import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';

export interface ModalInputData {
  title: string;
  description: string;
  deleteMessage: string;
  fieldInput: string;
  textGoButton: string;
  textReturnButton: string;
  maxLength?: number;
  placeholder?: string;
  secondNiveau?: boolean;
}

@Component({
  selector: 'lib-pxl-text-input-modal',
  templateUrl: './text-input-modal.component.html',
  styleUrls: ['./text-input-modal.component.scss'],
})
export class TextInputModalComponent implements OnInit {
  inputForm!: UntypedFormGroup;
  maxLength = 500;

  infoMessage =
    "Veuillez noter que le commentaire saisi est uniquement visible en interne dans l'outil et ne sera pas partagé avec les utilisateurs externes (déposants).";

  get formInputField(): UntypedFormControl {
    return this.inputForm.get('inputField') as UntypedFormControl;
  }

  constructor(
    public dialogRef: MatDialogRef<TextInputModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalInputData,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.inputForm = this.formBuilder.group({
      inputField: [null, []],
    });

    if (this.modalData.fieldInput != null && typeof this.modalData.fieldInput === 'string') {
      this.formInputField.setValue(this.modalData.fieldInput);
    }
    if (this.modalData.maxLength) {
      this.maxLength = this.modalData.maxLength;
    }
  }

  returnValue(): void {
    if (this.inputForm.valid) {
      const finalValue = this.formInputField.value.trim();

      if (this.modalData.fieldInput === finalValue) {
        this.dialogRef.close();
        return;
      }

      if (finalValue === '') {
        const dialogRef = this.dialog.open(ConfirmModalComponent, {
          data: {
            description: this.modalData.deleteMessage,
            textGoButton: 'Oui',
            textReturnButton: 'Non',
          },
        });

        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.dialogRef.close(finalValue);
          }
        });
      } else {
        this.dialogRef.close(finalValue);
      }
    }
  }

  cancelModal(): void {
    if (this.inputForm.dirty) {
      const cn = confirm('Les modifications apportées ne seront peut-être pas sauvegardées. Etes-vous sûr de vouloir quitter cette page ?');
      if (cn) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }
}
