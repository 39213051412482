<h2 id="modal-title" class="modal__title" *ngIf="modalData.title">{{ modalData.title }}</h2>

<form id="input-typeDoc" class="modal__content" [formGroup]="inputForm">
  <lib-card-info *ngIf="modalData.secondNiveau" [message]="infoMessage"></lib-card-info>
  <div
    class="ds-input-group"
    [class.ds-input-group--error]="!inputForm.controls['inputField'].valid && inputForm.controls['inputField'].touched"
  >
    <label *ngIf="modalData.description" for="input-field" class="ds-input-group__label">{{ modalData.description }}</label>
    <textarea
      id="input-field"
      name="input-field"
      rows="5"
      col="0"
      [attr.placeholder]="modalData.placeholder"
      [attr.maxlength]="maxLength"
      class="ds-input-group__textarea"
      formControlName="inputField"
      type="text"
      #textInput
    ></textarea>
    <div class="form-input__length-hint" [class.form-input__length-hint--full]="textInput.value?.length === maxLength">
      <span>{{ textInput.value?.length || 0 }}/{{ maxLength }}</span>
    </div>
    <!-- Icône et message d'erreur -->
    <ng-container id="error-input-field" *ngIf="!inputForm.controls['inputField'].valid && inputForm.controls['inputField'].touched">
      <div class="ds-input-group__feedback">Merci de bien vouloir renseigner le champ</div>
    </ng-container>
  </div>
</form>

<div id="modal-btn" class="modal__actions">
  <button *ngIf="modalData.textReturnButton" class="ds-btn ds-btn--secondary" (click)="cancelModal()" mat-dialog-close>
    {{ modalData.textReturnButton }}
  </button>
  <button class="ds-btn ds-btn--primary" (click)="returnValue()" cdkFocusInitial>
    {{ modalData.textGoButton }}
  </button>
</div>
