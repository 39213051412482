import { enumKeys } from '../../utils/enum-utils';

export enum EnumDocumentComplementaireTypeSuivi {
  AUTRES_DOCUMENTS_PROJET = 'AUTRES_DOCUMENTS_PROJET',
  PREVISIONS_ECONOMIQUES = 'PREVISIONS_ECONOMIQUES',
  DELEGATION_SIGNATURE_PERSONNE_HABILITEE = 'DELEGATION_SIGNATURE_PERSONNE_HABILITEE',
  PLAN_TRESORERIE = 'PLAN_TRESORERIE',
  AUTRES_DOCUMENTS_STRUCTURE = 'AUTRES_DOCUMENTS_STRUCTURE',
  ACTIVITES_PAYS_SANCTIONNES = 'ACTIVITES_PAYS_SANCTIONNES',
  ELEMENTS_FINANCIERS = 'ELEMENTS_FINANCIERS',
  EXTRAIT_KBIS_MOINS_3_MOIS = 'EXTRAIT_KBIS_MOINS_3_MOIS',
  RIB_IBAN = 'RIB_IBAN',
  STATUTS_ACTUALISES_STRUCTURE_SIGNES = 'STATUTS_ACTUALISES_STRUCTURE_SIGNES',
  PIECE_IDENTITE_ACTIONN_PERS_PHYSIQUES = 'PIECE_IDENTITE_ACTIONN_PERS_PHYSIQUES',
  PIECE_IDENTITE_REP_LEGAL_GROUPEMENT_COOPERATIVE = 'PIECE_IDENTITE_REP_LEGAL_GROUPEMENT_COOPERATIVE',
  PIECE_IDENTITE_REP_LEGAL_SOCIETE_NON_COTE = 'PIECE_IDENTITE_REP_LEGAL_SOCIETE_NON_COTE',
  PIECE_IDENTITE_PRESIDENT_ASSOCIATION = 'PIECE_IDENTITE_PRESIDENT_ASSOCIATION',
  PIECE_IDENTITE_TRESORIER_ASSOCIATION = 'PIECE_IDENTITE_TRESORIER_ASSOCIATION',
  EXTRAIT_JO_OU_RECEPISSE_PREFECTURE_ASSOCIATION = 'EXTRAIT_JO_OU_RECEPISSE_PREFECTURE_ASSOCIATION',
  JUSTIFICATIF_COTATION_MARCHE_REGLEMENTE = 'JUSTIFICATIF_COTATION_MARCHE_REGLEMENTE',
  KBIS_3_MOIS = 'KBIS_3_MOIS',
  REGISTRE_BENEFICIAIRES_EFFECTIFS_SOCIETE_NON_COTE = 'REGISTRE_BENEFICIAIRES_EFFECTIFS_SOCIETE_NON_COTE',
  ORGANIGRAMME_JURIDIQUE_DETAILLE_SOCIETE_NON_COTE = 'ORGANIGRAMME_JURIDIQUE_DETAILLE_SOCIETE_NON_COTE',
  STATUTS_ACTUALISES_SIGNES_ASSOCIATION_FONDATION = 'STATUTS_ACTUALISES_SIGNES_ASSOCIATION_FONDATION',
  TABLE_CAPITALISATION_DATEE_SIGNEE_SOCIETE_NON_COTE = 'TABLE_CAPITALISATION_DATEE_SIGNEE_SOCIETE_NON_COTE',
  TABLE_CAPITALISATION = 'TABLE_CAPITALISATION',
}

export namespace EnumDocumentComplementaireTypeSuivi {
  export function toString(option: any): string {
    switch (option) {
      case EnumDocumentComplementaireTypeSuivi.AUTRES_DOCUMENTS_PROJET:
        return 'Autres documents projet';
      case EnumDocumentComplementaireTypeSuivi.PREVISIONS_ECONOMIQUES:
        return 'Prévisions économiques';
      case EnumDocumentComplementaireTypeSuivi.DELEGATION_SIGNATURE_PERSONNE_HABILITEE:
        return 'Délégation de signature de la personne habilitée';
      case EnumDocumentComplementaireTypeSuivi.PLAN_TRESORERIE:
        return 'Plan de trésorerie';
      case EnumDocumentComplementaireTypeSuivi.AUTRES_DOCUMENTS_STRUCTURE:
        return 'Autres documents structure';
      case EnumDocumentComplementaireTypeSuivi.ACTIVITES_PAYS_SANCTIONNES:
        return 'Activités Pays sanctionnés';
      case EnumDocumentComplementaireTypeSuivi.ELEMENTS_FINANCIERS:
        return 'Eléments financiers';
      case EnumDocumentComplementaireTypeSuivi.EXTRAIT_KBIS_MOINS_3_MOIS:
        return 'Extrait Kbis de moins de trois mois';
      case EnumDocumentComplementaireTypeSuivi.RIB_IBAN:
        return 'RIB - IBAN';
      case EnumDocumentComplementaireTypeSuivi.STATUTS_ACTUALISES_STRUCTURE_SIGNES:
        return 'Statuts actualisés de la structure signés';
      case EnumDocumentComplementaireTypeSuivi.PIECE_IDENTITE_ACTIONN_PERS_PHYSIQUES:
        return "Actionn.>=25% - Pièce d'identité actionn. pers. phys. (soc. non cotée)";
      case EnumDocumentComplementaireTypeSuivi.PIECE_IDENTITE_REP_LEGAL_GROUPEMENT_COOPERATIVE:
        return "Actionn.>=25% - Pièce d'identité du représent. légal (groupement/coopérative)";
      case EnumDocumentComplementaireTypeSuivi.PIECE_IDENTITE_REP_LEGAL_SOCIETE_NON_COTE:
        return "Actionn.>=25% - Pièce d'identité du représent. légal (soc. non cotée)";
      case EnumDocumentComplementaireTypeSuivi.PIECE_IDENTITE_PRESIDENT_ASSOCIATION:
        return "Actionn.>=25% - Pièce d'identité du/de la président.e (association)";
      case EnumDocumentComplementaireTypeSuivi.PIECE_IDENTITE_TRESORIER_ASSOCIATION:
        return "Actionn.>=25% - Pièce d'identité du/de la trésorier.ière (association)";
      case EnumDocumentComplementaireTypeSuivi.EXTRAIT_JO_OU_RECEPISSE_PREFECTURE_ASSOCIATION:
        return 'Actionn.>=25% - Extr. JO ou récépissé à la préfecture (association)';
      case EnumDocumentComplementaireTypeSuivi.JUSTIFICATIF_COTATION_MARCHE_REGLEMENTE:
        return 'Actionn.>=25% - Justificatif de cotation (société cotée sur un marché réglementé)';
      case EnumDocumentComplementaireTypeSuivi.KBIS_3_MOIS:
        return 'Actionn.>=25% - Kbis - 3 mois';
      case EnumDocumentComplementaireTypeSuivi.REGISTRE_BENEFICIAIRES_EFFECTIFS_SOCIETE_NON_COTE:
        return 'Actionn.>=25% - Registre bénéficiaires effectifs (groupement/coopérative)';
      case EnumDocumentComplementaireTypeSuivi.ORGANIGRAMME_JURIDIQUE_DETAILLE_SOCIETE_NON_COTE:
        return 'Actionn.>=25% - Organigramme juridique détaillé (société non cotée)';
      case EnumDocumentComplementaireTypeSuivi.STATUTS_ACTUALISES_SIGNES_ASSOCIATION_FONDATION:
        return 'Actionn.>=25% - Statuts actualisés et signés (association - fondation)';
      case EnumDocumentComplementaireTypeSuivi.TABLE_CAPITALISATION_DATEE_SIGNEE_SOCIETE_NON_COTE:
        return 'Actionn.>=25% - Table de capi. datée signée (société non cotée)';
      case EnumDocumentComplementaireTypeSuivi.TABLE_CAPITALISATION:
        return 'Table de capitalisation';
      default:
        return '';
    }
  }

  export function all(): EnumDocumentComplementaireTypeSuivi[] {
    return enumKeys(EnumDocumentComplementaireTypeSuivi) as unknown as EnumDocumentComplementaireTypeSuivi[];
  }
}
