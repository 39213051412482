import { EnvironmentInterface } from '../interfaces/environment.interface';

export const SITE_ADMIN = 'admin';
export const SITE_EVAL = 'eval';
export class Environment implements EnvironmentInterface {
  apiUrl: string;
  apiAdminUrl: string;
  apiUrlLambda: string;
  apiSuiviUrl: string;
  site: string | null; // null when site candidat (maybe fix this)
  docPublicBaseUrl: string;
  docLogosPublicBaseUrl: string;
  youSignUiId?: string;
  apiSearchUrl: string;
}
