import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, EMPTY, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { EnumScope } from '../../shared/enums/enum.scope';
import { EnumTypeDocument } from '../../shared/enums/enum.typeDocument';
import { DocumentProjet } from '../../shared/models/documentProjet.model';
import { ShowToastrService } from '../../shared/services/show-toastr.service';
import { UploadDocumentHttpService } from '../../shared/services/upload-document.http.service';
import { DocumentHelper, FILE_SIZE } from '../../utils/_public_utils';
import { cloneDeep } from 'lodash';
import { SignedUrlResponseModel } from '../../shared/models/signed-url-response.model';
import { DocumentService } from '../../shared/services/document.service';

@Component({
  selector: 'lib-document-async-uploader',
  templateUrl: './document-async-uploader.component.html',
  styleUrl: './document-async-uploader.component.scss',
})
export class DocumentAsyncUploaderComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  @Input() populateDocFields?: (document: DocumentProjet) => void;
  @Input() title: string;
  @Input() fileSizeLimit = FILE_SIZE;
  @Input() canUserWrite = false;
  @Input() documentType: EnumTypeDocument;
  @Input() documentScope: EnumScope;
  @Input() projetId: string;
  @Input() structureId: string;
  @Input() documents: DocumentProjet[] = [];
  @Input() isAdminUpload = false;
  @Input() showError = false;
  @Input() errorMessage = '';
  @Input() acceptedDocuments: { extension: string; type: string }[] = [];
  @Input() showMaxFileSizeMessage = true;
  @Input() uploadButtonText = 'Ajouter un fichier';

  @Output() documentsChange = new EventEmitter<DocumentProjet[]>();
  @Output() uploadError = new EventEmitter<DocumentProjet>();

  originalDocuments: DocumentProjet[];
  supportedFileTypes: string[] = [];
  supportedExtensions = '';

  constructor(
    private showToastrService: ShowToastrService,
    private documentService: DocumentService,
    private uploadDocumentService: UploadDocumentHttpService
  ) {}

  ngOnInit(): void {
    this.originalDocuments = cloneDeep(this.documents);
    this.supportedFileTypes = this.acceptedDocuments.map(doc => doc.type);
    this.supportedExtensions = this.acceptedDocuments.map(doc => doc.extension).join(', ');
  }

  onDocumentFileSelected(event: Event): void {
    if (!this.canUserWrite) return;

    const input = event.target as HTMLInputElement;
    let fileToUpload = input.files?.item(0);

    if (!fileToUpload) return;

    if (fileToUpload.type === '' && fileToUpload.name.toLowerCase().endsWith('.msg')) {
      fileToUpload = new File([fileToUpload], fileToUpload.name, { type: 'application/vnd.ms-outlook' });
    }

    if (fileToUpload.size > this.fileSizeLimit * 1048576) {
      this.showToastrService.error(`Le fichier importé est trop volumineux, la taille maximale autorisée est de ${this.fileSizeLimit} Mo`);
    } else if (!this.supportedFileTypes.includes(fileToUpload.type)) {
      this.showToastrService.error("Le type du document sélectionné n'est pas supporté, merci de sélectionner un autre fichier.");
    } else {
      const documentToAdd = new DocumentProjet();
      documentToAdd.nom = fileToUpload.name;
      documentToAdd.file = fileToUpload;
      this.populateDocFields?.(documentToAdd);
      this.documents.push(documentToAdd);
      this.documentsChange.emit(this.documents);
    }
  }

  onDocumentFileDeleted(document: DocumentProjet): void {
    this.documents = this.documents.filter(doc => doc !== document);
    this.documentsChange.emit(this.documents);
  }

  public saveDocuments(): Observable<boolean> {
    const addedDocuments = this.documents.filter(doc => !doc.id);
    const deletedDocuments = this.originalDocuments.filter(originalDoc => !this.documents.find(doc => doc?.id === originalDoc.id));

    if (addedDocuments.length === 0 && deletedDocuments.length === 0) {
      return of(true);
    }

    addedDocuments.forEach(doc => this.populateDocFields?.(doc));

    return forkJoin([
      ...addedDocuments.map(document => this.saveNewDocument(document)),
      ...deletedDocuments.map(document => this.deleteExistingDocument(document)),
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(() => true),
      catchError(err => {
        this.handleError(err);
        return of(false);
      })
    );
  }

  private saveNewDocument(document: DocumentProjet): Observable<HttpResponse<SignedUrlResponseModel>> {
    return this.uploadDocument(document).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(response => response.body!),
      catchError(this.handleError.bind(this)),
      switchMap(doc => {
        document.id = doc.id;
        document.dateCreation = doc.dateCreation;
        return this.uploadDocumentWithSignedUrl(document, doc.id).pipe(catchError(this.handleError.bind(this)));
      })
    );
  }

  private uploadDocumentWithSignedUrl(document: DocumentProjet, idDoc: string): Observable<HttpResponse<SignedUrlResponseModel>> {
    const path = DocumentHelper.getDocumentS3FolderPath(document);
    const fileType = 'B401';

    return this.uploadDocumentService.getValueForDocUpload(document.nom, path, fileType, idDoc, this.projetId).pipe(
      takeUntilDestroyed(this.destroyRef),
      map(response => response.body!),
      catchError(err => this.rollbackDocumentCreation(document, err)),
      switchMap(response => this.uploadDocumentToS3(response.url, document, idDoc, fileType))
    );
  }

  private uploadDocumentToS3(url: string, document: DocumentProjet, idDoc: string, fileType: string): Observable<HttpResponse<any>> {
    return this.uploadDocumentService
      .uploadDoc(url, document.file, this.projetId, idDoc, fileType)
      .pipe(takeUntilDestroyed(this.destroyRef), catchError(this.handleError.bind(this)));
  }

  private deleteExistingDocument(document: DocumentProjet): Observable<object> {
    return this.deleteDocument(document).pipe(
      takeUntilDestroyed(this.destroyRef),
      catchError(this.handleError.bind(this)),
      switchMap(() => this.deleteDocumentFromS3(document))
    );
  }

  private deleteDocumentFromS3(document: DocumentProjet): Observable<HttpResponse<SignedUrlResponseModel>> {
    if (document.stockerGed) {
      return of({} as HttpResponse<SignedUrlResponseModel>); // No need to delete from S3 if it's stored in GED
    }
    const path = DocumentHelper.getDocumentS3FilePath(document);
    const fileType = 'B401';

    return this.uploadDocumentService
      .deleteDocFromS3(path, fileType, document.id, this.projetId)
      .pipe(takeUntilDestroyed(this.destroyRef), catchError(this.handleError.bind(this)));
  }

  private rollbackDocumentCreation(document: DocumentProjet, err: HttpErrorResponse): Observable<never> {
    this.uploadError.emit(document);
    this.documents = this.documents.filter(doc => doc !== document);
    return this.deleteDocument(document).pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap(() => {
        this.handleError(err);
        return EMPTY;
      })
    );
  }

  private uploadDocument(document: DocumentProjet): Observable<HttpResponse<DocumentProjet>> {
    return this.isAdminUpload ? this.documentService.createDocumentAdmin(document) : this.documentService.createDocument(document);
  }

  public deleteDocument(document: DocumentProjet): Observable<object> {
    return this.isAdminUpload
      ? this.documentService.deleteDocumentAdmin(document.id)
      : this.documentService.deleteDocument(this.projetId, document.id);
  }

  resetToOriginalDocuments(): void {
    this.documents = cloneDeep(this.originalDocuments);
    this.documentsChange.emit(this.documents);
  }

  private handleError(err: HttpErrorResponse): Observable<never> {
    this.showToastrService.checkCodeError(err?.error);
    return EMPTY;
  }
}
