export enum EnumKpiPhase {
  DEPOT_CIBLE = 'DEPOT_CIBLE',
  EC_CIBLE_REVISEE = 'EC_CIBLE_REVISEE',
  SOLDE_REALISE = 'SOLDE_REALISE',
  SOLDE_CIBLE_REVISEE = 'SOLDE_CIBLE_REVISEE',
}

export namespace EnumKpiPhase {
  export function toString(value: EnumKpiPhase): string {
    switch (value) {
      case EnumKpiPhase.DEPOT_CIBLE:
        return 'Pré-dépôt/Dépôt';
      case EnumKpiPhase.EC_CIBLE_REVISEE:
        return 'Etape(s) clé(s)';
      case EnumKpiPhase.SOLDE_REALISE:
        return 'Solde (réalisé)';
      case EnumKpiPhase.SOLDE_CIBLE_REVISEE:
        return 'Solde (cible révisée)';
      default:
        return '';
    }
  }
}
