<button [matMenuTriggerFor]="menu" class="ds-btn ds-btn--secondary">Partenaire</button>
<mat-menu #menu="matMenu" (closed)="onMenuClosed()">
  <div
    class="date-filters-container"
    (click)="$event.stopPropagation()"
    (keydown.tab)="$event.stopPropagation()"
    (keydown.shift.tab)="$event.stopPropagation()"
    cdkTrapFocus
    [cdkTrapFocusAutoCapture]="true"
  >
    <div class="single-filter-container">
      <span class="ds-input-group__label">Assiette présentée</span>
      <div class="range-filters-container">
        <div
          id="input-min-nb-partenaire"
          class="form-step__total ds-input-group range-filter"
          [class.ds-input-group--error]="minAssiettePresentee < 0 || minAssiettePresentee > maxAssiettePresentee"
        >
          <span class="ds-input-group__input">
            <input
              id="minAssiettePresentee"
              name="minAssiettePresentee"
              type="number"
              placeholder="Minimum"
              min="0"
              (keypress)="preventValues($event)"
              class="ds-input-group__input"
              [(ngModel)]="minAssiettePresentee"
              #minAssiettePresenteeInput
            />
          </span>
        </div>
        <div
          id="input-max-nb-partenaire"
          class="form-step__total ds-input-group range-filter"
          [class.ds-input-group--error]="maxAssiettePresentee < 0 || minAssiettePresentee > maxAssiettePresentee"
        >
          <span class="ds-input-group__input">
            <input
              id="maxAssiettePresentee"
              name="maxAssiettePresentee"
              type="number"
              (keypress)="preventValues($event)"
              min="0"
              placeholder="Maximum"
              class="ds-input-group__input"
              [(ngModel)]="maxAssiettePresentee"
              #maxAssiettePresenteeInput
            />
          </span>
        </div>
      </div>
      <!-- message d'erreur -->
      <ng-container id="error-budget" *ngIf="minAssiettePresentee > maxAssiettePresentee">
        <div class="ds-input-group__feedback">Le minimum doit être inférieur ou égal au maximum</div>
      </ng-container>
    </div>

    <div class="single-filter-container">
      <span class="ds-input-group__label">Assiette totale retenue</span>
      <div class="range-filters-container">
        <div
          id="input-min-nb-partenaire"
          class="form-step__total ds-input-group range-filter"
          [class.ds-input-group--error]="minAssietteTotaleRetenue < 0 || minAssietteTotaleRetenue > maxAssietteTotaleRetenue"
        >
          <span class="ds-input-group__input">
            <input
              id="minAssietteTotaleRetenue"
              name="minAssietteTotaleRetenue"
              type="number"
              placeholder="Minimum"
              min="0"
              (keypress)="preventValues($event)"
              class="ds-input-group__input"
              [(ngModel)]="minAssietteTotaleRetenue"
              #minAssietteTotaleRetenueInput
            />
          </span>
        </div>
        <div
          id="input-max-nb-partenaire"
          class="form-step__total ds-input-group range-filter"
          [class.ds-input-group--error]="maxAssietteTotaleRetenue < 0 || minAssietteTotaleRetenue > maxAssietteTotaleRetenue"
        >
          <span class="ds-input-group__input">
            <input
              id="maxAssietteTotaleRetenue"
              name="maxAssietteTotaleRetenue"
              type="number"
              (keypress)="preventValues($event)"
              min="0"
              placeholder="Maximum"
              class="ds-input-group__input"
              [(ngModel)]="maxAssietteTotaleRetenue"
              #maxAssietteTotaleRetenueInput
            />
          </span>
        </div>
      </div>
      <!-- message d'erreur -->
      <ng-container id="error-budget" *ngIf="minAssietteTotaleRetenue > maxAssietteTotaleRetenue">
        <div class="ds-input-group__feedback">Le minimum doit être inférieur ou égal au maximum</div>
      </ng-container>
    </div>

    <div class="single-filter-container">
      <span class="ds-input-group__label">Montant total de l'aide retenue HT</span>
      <div class="range-filters-container">
        <div
          id="input-min-nb-partenaire"
          class="form-step__total ds-input-group range-filter"
          [class.ds-input-group--error]="minMontantTotalAideHT < 0 || minMontantTotalAideHT > maxMontantTotalAideHT"
        >
          <span class="ds-input-group__input">
            <input
              id="minMontantTotalAideHT"
              name="minMontantTotalAideHT"
              type="number"
              placeholder="Minimum"
              min="0"
              (keypress)="preventValues($event)"
              class="ds-input-group__input"
              [(ngModel)]="minMontantTotalAideHT"
              #minMontantTotalAideHTInput
            />
          </span>
        </div>
        <div
          id="input-max-nb-partenaire"
          class="form-step__total ds-input-group range-filter"
          [class.ds-input-group--error]="maxMontantTotalAideHT < 0 || minMontantTotalAideHT > maxMontantTotalAideHT"
        >
          <span class="ds-input-group__input">
            <input
              id="maxMontantTotalAideHT"
              name="maxMontantTotalAideHT"
              type="number"
              (keypress)="preventValues($event)"
              min="0"
              placeholder="Maximum"
              class="ds-input-group__input"
              [(ngModel)]="maxMontantTotalAideHT"
              #maxMontantTotalAideHTInput
            />
          </span>
        </div>
      </div>
      <!-- message d'erreur -->
      <ng-container id="error-budget" *ngIf="minMontantTotalAideHT > maxMontantTotalAideHT">
        <div class="ds-input-group__feedback">Le minimum doit être inférieur ou égal au maximum</div>
      </ng-container>
    </div>

    <div class="single-filter-container">
      <span class="ds-input-group__label">Code postal de réalisation / partenaire</span>
      <lib-custom-multiselect
        #codePostalFilterComponent
        (autocompleteEvent)="autocompleteEvent($event, CODE_POSTAL)"
        (selectedListChange)="selectedListChange($event, CODE_POSTAL)"
        [(dataList)]="codePostalDataList"
        [selectedList]="codePostalSelectedList"
        placeholder="Code postal de réalisation / partenaire"
        name="CodePostal"
        class="filter"
        [isLoading]="loadingMap.get(CODE_POSTAL)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
    </div>
    <div class="single-filter-container">
      <span class="ds-input-group__label">SIRET</span>
      <lib-custom-multiselect
        #siretFilterComponent
        (autocompleteEvent)="autocompleteEvent($event, SIRET)"
        (selectedListChange)="selectedListChange($event, SIRET)"
        [(dataList)]="siretDataList"
        [selectedList]="siretSelectedList"
        placeholder="SIRET"
        name="SIRET"
        class="filter"
        [isLoading]="loadingMap.get(SIRET)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
    </div>

    <div class="single-filter-container">
      <span class="ds-input-group__label">SIREN</span>
      <lib-custom-multiselect
        #sirenFilterComponent
        (autocompleteEvent)="autocompleteEvent($event, SIREN)"
        (selectedListChange)="selectedListChange($event, SIREN)"
        [(dataList)]="sirenDataList"
        [selectedList]="sirenSelectedList"
        placeholder="SIREN"
        name="SIREN"
        class="filter"
        [isLoading]="loadingMap.get(SIREN)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
    </div>

    <div class="single-filter-container">
      <span class="ds-input-group__label">N° projet PXL-PIC</span>
      <lib-custom-multiselect
        #projetPxlPicFilterComponent
        (autocompleteEvent)="autocompleteEvent($event, PROJET_PXL_PIC)"
        (selectedListChange)="selectedListChange($event, PROJET_PXL_PIC)"
        [(dataList)]="projetPxlPicDataList"
        [selectedList]="projetPxlPicSelectedList"
        placeholder="N° projet PXL-PIC"
        name="ProjetPxlPic"
        class="filter"
        [isLoading]="loadingMap.get(PROJET_PXL_PIC)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
    </div>

    <div class="single-filter-container">
      <span class="ds-input-group__label">Nom du groupe</span>
      <lib-custom-multiselect
        #nomDuGroupeComponent
        (autocompleteEvent)="autocompleteEvent($event, NOM_DU_GROUPE)"
        (selectedListChange)="selectedListChange($event, NOM_DU_GROUPE)"
        [(dataList)]="nomDuGroupeDataList"
        [selectedList]="nomDuGroupeSelectedList"
        placeholder="Nom du groupe"
        name="NomDuGroupe"
        class="filter"
        [isLoading]="loadingMap.get(NOM_DU_GROUPE)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
    </div>
    <div class="filters-actions">
      <button id="cancel-btn" class="ds-btn ds-btn--tertiary" (click)="resetFilters()">Réinitialiser</button>

      <button id="save-btn" class="ds-btn ds-btn--primary" (click)="applyFilters()">Appliquer</button>
    </div>
  </div>
</mat-menu>
