import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { QdtViz, qdtCapabilityApp } from 'qdt-components';

const config = {
  host: 'staging.qls-nprd.cloud.bpifrance.fr',
  secure: true,
  port: 443,
  prefix: 'mcb',
  appId: 'ef0c77d8-1a61-4f37-9831-9f2254e01b85',
};

@Component({
  selector: 'qdt-component',
  templateUrl: './qdt-viz.component.html',
  styleUrls: ['./qdt-viz.component.scss'],
})
export class QdtVizComponentsComponent implements OnInit {
  @Input() component: any;
  @Input() properties: any;
  @Input() options: any;
  @Input() connection: number = 1;

  constructor(private elementRef: ElementRef) {}

  static capabilityApiAppPromise: Promise<any>;

  async init() {
    const mcbToken = this.getToken();
    QdtVizComponentsComponent.capabilityApiAppPromise = qdtCapabilityApp({ ...config, token: mcbToken });

    const { options } = this;
    const app = await QdtVizComponentsComponent.capabilityApiAppPromise;
    QdtViz({
      element: this.elementRef.nativeElement,
      app,
      options,
    });
  }

  ngOnInit() {
    this.init();
  }

  getToken(): string {
    const mcbStr = localStorage.getItem('MCB');
    if (!mcbStr) {
      return;
    }
    const auth = JSON.parse(mcbStr);
    return auth?.authnResult?.id_token;
  }
}
