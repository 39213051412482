<div class="page-content">
  <mat-accordion #accordion="matAccordion">
    <mat-expansion-panel *ngFor="let kpisAndCategorie of kpisByCategorieList">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <lib-pxl-icon
            class="green-checkmark"
            icon="icon-action-check-circle"
            aria-hidden="true"
            *ngIf="isKpiCompleted(kpisAndCategorie)"
          ></lib-pxl-icon>
          <span class="fw-bold">{{ kpisAndCategorie.categorieKPI.titre }}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <!-- formulaire par catégorie -->
      <form [formGroup]="grilleImpactsCategorieForm" (ngSubmit)="grilleImpactsCategorieSubmit(kpisAndCategorie)">
        <ng-container formGroupName="kpis">
          <ng-container *ngFor="let kpisOfCategorie of kpisAndCategorie.refKPIDTOList; let i = index">
            <!-- composant par KPI -->
            <lib-kpi-questions-form-field
              [saveGrilleImpact]="saveGrilleImpact"
              [formArrayName]="i"
              [kpisFormArray]="grilleImpactsKpisForm"
              [(savedKpisFormArrayValue)]="savedGrilleImpactsKpisFormValue"
              [kpi]="kpisOfCategorie"
              [responses]="getKpiResponses(kpisOfCategorie)"
              [isUpdatableByUser]="isUpdatableByUser"
              [isSelection]="isSelection"
              [etapeCleType]="etapeCleType"
            >
            </lib-kpi-questions-form-field>
          </ng-container>
        </ng-container>

        <!-- -->
        <div class="form-actions d-flex justify-content-end" *ngIf="isUpdatableByUser">
          <button id="cancel-save" type="button" class="ds-btn ds-btn--tertiary ms-2" (click)="cancelSave(kpisAndCategorie)">
            Annuler
          </button>
          <ng-container>
            <button id="save-structure" type="submit" class="ds-btn ds-btn--primary">
              <span>Enregistrer</span>
            </button>
          </ng-container>
        </div>
      </form>
    </mat-expansion-panel>
  </mat-accordion>
</div>
