<form class="d-flex flex-wrap">
  <div class="ds-input-group w-100 mt-3">
    @if (canUserWrite) {
    <div class="ds-input-group__label mb-0">
      Rapport d'expertise
      <lib-asterisk></lib-asterisk>
    </div>
    <lib-document-async-uploader
      #rapportExpertiseUploader
      title="Rapport d'expertise"
      [documents]="documents"
      (documentsChange)="onDocumentsChange($event)"
      [projetId]="projet.id"
      [canUserWrite]="canUserWrite"
      [populateDocFields]="getDocFieldsCompleter()"
      [acceptedDocuments]="ACCEPTED_DOCUMENTS"
      [showError]="showDocumentsError"
      errorMessage="Merci de déposer votre rapport d'expertise"
      uploadButtonText="Déposer un rapport d'expertise"
    >
    </lib-document-async-uploader>
    } @else {
    <div class="fw-bold">Document(s)</div>
    @for (document of documents; track document.id) {
    <lib-document-card [document]="document" [hasCheckbox]="false" [canUserWrite]="false"> </lib-document-card>
    } }
  </div>

  <div class="ds-input-group w-100 mt-3">
    @if (canUserWrite) {
    <div class="ds-input-group__label mb-0">Commentaire</div>
    <span class="ds-input-group__input">
      <textarea
        id="commentaire"
        name="commentaire"
        type="text"
        rows="5"
        maxlength="2500"
        style="resize: none"
        class="ds-input-group__input ds-input-group__textarea"
        placeholder="Commentaire"
        [ngModel]="commentaire"
        (ngModelChange)="onCommentaireChange($event)"
        #commentaireInput
      >
      </textarea>
      <div class="form-input__length-hint" [class.form-input__length-hint--full]="commentaireInput.value?.length === 2500">
        <span>{{ commentaireInput.value?.length || 0 }}/2500</span>
      </div>
    </span>
    } @else {
    <div class="fw-bold">Commentaire</div>
    @if (commentaire) {
    <lib-expandable-text [text]="commentaire"></lib-expandable-text>
    } }
  </div>

  <div class="d-flex justify-content-end w-100 mt-3" *ngIf="canUserWrite">
    <button id="cancel-synthese-btn" type="button" class="ds-btn ds-btn--secondary me-2" (click)="reset()">Annuler</button>
    <button id="save-synthese-btn" type="submit" class="ds-btn ds-btn--primary" (click)="save()">Enregistrer</button>
  </div>
</form>
