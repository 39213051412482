<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToProjetInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Projet
  </a>
  <h2 class="page-header__title">Fiche de communication publique</h2>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <!-- Message d'information read only -->
  <div class="card--info card--blue" *ngIf="!isProjectUpdatableByUser">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    Vous êtes en lecture seule sur cette page. Vous ne pouvez pas éditer son contenu.
    {{ projet.inactif ? 'Pour effectuer des modifications sur le projet, vous devez d’abord le réactiver.' : null }}
  </div>

  <div class="card--info card--blue">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    <div>
      Chaque paragraphe doit être rédigé (pas d'énumération). Veillez à soigner le style et à l'adapter à une audience large, grand public.
      Assurez-vous de ne pas divulguer des informations trop sensibles ou confidentielles (contrat non encore signé, brevet en cours de
      dépôt, etc).
      <br />
      En cas de projet collaboratif le chef de file s’assurera de la validation des données par ses partenaires.
      <br />
      <strong
        >En déposant cette fiche résumée, vous accordez à Bpifrance et à l'Etat le droit de la diffuser, y compris avec les images, dans le
        cadre de la communication sur le projet ou l'appel à projets.</strong
      >
    </div>
  </div>
  <div class="card--info card--blue" *ngIf="ficheCom?.statut === VALIDEE">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    <div>La fiche de communication a été validée , il n'est plus possible de la modifier</div>
  </div>

  <div>
    <p>Vous devez renseigner toutes les informations avec <lib-asterisk></lib-asterisk></p>

    <mat-accordion #accordion="matAccordion" *ngIf="isLoaded && isDocLoaded">
      <lib-fiche-com-info-projet
        [aap]="aap"
        [projet]="projet"
        [infoProjetForm]="ficheComForm?.get('informationProjet')"
        [ficheCom]="ficheCom"
        [listLogoProjet]="listLogoProjet"
        [utilisateur]="utilisateur"
        [canUserWrite]="isProjectUpdatableByUser"
        [isValideFicheCom]="isValideFicheCom"
        (saveFicheCom)="onSave($event)"
      ></lib-fiche-com-info-projet>
      <lib-fiche-com-info-chef-de-file
        [aap]="aap"
        [projet]="projet"
        [infoChefDeFileForm]="ficheComForm?.get('informationChefDeFile')"
        [ficheCom]="ficheCom"
        [listLogoProjet]="listLogoProjet"
        [utilisateur]="utilisateur"
        [canUserWrite]="isProjectUpdatableByUser"
        [isValideFicheCom]="ficheCom.statut === VALIDEE"
        (saveFicheCom)="onSave($event)"
      ></lib-fiche-com-info-chef-de-file>
      <lib-fiche-com-contact-presse
        [aap]="aap"
        [projet]="projet"
        [contactPresseForm]="ficheComForm?.get('contactPresse')"
        [ficheCom]="ficheCom"
        [canUserWrite]="isProjectUpdatableByUser"
        [isValideFicheCom]="ficheCom.statut === VALIDEE"
        (saveFicheCom)="onSave($event)"
      >
      </lib-fiche-com-contact-presse>
      <lib-fiche-com-logos-structures
        *ngIf="projet.partenaireType === EnumTypePartenaire.MULTI"
        [aap]="aap"
        [projet]="projet"
        [logosStructuresForm]="ficheComForm?.get('logoEntreprises')"
        [ficheCom]="ficheCom"
        [listLogoProjet]="listLogoProjet"
        [utilisateur]="utilisateur"
        [canUserWrite]="isProjectUpdatableByUser"
        [isValideFicheCom]="ficheCom.statut === VALIDEE"
        (saveFicheCom)="onSave($event)"
      ></lib-fiche-com-logos-structures>
      <lib-fiche-com-demande-derogation
        [aap]="aap"
        [projet]="projet"
        [derogationForm]="ficheComForm.get('derogationForm')"
        [ficheCom]="ficheCom"
        [canUserWrite]="isProjectUpdatableByUser"
        [isValideFicheCom]="ficheCom.statut === VALIDEE"
        (saveFicheCom)="onSave($event)"
      >
      </lib-fiche-com-demande-derogation>
    </mat-accordion>
  </div>
  <div class="card d-block mt-3" *ngIf="ficheCommunicationDocuments.length > 0">
    <div>
      <h4 class="title fw-bold">Voir la fiche de communication</h4>
    </div>
    <div class="card__title">
      <div class="documents-container">
        <div class="document-fiche-communication" *ngFor="let doc of ficheCommunicationDocuments">
          <lib-pxl-icon class="icon" icon="icon-file-folder-insert-drive-file" aria-hidden="true"></lib-pxl-icon>
          <lib-document-card [document]="doc" [hasCheckbox]="false" [canUserWrite]="true" (deleteDocEmitter)="deleteDocument($event)">
          </lib-document-card>
        </div>
      </div>
    </div>
  </div>
</div>
