"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = (ctx, rec, confidence) => ({
  confidence,
  name: rec.name(ctx),
  lang: rec.language ? rec.language() : undefined
});
