import { Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  DOC,
  DocumentAsyncUploaderComponent,
  DocumentProjet,
  DOCX,
  EnumScope,
  EnumTypeDocument,
  Evaluateur,
  PDF,
  PPT,
  PPTX,
  Projet,
  RapportExpertise,
  ShowToastrService,
  Utilisateur,
  XLS,
  XLSX,
} from '@shared-ui';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { catchError, map } from 'rxjs/operators';
import { RapportExpertiseService } from '@shared-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { EMPTY, Observable, switchMap } from 'rxjs';

@Component({
  selector: 'lib-rapport-expertise',
  templateUrl: './rapport-expertise.component.html',
  styleUrl: './rapport-expertise.component.scss',
})
export class RapportExpertiseComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  readonly ACCEPTED_DOCUMENTS = [PDF, XLS, XLSX, DOC, DOCX, PPT, PPTX];

  @ViewChild('rapportExpertiseUploader') rapportExpertiseUploader!: DocumentAsyncUploaderComponent;

  @Input() projet!: Projet;
  @Input() expert!: Utilisateur | Evaluateur;
  @Input() delegated = false;
  @Input() canUserWrite = false;
  @Input() rapportExpertise: RapportExpertise | null;
  @Input() documents: DocumentProjet[] = [];
  @Output() rapportSaved = new EventEmitter<RapportExpertise>();
  @Output() rapportEditionCanceled = new EventEmitter<void>();

  commentaire: string | null;
  showDocumentsError = false;
  isDirty = false;

  constructor(private showToastrService: ShowToastrService, private rapportExpertiseService: RapportExpertiseService) {}

  ngOnInit() {
    this.commentaire = this.rapportExpertise?.commentaire ?? null;
  }

  getDocFieldsCompleter(): (document: DocumentProjet) => void {
    return (document: DocumentProjet) => {
      document.typeDoc = EnumTypeDocument.RAPPORT_EXPERTISE;
      document.projetId = this.projet.id;
      document.createur = this.expert.matricule;
      document.scope = EnumScope.PROJET;
      document.relatedResourceId = this.rapportExpertise?.id ?? null;
    };
  }

  reset() {
    this.rapportExpertiseUploader.resetToOriginalDocuments();
    this.commentaire = this.rapportExpertise?.commentaire ?? null;
    this.isDirty = false;
    this.rapportEditionCanceled.emit();
  }

  save() {
    if (this.documents.length === 0) {
      this.showDocumentsError = true;
      return;
    }

    if (this.rapportExpertise) {
      this.rapportExpertise.commentaire = this.commentaire;
      this.rapportExpertiseService
        .updateRapportExpertise(this.rapportExpertise, this.delegated ? this.expert.email : undefined)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          catchError(err => this.handleError(err)),
          map(response => response.body!),
          switchMap(rapport => {
            this.rapportExpertise = rapport;
            return this.rapportExpertiseUploader.saveDocuments();
          })
        )
        .subscribe(() => {
          this.showToastrService.success("Le rapport d'expertise a été mis à jour");
          this.rapportSaved.emit(this.rapportExpertise!);
          this.isDirty = false;
        });
    } else {
      const rapportExpertise = {
        projetId: this.projet.id,
        commentaire: this.commentaire,
      } as RapportExpertise;
      this.rapportExpertiseService
        .createRapportExpertise(rapportExpertise, this.delegated ? this.expert.email : undefined)
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          catchError(err => this.handleError(err)),
          map(response => response.body!),
          switchMap(rapport => {
            this.rapportExpertise = rapport;
            return this.rapportExpertiseUploader.saveDocuments();
          })
        )
        .subscribe(() => {
          this.showToastrService.success("Le rapport d'expertise a été créé");
          this.rapportSaved.emit(this.rapportExpertise!);
          this.isDirty = false;
        });
    }
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    this.showToastrService.checkCodeError(err?.error);
    return EMPTY;
  }

  onDocumentsChange(documents: DocumentProjet[]) {
    this.documents = documents;
    this.showDocumentsError = false;
    this.isDirty = true;
  }

  onCommentaireChange(commentaire: string) {
    this.commentaire = commentaire;
    this.isDirty = true;
  }

  public isFormDirty(): boolean {
    return this.isDirty;
  }
}
