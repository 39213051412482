import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import {
  EnumGenre,
  EnumMotifNonRattachement,
  EnumRoleStructure,
  GenreItem,
  SignataireInterface,
  EnumStatutSignataire,
  TelephoneInputComponent,
} from '@shared-ui';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';

declare const dsLib: any;

@Component({
  selector: 'lib-signataire-form',
  templateUrl: './signataire-form.component.html',
  styleUrls: ['./signataire-form.component.scss'],
})
export class SignataireFormComponent implements OnInit, OnChanges {
  @Input() signataire!: SignataireInterface;
  @Input() canWrite!: boolean;
  @Input() isSiteAdmin?: boolean;
  @Input() readOnly?: boolean;
  @Output() formSubmitted = new EventEmitter();
  @Output() canceled = new EventEmitter();

  @ViewChild('signataireTelephone') telephoneInputComponent: TelephoneInputComponent;

  protected readonly EnumRoleStructure = EnumRoleStructure;
  protected readonly EnumMotifNonRattachement = EnumMotifNonRattachement;
  protected readonly EnumGenre = EnumGenre;
  readonly WARNING_MESSAGE_PIECE_IDENTITE =
    'Merci de fournir la pièce d\'identité du signataire déclaré au niveau de la section "Document(s) de la structure"';
  readonly WARNING_MESSAGE_DELEGATION_ET_PIECE_IDENTITE =
    'Merci de fournir la pièce d\'identité et la délégation de signature du signataire déclaré au niveau de la section "Document(s) de la structure"';
  readonly WARNING_MESSAGE_DOCUMENTS_STRUCTURE =
    "La pièce d'identité du signataire déclaré se trouve au niveau de la section Document(s) de la structure";
  readonly EnumStatutSignataire = EnumStatutSignataire;

  genres: Array<GenreItem> = EnumGenre.all().map(item => ({ valeur: item, checked: false }));
  signataireForm!: UntypedFormGroup;
  telephone = {};

  ngOnInit(): void {
    this.createForm();
  }

  checkTelephoneIsValid(): boolean {
    if (this.telephoneInputComponent.telephone.input.iti.isValidNumber()) {
      this.signataireForm.controls['telephone'].setErrors(null);
      return true;
    } else {
      this.signataireForm.controls['telephone'].setErrors({ incorrect: true });
      return false;
    }
  }

  submitFormSignataire() {
    if (this.signataireForm.valid && this.checkTelephoneIsValid()) {
      const fullPhoneNumber = this.telephoneInputComponent.telephone.input.iti.getNumber();
      this.signataireForm.controls['telephone'].setValue(fullPhoneNumber);
      this.formSubmitted.emit(this.signataireForm.value);
    } else {
      this.signataireForm.markAllAsTouched();
    }
  }

  onBlur(): void {
    const signataireTelephone = document.getElementById('signataire-telephone');
    if (signataireTelephone?.classList.contains('ds-input-group--error')) {
      this.signataireForm.controls['telephone'].setErrors({ incorrect: true });
    }
  }

  private createForm() {
    this.telephone = new dsLib.InputPhone('telephone');

    this.signataireForm = new UntypedFormGroup({
      civilite: new FormControl(null, [Validators.required]),
      nom: new FormControl(null, [Validators.required]),
      prenom: new FormControl(null, [Validators.required]),
      fonction: new FormControl(null, [Validators.required]),
      telephone: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required]),
      representantLegal: new FormControl(null, [Validators.required]),
    });
    if (this.isSiteAdmin) {
      this.signataireForm.addControl('statut', new FormControl(null, [Validators.required]));
    }
    this.refreshFormData();
    this.refreshFormState();
  }

  onCancel() {
    this.canceled.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['signataire'] && this.signataire) {
      this.refreshFormData();
    } else if (changes['canWrite'] && this.signataireForm) {
      this.refreshFormState();
    }
  }

  isFormDirty(): boolean {
    return this.signataireForm.dirty;
  }

  isFormValid(): boolean {
    return this.signataireForm.valid;
  }

  private refreshFormState() {
    if (this.canWrite && !this.signataire?.isFromFicheDemandeAide) {
      this.signataireForm.enable();
    } else {
      this.signataireForm.disable();
    }
  }

  private refreshFormData() {
    if (this.signataireForm && this.signataire) {
      this.signataireForm.patchValue(this.signataire);
      this.genres.forEach(genreItem => {
        genreItem.checked = genreItem.valeur === this.signataire.civilite;
      });
      if (this.signataire.isFromFicheDemandeAide) {
        this.signataireForm.disable();
      }
    }
  }
}
