import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { UserService } from '@services-candidat/user.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  EnumKpiPhase,
  EnumProjetEtape,
  EnumScope,
  EnumStatutGrilleImpactsProjet,
  GrilleImpactComponent,
  GrilleImpactHelperService,
  GrilleImpactHttpService,
  GrilleImpacts,
  GrilleImpactsVersion,
  KpisByCategorie,
  Projet,
  ShowToastrService,
  StatutGrilleImpacts,
  SubscriptionDestroyerComponent,
  Utilisateur,
} from '@shared-ui';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';

@Component({
  selector: 'app-projet-info-grille-impacts',
  templateUrl: './projet-info-grille-impacts.component.html',
  styleUrls: ['./projet-info-grille-impacts.component.scss'],
})
export class ProjetInfoGrilleImpactsComponent extends SubscriptionDestroyerComponent implements OnInit {
  projet: Projet = new Projet();
  aap: Aap = new Aap();
  grille: GrilleImpacts[];
  utilisateur: Utilisateur;
  isProjectUpdatableByUser = false;
  kpisByCategorieList: KpisByCategorie[] = [];
  grilleImpactsLoaded = false;
  grilleImpactsResponsesLoaded = false;
  grilleImpacts: GrilleImpacts;

  viewVersionGI = false;
  selectedVersion: GrilleImpactsVersion = null;
  kpiVersions: GrilleImpactsVersion[] = [];
  finalisedGI: GrilleImpacts[] = [];
  completedDraftGI: GrilleImpacts;
  index: number;
  ceQueJeDoisSavoirOpen = true;
  isSelection: boolean;

  @ViewChild(GrilleImpactComponent) grilleImpactComponent: GrilleImpactComponent;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.grilleImpactComponent ? this.grilleImpactComponent.canDeactivate() : true;
  }

  constructor(
    private projetService: ProjetService,
    private aapService: AapService,
    private router: Router,
    private route: ActivatedRoute,
    private showToastrService: ShowToastrService,
    public dialog: MatDialog,
    public grilleImpactHttpService: GrilleImpactHttpService,
    public userService: UserService,
    public sharedFunction: SharedFunction,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private grilleImpactHelperService: GrilleImpactHelperService
  ) {
    super();
  }

  ngOnInit(): void {
    this.getProjet();

    this.grilleImpactHelperService
      .getCeQueJeDoisSavoirOpenForProjet()
      .pipe(this.takeUntilDestroyed())
      .subscribe(open => (this.ceQueJeDoisSavoirOpen = open));

    const etape = this.sharedFunction.getProjectEtapeName(this.projet);
    this.isSelection = etape !== EnumProjetEtape.SUIVI;
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    const projetId = this.route.snapshot.parent.params.id;
    if (projetId) {
      this.projetService
        .getProjetObservable()
        .pipe(
          concatMap(responseProjet => {
            if (responseProjet) {
              this.projet = responseProjet;
              return this.aapService.loadAapSubject();
            }
          })
        )
        .pipe(this.takeUntilDestroyed())
        .subscribe({
          next: responseAap => {
            if (responseAap) {
              this.aap = responseAap.body;
              this.getUser();
              this.loadKpisByCategorie(responseAap.body.id);
              this.loadGrilleImpacts(this.projet.id, EnumScope.PROJET);
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    }
  }

  getUser(): void {
    this.userService
      .getUserObservable()
      .pipe(this.takeUntilDestroyed())
      .subscribe(response => {
        if (response) {
          this.utilisateur = response;
          this.isProjectUpdatableByUser = this.sharedFunction.isProjectUpdatableByUser(this.aap, this.projet, this.utilisateur);
        }
      });
  }

  loadKpisByCategorie(aapId: string): void {
    this.kpiVersions = [];
    this.grilleImpactHttpService
      .getKpisByCategorie(aapId, EnumScope.PROJET)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: response => {
          this.kpisByCategorieList = this.grilleImpactHelperService.retrieveProjetKpisAccordingPhase(response, [EnumKpiPhase.DEPOT_CIBLE]);
          this.grilleImpactsLoaded = true;
          this.changeDetectorRef.detectChanges();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  loadGrilleImpacts(projetId: string, scope: EnumScope): void {
    this.kpiVersions = [];
    this.finalisedGI = [];
    this.grilleImpactHttpService
      .getGrilleImpactByFilters(projetId, null, scope, false)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: response => {
          response.body.forEach((k: GrilleImpacts) => {
            if (k.statut === StatutGrilleImpacts.FINALISED) {
              this.finalisedGI.push(k);
              this.kpiVersions.push({ ...k.version, statut: k.statut });
            } else {
              this.completedDraftGI = k;
            }
          });
          if (this.kpiVersions.length > 0) this.viewVersionGI = true;

          this.kpiVersions = GrilleImpactHelperService.sortGrilleImpact(this.kpiVersions);
          this.selectedVersion = this.kpiVersions[this.index] ? this.kpiVersions[this.index] : null;
          this.grilleImpacts = this.grilleImpacts ? this.grilleImpacts : this.completedDraftGI;
          this.grilleImpactsResponsesLoaded = true;
          this.changeDetectorRef.detectChanges();
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  onChangeKpiVersion(): void {
    this.grilleImpacts = this.finalisedGI.find(
      (gi: GrilleImpacts) =>
        gi.statut === this.selectedVersion.statut &&
        gi.version.dateValidation === this.selectedVersion.dateValidation &&
        gi.version.typeVersion === this.selectedVersion.typeVersion
    );

    this.index = this.kpiVersions.findIndex(
      (kpi: GrilleImpactsVersion) =>
        kpi.statut === this.selectedVersion.statut &&
        kpi.dateValidation === this.selectedVersion.dateValidation &&
        kpi.typeVersion === this.selectedVersion.typeVersion
    );

    this.changeDetectorRef.detectChanges();
    this.grilleImpactHelperService.setGrillesImpactsData(this.grilleImpacts);
  }

  restoreKpiVersion() {
    this.grilleImpacts = this.completedDraftGI;
    this.selectedVersion = null;
    this.index = -1;
    this.changeDetectorRef.detectChanges();
    this.grilleImpactHelperService.setGrillesImpactsData(this.grilleImpacts);
  }

  /*
   * Cette méthode permet de retourner à la page d'information projet
   * */
  onGoToProjetInfo(): void {
    this.router.navigate(['../projet-info'], { relativeTo: this.route });
  }

  get isGrilleImpactsProjetValid() {
    return (
      this.projet.grilleImpacts?.validation?.statut === EnumStatutGrilleImpactsProjet[EnumStatutGrilleImpactsProjet.VALIDEE.toString()]
    );
  }

  closeOrOpenCeQueJeDoisSavoir() {
    this.grilleImpactHelperService.setCeQueJeDoisSavoirOpenForProjet(!this.ceQueJeDoisSavoirOpen);
  }

  protected readonly EnumProjetEtape = EnumProjetEtape;
  protected readonly StatutGrilleImpacts = StatutGrilleImpacts;
}
