import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Environment, RechercheTransverse, RechercheTransverseExportCriteria, SearchCriteria } from '@shared-ui';
import { Observable } from 'rxjs';

@Injectable()
export class RechercheTransverseService {
  apiUrl: string;

  constructor(@Inject('environment') private environment: Environment, private httpClient: HttpClient) {
    this.apiUrl = this.environment.apiSearchUrl + 'api/recherche-transverse/';
  }

  search(searchObject: SearchCriteria): Observable<HttpResponse<RechercheTransverse[]>> {
    return this.httpClient.post<RechercheTransverse[]>(this.apiUrl + 'search', searchObject, { observe: 'response' });
  }

  getAutocomplete(searchObject: { query: string; fieldName: string; isPrefix: boolean }): Observable<HttpResponse<string[]>> {
    return this.httpClient.get<string[]>(this.apiUrl + 'autocomplete', { params: searchObject, observe: 'response' });
  }

  getAllFieldValues(fieldName: string): Observable<HttpResponse<string[]>> {
    return this.httpClient.get<string[]>(this.apiUrl + 'field-values', { params: { fieldName }, observe: 'response' });
  }

  export(criteria: RechercheTransverseExportCriteria): Observable<HttpResponse<string>> {
    return this.httpClient.post(this.apiUrl + 'export', criteria, { observe: 'response', responseType: 'text' });
  }
}
