import { Injectable } from '@angular/core';
import { FicheDemandeAideStatut } from '../../models/fiche-demande-aide-statut.model';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnumRoleContact } from '../../enums/enum.roleContact';
import { DocumentService } from '../document.service';
import { FicheDemandeAideHttpService } from '../fiche-demande-aide/fiche-demande-aide.http.service';
import { GrilleImpactHttpService } from '../grille-impact.http.service';
import { GrilleImpactHelperService } from '../grille-impacts.helper.service';
import { InformationsBancairesHttpService } from '../informations-bancaires/informations-bancaires.http.service';
import { StructureHelperService } from './structure.helper.service';
import { Structure } from '../../models/structure.model';
import { Projet } from '../../models/projet.model';
import { Aap } from '../../models/aap.model';
import { EnumRoleStructure } from '../../enums/enum.roleStructure';
import { EnumTypePartenaire } from '../../enums/enum.partenaireType';
import { GrilleImpactStatutModel } from '../../models/grille-impact-statut.model';
import { DocumentProjet } from '../../models/documentProjet.model';
import { InformationBancaire } from '../../models/information-bancaire.model';
import { FicheDemandeAidePresent } from '../../models/ficheDemandeAidePresent.model';
import { SignataireInterface } from '../../interfaces/signataire.interface';
import { EnumTypeDocument } from '../../enums/enum.typeDocument';
import { EnumMotifNonRattachement } from '../../enums/enum.motifNonRattachement';
import { EnumProjetEtape } from '../../enums/enumProjetEtape';
import { Budget } from '../../models/budget.model';
import { Contact } from '../../models/contact.model';
import { MembreEquipe } from '../../models/membreEquipe.model';
import { Adresse } from '../../models/adresse.model';
import { DocumentProjetInterface } from '../../interfaces/documentProjet.interface';
import { DocumentAapModel } from '../../models/document-aap.model';
import { Kpi } from '../../models/kpi.model';
import { EnumScanDocument } from '../../enums/enum.scanDocument';
import { EnumStatutDemandeAide } from '../../enums/enum.statut-demande-aide';
import { EnumValidation } from '../../enums/enum.validation';
import { EnumScope } from '../../enums/enum.scope';
import { EnumTypeStructure } from '../../enums/enum.typeStructure';
import { StatutGrilleImpacts } from '../../enums/enum.statut-grille-impact';
import { StructureRoleType } from '../../types/structureRole.type';
import { Status } from '../../models/donnees-financieres.model';
import { SignataireHttpService } from '../signataire/signataire.http.service';
import { getProjectEtapeName } from '../../../utils/projet.utils';

@Injectable({
  providedIn: 'root',
})
export class StructureValidationService {
  /*
      Fonctionnement du service :
    - Ce service est un regroupement des fonctions de validation des structures (niveau "Tâches à réaliser) dans le projet "pxl_front_candidat_nx"
    - Ces règles ont été simplifiées, réfactorées et adaptées pour s'appliquer au niveau d'une seule structure
    - Les fonctions de validation sont pures et retournent uniquement un booléen, elles ne contiennent aucune souscription à des Observables
    - Tous les appels HTTP permettant de récupérer les données nécessaires à la validation sont donc réalisés en amont
    - Les données récupérées sont ensuite passées en paramètres des fonctions de validation

      Découpage du code :
      - une fonction de validation par élément à valider
      - une fonction de validation pour une seule structure, qui appelle ces dernières fonctions
      - une fonction de validation pour plusieurs structures, qui pour chaque structure, appelle la fonction de validation d'une structure

      Liste des appels http réalisés en amont de la validation :
       - grilleImpactsStatuts => niveau projet
       - ficheDemandeAideStatut => niveau projet
       - documents de la structure => pour chaque structure
       - informationsBancaires[] de la structure => pour chaque structure
       - Signataire[] de la structure => pour chaque structure
    */

  // TODO : voir pourquoi il avait été décidé de faire ça dans le code précédent
  private readonly RESPONSABLE_PROJET: EnumRoleContact = (EnumRoleContact as any)[EnumRoleContact.RESPONSABLE_PROJET.toString()];
  private readonly REPRESENTANT_LEGAL: EnumRoleContact = (EnumRoleContact as any)[EnumRoleContact.REPRESENTANT_LEGAL.toString()];
  private readonly RESPONSABLE_ADMINISTRATIF: EnumRoleContact = (EnumRoleContact as any)[
    EnumRoleContact.RESPONSABLE_ADMINISTRATIF.toString()
  ];

  constructor(
    private documentService: DocumentService,
    private signataireHttpService: SignataireHttpService,
    private ficheDemandeAideHttpService: FicheDemandeAideHttpService,
    private grilleImpactHttpService: GrilleImpactHttpService,
    private informationsBancairesHttpService: InformationsBancairesHttpService
  ) {}

  // PLUSIEURS STRUCTURES

  public areAllStructuresValid(structures: Structure[], projet: Projet, aap: Aap): Observable<boolean> {
    if (!structures.length) {
      return of(false);
    }
    // TODO : à vérifier si ces 2 conditions ne s'appliquent que si l'utilisateur a la permission "Write All"
    const structuresNonMandataires: Structure[] = structures.filter(
      (structure: Structure) => structure.role !== EnumRoleStructure.MANDATAIRE
    );
    if (projet.partenaireType === EnumTypePartenaire.MULTI && structuresNonMandataires.length < 2) {
      return of(false);
    }
    if (!structures.some((structure: Structure) => structure.role === EnumRoleStructure.CHEF_DE_FILE)) {
      return of(false);
    }
    return this.buildValidityByStructureId(structures, projet, aap).pipe(
      map((validityByStructureId: Record<string, boolean>) => Object.values(validityByStructureId).every((isValid: boolean) => isValid))
    );
  }

  public buildValidityByStructureId(structures: Structure[], projet: Projet, aap: Aap): Observable<Record<string, boolean>> {
    // Cette fonction construit une map avec en clé l'id de la structure et en valeur un booléen permettant de déterminer si la structure est valide

    if (!structures.length) {
      return of({});
    }

    type FetchedStructureData = {
      structure: Structure;
      ficheDemandeAideStatut?: FicheDemandeAideStatut | undefined;
      grilleImpactStatuts?: GrilleImpactStatutModel;
      documents: DocumentProjet[];
      bankDetails: InformationBancaire[];
      signataires: SignataireInterface[];
    };

    return forkJoin({
      grilleImpactStatuts: this.grilleImpactHttpService.getProjectAndStructuresKpisStatus(projet.id),
      ficheDemandeAideStatuts: this.ficheDemandeAideHttpService.fetchFichesDemandeAideStatusByProjectId(projet.id),
      structureData: forkJoin(
        structures.map((structure: Structure) =>
          forkJoin({
            documents: this.documentService.fetchStructureDocuments(projet.id, structure.id, [
              EnumTypeDocument.STRUCTURE,
              EnumTypeDocument.STRUCTURE_ACTIONARIAL,
            ]),
            bankDetails: this.informationsBancairesHttpService.getInformationsBancaires(structure.id).pipe(map(res => res.body || [])),
            signataires: this.signataireHttpService.fetchSignataires(structure.id),
          }).pipe(
            map(({ documents, bankDetails, signataires }) => ({
              structure,
              documents,
              bankDetails,
              signataires,
            }))
          )
        )
      ),
    }).pipe(
      map(({ grilleImpactStatuts, ficheDemandeAideStatuts, structureData }) => {
        return structureData.reduce((acc: Record<string, boolean>, fetchedStructureData: FetchedStructureData) => {
          const ficheDemandeAideStatut: FicheDemandeAideStatut | undefined = ficheDemandeAideStatuts.find(
            (ficheDemandeAideStatut: FicheDemandeAideStatut) => ficheDemandeAideStatut.structureId === fetchedStructureData.structure.id
          );
          const { structure, documents, bankDetails, signataires } = fetchedStructureData;
          acc[structure.id] = this.isOneStructureValid(
            structure,
            projet,
            aap,
            grilleImpactStatuts,
            ficheDemandeAideStatut,
            documents,
            bankDetails,
            signataires
          );
          return acc;
        }, {});
      })
    );
  }

  // UNE STRUCTURE

  public isOneStructureValid(
    structure: Structure,
    projet: Projet,
    aap: Aap,
    grilleImpactStatutModel: GrilleImpactStatutModel,
    ficheDemandeAideStatut: FicheDemandeAideStatut | undefined,
    documents: DocumentProjet[],
    bankDetails: InformationBancaire[],
    signataires: SignataireInterface[]
  ): boolean {
    if (structure.closed) {
      return true;
    }

    const etapeProjet: EnumProjetEtape | undefined = getProjectEtapeName(projet.etapes);
    const budget: Budget = etapeProjet === EnumProjetEtape.PRE_DEPOT ? structure.budgetPreDepot : structure.budgetDepot;
    const isMandataire: boolean = structure.role === EnumRoleStructure.MANDATAIRE;

    return (
      this.isGeneralInformationValid(structure, isMandataire) &&
      this.isBudgetValid(isMandataire, etapeProjet, budget, aap) &&
      this.areAllContactsValid(structure, isMandataire, aap) &&
      this.arePrevisionsEconomiquesValid(aap.previsionsEconomiquesPresent, isMandataire, structure, aap, etapeProjet) &&
      this.areElementsFinanciersValid(aap.elementsFinanciersPresent, isMandataire, structure, aap, etapeProjet) &&
      this.areDocumentsValid(structure, documents || [], aap, projet.partenaireType, etapeProjet) &&
      this.isGrilleImpactValid(etapeProjet, aap, grilleImpactStatutModel, structure) &&
      this.isFicheDemandeAideValid(etapeProjet, aap, ficheDemandeAideStatut, budget) &&
      (aap.immersion || this.areBankDetailsValid(bankDetails || [])) &&
      this.isSignataireValid(signataires || [], aap, etapeProjet, structure)
    );
  }

  // GENERAL INFORMATION

  public isGeneralInformationValid(structure: Structure, isMandataire: boolean): boolean {
    // TODO : voir pourquoi CP a une importance particulière et pas le reste de l'adresse...
    if (structure.raisonSiret !== EnumMotifNonRattachement.EN_COURS_DE_CREATION && !structure.adresse?.cp) {
      return false;
    }
    if (!isMandataire && !structure?.lieuRD?.raisonSocial) {
      return false;
    }
    return true;
  }

  // BUDGET

  public isBudgetValid(isMandataire: boolean, etapeProjet: EnumProjetEtape | undefined, budget: Budget, aap: Aap): boolean {
    if (!isMandataire) {
      // TODO : voir si pour "besoin" il est justifié que "false" soit différent de "undefined" (code existant : besoin !== false)
      if (
        ((etapeProjet === EnumProjetEtape.PRE_DEPOT && !aap.budgetEstime) || etapeProjet === EnumProjetEtape.DEPOT) &&
        budget.besoin &&
        !budget.montant
      ) {
        return false;
      }
    }
    return true;
  }

  // CONTACTS

  public areAllContactsValid(structure: Structure, isMandataire: boolean, aap: Aap): boolean {
    const contacts: Contact[] = structure.contacts || [];
    const teamMembers: MembreEquipe[] = structure.equipe || [];
    const checkTeam: boolean = aap.equipePresent && aap.equipeObligatoire;
    return isMandataire ? this.areMandataireContactsValid(contacts) : this.areNotMandataireContactsValid(contacts, teamMembers, checkTeam);
  }

  private areMandataireContactsValid(contacts: Contact[]): boolean {
    return (
      !!contacts.length &&
      contacts.filter((contact: Contact) => !contact.inactif).every((contact: Contact) => this.isStructureContactValid(contact))
    );
  }

  private areNotMandataireContactsValid(contacts: Contact[], teamMembers: MembreEquipe[], checkTeam: boolean): boolean {
    let isValid = true;
    if (checkTeam) {
      isValid =
        !!contacts.length && this.areTeamMembersValid(teamMembers) && this.isResponsableEquipeResponsableContact(contacts, teamMembers);
    }
    return isValid && this.areRlRpRaRolesPresent(contacts);
  }

  areTeamMembersValid(teamMembers: MembreEquipe[]): boolean {
    return !!teamMembers.length && teamMembers.every((teamMember: MembreEquipe) => this.isTeamMemberValid(teamMember));
  }

  isTeamMemberValid(teamMember: MembreEquipe): boolean {
    return Boolean(
      teamMember.identite?.nom &&
        teamMember.identite?.prenom &&
        teamMember.identite?.genre &&
        teamMember.identite?.dateNaissance &&
        teamMember.infosProjet?.roleEquipe &&
        teamMember.formation?.niveauFormation &&
        teamMember.idDocumentCV
    );
  }

  private isResponsableEquipeResponsableContact(contacts: Contact[], teamMembers: MembreEquipe[]): boolean {
    const responsableContact: Contact | undefined = contacts.find(
      (contact: Contact) =>
        !contact.inactif && contact.roles.some(role => [EnumRoleContact.RESPONSABLE_PROJET, this.RESPONSABLE_PROJET].includes(role))
    );
    const responsableEquipe: MembreEquipe | undefined = teamMembers.find(
      (teamMember: MembreEquipe) => !!teamMember.identite?.responsableProjet
    );

    return (
      !!responsableContact &&
      !!responsableEquipe &&
      responsableContact.nom?.toLocaleLowerCase() === responsableEquipe.identite?.nom?.toLocaleLowerCase() &&
      responsableContact.genre?.toLocaleLowerCase() === responsableEquipe.identite?.genre?.toLocaleLowerCase() &&
      responsableContact.prenom?.toLocaleLowerCase() === responsableEquipe.identite?.prenom?.toLocaleLowerCase()
    );
  }

  private areRlRpRaRolesPresent(contacts: Contact[]): boolean {
    const roles: EnumRoleContact[] = contacts.flatMap((contact: Contact) => (this.isStructureContactValid(contact) ? contact.roles : []));
    return (
      roles.some(role => [EnumRoleContact.RESPONSABLE_PROJET, this.RESPONSABLE_PROJET].includes(role)) &&
      roles.some(role => [EnumRoleContact.REPRESENTANT_LEGAL, this.REPRESENTANT_LEGAL].includes(role)) &&
      roles.some(role => [EnumRoleContact.RESPONSABLE_ADMINISTRATIF, this.RESPONSABLE_ADMINISTRATIF].includes(role))
    );
  }

  private isStructureContactValid(contact: Contact): boolean {
    return Boolean(contact.nom && contact.prenom && contact.telephone && contact.email && contact.genre);
  }

  // TODO : au niveau des éléments d'une structure pour un seul contact
  public isOneContactValid(contact: Contact, raisonSiret: EnumMotifNonRattachement): boolean {
    if (!this.isContactIdentityValid(contact)) {
      return false;
    }
    // TODO : souci car EnumRoleContact pas la même chose
    if (raisonSiret === EnumMotifNonRattachement.EN_COURS_DE_CREATION && contact?.roles?.includes(this.RESPONSABLE_PROJET)) {
      return this.isPersonalAddressValid(contact.adresse);
    }
    return true;
  }

  private isContactIdentityValid(contact: Contact): boolean {
    const { genre, nom, prenom, telephone, email, fonction } = contact;
    return !!(genre && nom && prenom && telephone && email && fonction);
  }

  private isPersonalAddressValid(adresse: Adresse | undefined | null): boolean {
    if (!adresse) {
      return false;
    }
    const { pays, codePays, cp, ville, numero, voie, typeVoie } = adresse;
    return !!(pays && codePays && cp && ville && numero && voie && typeVoie);
  }

  // DOCUMENTS

  // TODO : vérifier documents uniquement si pas mandataire ?

  public areDocumentsValid(
    structure: Structure,
    listDocumentStructure: DocumentProjetInterface[],
    aap: Aap,
    partenaireType: EnumTypePartenaire,
    etapeProjet: EnumProjetEtape | undefined
  ): boolean {
    let listDoc: DocumentAapModel[] = this.getStructureAapDocuments(aap.documentAaps, structure, partenaireType, etapeProjet);
    let listDocActio: DocumentAapModel[] = this.getStructureAapDocuments(
      aap.documentAaps,
      structure,
      partenaireType,
      etapeProjet,
      EnumScope.STRUCTURE_ACTIONARIAL
    );
    listDoc = listDoc.filter(document => document.etapes[0] === etapeProjet);
    listDocActio = listDocActio?.filter(document => document.etapes[0] === etapeProjet);
    if (etapeProjet === EnumProjetEtape.PRE_DEPOT && !aap.budgetEstime) {
      return this.areDepotPredepotDocsValid(
        listDocumentStructure,
        listDoc,
        listDocActio,
        structure.budgetPreDepot?.besoin || false,
        etapeProjet
      );
    } else if (etapeProjet !== EnumProjetEtape.PRE_DEPOT) {
      return this.areDepotPredepotDocsValid(
        listDocumentStructure,
        listDoc,
        listDocActio,
        structure.budgetDepot?.besoin || false,
        etapeProjet
      );
    }
    return false;
  }

  private getStructureAapDocuments(
    docs: DocumentAapModel[] = [],
    structure: Structure,
    partenaireType: EnumTypePartenaire,
    etapeProjet: EnumProjetEtape | undefined,
    checkedScope: EnumScope = EnumScope.STRUCTURE
  ): DocumentAapModel[] {
    return docs.filter(document => {
      const { etapes, typePartenaires, scope, roleStructures, typeStructures } = document;
      return (
        etapes.some((etape: EnumProjetEtape) => etape === etapeProjet) &&
        typePartenaires?.includes(partenaireType) &&
        scope === checkedScope &&
        roleStructures &&
        roleStructures.includes(structure.role) &&
        typeStructures.some((structureType: EnumTypeStructure) => structureType === structure.typeStructure)
      );
    });
  }

  private areDepotPredepotDocsValid(
    listDocumentStructure: DocumentProjetInterface[],
    listDoc: DocumentAapModel[],
    listDocActio: DocumentAapModel[],
    budgetBesoin: boolean,
    etapeProjet: EnumProjetEtape | undefined
  ): boolean {
    if (!listDocumentStructure.length && (listDoc.length || listDocActio.length)) {
      return false;
    } else {
      let isValid = true;
      if (listDoc.length && budgetBesoin) {
        isValid = this.areDocsByScopeValid(listDocumentStructure, etapeProjet, EnumScope.STRUCTURE);
      }
      if (listDocActio.length && budgetBesoin) {
        isValid = isValid && this.areDocsByScopeValid(listDocumentStructure, etapeProjet, EnumScope.STRUCTURE_ACTIONARIAL);
      }
      return isValid && this.areAllDocumentsSafe(listDocumentStructure);
    }
  }

  private areDocsByScopeValid(
    listDocumentStructure: DocumentProjetInterface[],
    etapeProjet: EnumProjetEtape | undefined,
    scope: EnumScope
  ): boolean {
    return listDocumentStructure.some((document: DocumentProjetInterface) => document.scope === scope && document.etape === etapeProjet);
  }

  private areAllDocumentsSafe(listDocumentStructure: DocumentProjetInterface[]): boolean {
    return !listDocumentStructure.some((document: DocumentProjetInterface) => document.scan === EnumScanDocument.UNSAFE);
  }

  // Grille d'impact

  public isGrilleImpactValid(
    etapeProjet: EnumProjetEtape | undefined,
    aap: Aap,
    statuts: GrilleImpactStatutModel | undefined,
    structure: Structure
  ): boolean {
    const kpis: Kpi[] = aap.kpis || [];
    const isKpiNeeded: boolean = kpis.some((kpi: Kpi) => [EnumScope.STRUCTURE, EnumScope.PROJET].includes(kpi.scope));
    if (!isKpiNeeded) {
      return true;
    }
    return !this.isStructureWithMandatoryGI(etapeProjet, aap, kpis, structure) || this.isGIStructureValid(statuts, structure.id);
  }

  public isStructureWithMandatoryGI(etapeProjet: EnumProjetEtape | undefined, aap: Aap, kpis: Kpi[], structure: Structure): boolean {
    if (structure.closed) {
      return false;
    }
    const isKpiForAllTypeOfStructures: boolean = kpis.some((kpi: Kpi) => kpi.typeStructure === 'TOUT');
    if (isKpiForAllTypeOfStructures) {
      return !StructureHelperService.structureHasRole(structure, ['MANDATAIRE']) && this.isFinancingNeeded(aap, etapeProjet, structure);
    }
    const requiredStructureRoles: StructureRoleType[] = kpis
      .map(kpi => kpi.typeStructure)
      .filter((typeStructure: StructureRoleType | undefined) => typeStructure) as StructureRoleType[];
    return (
      requiredStructureRoles.some((requiredRole: StructureRoleType) => structure.role === requiredRole) &&
      this.isFinancingNeeded(aap, etapeProjet, structure)
    );
  }

  public isFinancingNeeded(aap: Aap, etape: EnumProjetEtape | undefined, structure: Structure): boolean {
    if (etape === EnumProjetEtape.PRE_DEPOT && !aap.budgetEstime && structure.budgetPreDepot) {
      return structure.budgetPreDepot.besoin || false;
    } else if (etape !== EnumProjetEtape.PRE_DEPOT && structure.budgetDepot) {
      return structure.budgetDepot.besoin || false;
    }
    return true;
  }

  public isGIStructureValid(grilleImpactsStatuts: GrilleImpactStatutModel | undefined, structureId: string): boolean {
    const statut: StatutGrilleImpacts | undefined = GrilleImpactHelperService.getGIStructureStatut(grilleImpactsStatuts, structureId);
    return (statut && GrilleImpactHelperService.isGrilleImpactsValid(statut)) || false;
  }

  // Fiche demande d'aide

  public isFicheDemandeAideValid(
    etapeProjet: EnumProjetEtape | undefined,
    aap: Aap,
    ficheDemandeAideStatut: FicheDemandeAideStatut | undefined,
    budget: Budget
  ): boolean {
    // TODO : Si pas de fiche demande d'aide pour une structure, alors c'est valide
    if (!ficheDemandeAideStatut) {
      return true;
    }
    const fiche: FicheDemandeAidePresent = aap.ficheDemandeAidePresent;
    if (!fiche || !fiche.active || fiche.etape !== etapeProjet) {
      return true;
    }
    return !budget.besoin || ficheDemandeAideStatut.statut === EnumStatutDemandeAide.COMPLETED;
  }

  // Informations bancaires

  public areBankDetailsValid(bankDetails: InformationBancaire[]): boolean {
    return bankDetails.some((detail: InformationBancaire) => detail.selectionne);
  }

  // Signataire

  public isSignataireValid(
    signataires: SignataireInterface[],
    aap: Aap,
    etape: EnumProjetEtape | undefined,
    structure: Structure
  ): boolean {
    return !!signataires.length || !this.isFinancingNeeded(aap, etape, structure);
  }

  // Prévisions économiques

  public arePrevisionsEconomiquesValid(
    arePrevisionsEconomiquesPresent: boolean,
    isMandataire: boolean,
    structure: Structure,
    aap: Aap,
    etapeProjet: EnumProjetEtape | undefined
  ): boolean {
    if (!arePrevisionsEconomiquesPresent) {
      return true;
    }
    return (
      isMandataire ||
      String(structure.previsionsEconomiques?.statut) === EnumValidation[EnumValidation.VALIDE] ||
      this.isFinancingNeeded(aap, etapeProjet, structure)
    );
  }

  // Eléments financiers

  public areElementsFinanciersValid(
    elementsFinanciersPresent: boolean,
    isMandataire: boolean,
    structure: Structure,
    aap: Aap,
    etapeProjet: EnumProjetEtape | undefined
  ): boolean {
    if (!elementsFinanciersPresent || EnumProjetEtape.PRE_DEPOT === etapeProjet) {
      return true;
    }
    return isMandataire || structure.donneesFinancieres?.statut === Status.VALIDE || this.isFinancingNeeded(aap, etapeProjet, structure);
  }
}
