<!-- Entête de la page -->
<header id="header-contact" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToConsortiumInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Ma structure
  </a>
  <h2 id="title-new-contact" class="page-header__title" *ngIf="!contactId && !targetedCreation">Nouveau contact</h2>
  <h2 id="title-contact-target" class="page-header__title" *ngIf="targetedCreation">{{ targetedCreation }}</h2>
  <h2 id="title-contact-name" class="page-header__title" *ngIf="contactId">{{ contact?.nom }} {{ contact?.prenom }}</h2>
  <!-- ATTENTION : bouton dupliqué en bas de page (pour mobiles) -->
  <div class="page-header__action">
    <button
      class="delete-contact-btn"
      (click)="deleteContact()"
      *ngIf="contactId && isRepresentantLegal && isStructureUpdatableByUser && !isRepresentantLegalSignataireInOngoingProcedure"
    >
      <lib-pxl-icon class="icon" icon="icon-action-delete-outline" aria-hidden="true"></lib-pxl-icon>
      Supprimer le.a représentant.e légal.e
    </button>
    <button class="delete-contact-btn" (click)="deleteContact()" *ngIf="canDeletedContact">
      <lib-pxl-icon class="icon" icon="icon-action-delete-outline" aria-hidden="true"></lib-pxl-icon>
      Supprimer le contact
    </button>
  </div>
</header>
<!-- FIN de Entête de la page -->

<!-- Contenu de la page -->
<div class="page-content">
  <lib-card-info
    *ngIf="contact.inactif || isRepresentantLegalSignataireInOngoingProcedure"
    message="Vous êtes en lecture seule sur cette page. Vous ne pouvez pas éditer son contenu. {{
      projet.inactif ? 'Pour effectuer des modifications sur le projet, vous devez d’abord le réactiver.' : null
    }}"
  ></lib-card-info>

  <!-- Message d'information signataire procedure en cours -->
  <div class="card--info card--blue card--readonly" *ngIf="isRepresentantLegalSignataireInOngoingProcedure">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    Pour modifier les données d'un signataire, veuillez au préalable annuler la procédure et le désélectionner de la liste des signataires
    de la structure.
  </div>

  <div
    class="card card--consortium-alert"
    *ngIf="!isRepresentantLegal && (contact.inactif || (invitation?.active && invitation?.expired)) && !structure?.closed"
  >
    <div id="structure" class="card__message">
      {{ textRenvoyerInvitation() }}
    </div>
    <button
      type="button"
      *ngIf="isStructureUpdatableByUser"
      id="renvoyer-btn"
      class="card__button ds-btn ds-btn--primary btn-color"
      (click)="resendInvitation()"
    >
      <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon>
      Renvoyer l'invitation
    </button>
  </div>

  <lib-card-info
    *ngIf="lastEtape === 'DEPOT' && isRepresentantLegal"
    color="yellow"
    icon="icon-info-warning"
    message="Merci de fournir la pièce d'identité du représentant légal déclaré au niveau de la section “Document(s) de la structure”."
  ></lib-card-info>

  <form id="contact-form" class="form" [formGroup]="contactForm" (ngSubmit)="submitFormContact(contactForm.value)">
    <!-- Identité -->
    <section class="form-step identite">
      <h3 class="form-step__title">Identité</h3>
      <div class="form-step__content">
        <!-- Genre  -->
        <div
          id="input-raison"
          class="ds-input-group genre_selection_option_overwrite"
          [class.ds-input-group--error]="contactForm.controls['genre'].invalid && contactForm.controls['genre'].touched"
        >
          <label for="genre" class="ds-input-group__label">Civilité<lib-asterisk></lib-asterisk></label>
          <div class="div_genre_checkboxs">
            <label *ngFor="let genre of genres">
              <input
                class="btnlike"
                type="checkbox"
                (change)="selectGenre(genre)"
                [checked]="genre.checked"
                [attr.disabled]="contact.inactif === true ? true : null"
              />
              <span
                [ngClass]="{ genre_invalid: contactForm.controls['genre'].invalid && contactForm.controls['genre'].touched }"
                [ngClass]="{ genre_disabled: contact.inactif }"
                class="unselectable"
                >{{ EnumGenre.toString(genre.valeur) }}</span
              >
            </label>
          </div>

          <!-- Icône et message d'erreur -->
          <ng-container id="error-raison" *ngIf="contactForm.controls['genre'].invalid && contactForm.controls['genre'].touched">
            <div class="ds-input-group__feedback invalid_msg_overwrite">Merci de bien vouloir renseigner une civilité valide.</div>
          </ng-container>
        </div>

        <!-- Nom -->
        <div
          id="input-nom"
          class="ds-input-group"
          [class.ds-input-group--error]="!contactForm.controls['nom'].valid && contactForm.controls['nom'].touched"
        >
          <label for="nom" class="ds-input-group__label">Nom<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="nom"
              name="nom"
              type="text"
              placeholder="Votre nom"
              class="ds-input-group__input"
              maxlength="30"
              pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
              formControlName="nom"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-nom" *ngIf="!contactForm.controls['nom'].valid && contactForm.controls['nom'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un nom valide</div>
          </ng-container>
        </div>

        <!-- Prénom  -->
        <div
          id="input-prenom"
          class="ds-input-group"
          [class.ds-input-group--error]="!contactForm.controls['prenom'].valid && contactForm.controls['prenom'].touched"
        >
          <label for="prenom" class="ds-input-group__label">Prénom<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="prenom"
              name="prenom"
              type="text"
              placeholder="Votre prénom"
              class="input_prenom ds-input-group__input"
              maxlength="30"
              pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
              formControlName="prenom"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-prenom" *ngIf="!contactForm.controls['prenom'].valid && contactForm.controls['prenom'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un prénom valide</div>
          </ng-container>
        </div>

        <!-- Fonction  -->
        <div
          id="input-fonction"
          class="ds-input-group"
          [class.ds-input-group--error]="!contactForm.controls['fonction'].valid && contactForm.controls['fonction'].touched"
        >
          <label for="fonction" class="ds-input-group__label">Fonction<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="fonction"
              name="fonction"
              type="text"
              placeholder="Votre fonction"
              class="input_fonction ds-input-group__input"
              maxlength="100"
              formControlName="fonction"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-fonction" *ngIf="!contactForm.controls['fonction'].valid && contactForm.controls['fonction'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une fonction valide</div>
          </ng-container>
        </div>

        <!-- Date de naissance  -->
        <div
          id="input-dateNaissance"
          class="ds-input-group ds-input-group--date"
          *ngIf="isRepresentantLegal"
          [class.ds-input-group--error]="contactForm.controls['dateNaissance'].invalid && contactForm.controls['dateNaissance'].touched"
        >
          <label class="ds-input-group__label"> Date de naissance <lib-asterisk></lib-asterisk> </label>
          <span class="ds-input-group__input">
            <input
              matInput
              [matDatepicker]="openDatePicker"
              formControlName="dateNaissance"
              class="ds-input-group__input"
              placeholder="JJ/MM/AAAA"
            />
            <mat-datepicker-toggle matSuffix [for]="openDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #openDatePicker></mat-datepicker>
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-dateNaissance"
            *ngIf="!contactForm.controls['dateNaissance'].valid && contactForm.controls['dateNaissance'].touched"
          >
            <div class="ds-input-group__feedback feedback_birthdate">Merci de bien vouloir renseigner une date de naissance valide</div>
          </ng-container>
        </div>

        <!-- Ville de naissance  -->
        <div
          id="input-villeNaissance"
          *ngIf="isRepresentantLegal"
          class="ds-input-group"
          [class.ds-input-group--error]="!contactForm.controls['villeNaissance'].valid && contactForm.controls['villeNaissance'].touched"
        >
          <label for="villeNaissance" class="ds-input-group__label">Ville de naissance<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="villeNaissance"
              name="villeNaissance"
              type="text"
              placeholder="Ville de naissance"
              class="input_villeNaissance ds-input-group__input"
              maxlength="32"
              formControlName="villeNaissance"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-villeNaissance"
            *ngIf="!contactForm.controls['villeNaissance'].valid && contactForm.controls['villeNaissance'].touched"
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une ville de naissance valide</div>
          </ng-container>
        </div>

        <!-- Pays de naissance  -->
        <div
          id="input-paysNaissance"
          *ngIf="isRepresentantLegal"
          class="ds-input-group"
          [class.ds-input-group--error]="!contactForm.controls['paysNaissance'].valid && contactForm.controls['paysNaissance'].touched"
        >
          <label for="paysNaissance" class="ds-input-group__label">Pays de naissance<lib-asterisk></lib-asterisk></label>
          <div [ngClass]="{ disabled: contactForm.disabled }">
            <lib-country-list
              id="paysNaissance"
              [selectedCountryConfig]="config"
              [countryListConfig]="config"
              [selectedCountryCode]="contactForm.get('paysNaissance').value?.code || 'FR'"
              placeholderText="Renseigner le pays"
              formControlName="paysNaissance"
              (onCountryChange)="onCountryChange($event)"
              ngDefaultControl
            >
            </lib-country-list>
          </div>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-paysNaissance"
            *ngIf="contactForm.controls['paysNaissance'].invalid && contactForm.controls['paysNaissance'].touched"
          >
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un pays de naissance valide</div>
          </ng-container>
        </div>

        <!-- Role du contact -->
        <div
          id="input-role"
          class="form-step__role"
          *ngIf="
            (structure?.role === EnumRoleStructure.CANDIDAT || structure?.role === EnumRoleStructure.CHEF_DE_FILE) && !isRepresentantLegal
          "
          [class.ds-input-group--error]="
            (!contactForm.controls['roles'].valid && contactForm.controls['roles'].touched) ||
            (contactForm.controls['roles'].touched && contactForm.controls['roles'].value.length === 0)
          "
        >
          <div class="infobulle">
            <label class="ds-input-group__label"> Responsable projet</label>
            <div [matTooltip]="'Responsable opérationnel en charge des interactions avec Bpifrance.'" [matTooltipPosition]="'right'">
              <lib-pxl-icon class="icon-info" icon="icon-action-info-circle-outline" aria-hidden="true"> </lib-pxl-icon>
            </div>
          </div>
          <div class="select-container">
            <span id="non-option" class="ds-body-2--regular label-l me-2"> Non</span>
            <label for="rp" class="ds-switch ds-switch--m">
              <input
                type="checkbox"
                id="rp"
                (change)="selectRole($event, 'RESPONSABLE_PROJET')"
                [checked]="hasRole('RESPONSABLE_PROJET')"
                [disabled]="disableBtnModification"
              />
              <span class="slider"></span>
            </label>
            <span id="oui-option" class="ds-body-2--regular label-r ms-2"> Oui</span>
          </div>
          <br />
          <div class="infobulle">
            <div class="ds-input-group__label">Responsable administratif et financier du projet</div>
            <div
              [matTooltip]="
                'Le responsable administratif et financier est la personne en capacité de répondre aux questions d\'ordre administratifs telle la connaissance du bénéficiaire, et de répondre aux questions relatives au volet analyse financière sur les exercices passés et sur les prévisionnels de compte de résultats et de plan de financement.'
              "
              [matTooltipPosition]="'right'"
            >
              <lib-pxl-icon class="icon-info" icon="icon-action-info-circle-outline" aria-hidden="true"> </lib-pxl-icon>
            </div>
          </div>
          <div class="select-container">
            <span id="non-option" class="ds-body-2--regular label-l me-2"> Non</span>
            <label for="ra" class="ds-switch ds-switch--m">
              <input
                type="checkbox"
                id="ra"
                (change)="selectRole($event, 'RESPONSABLE_ADMINISTRATIF')"
                [checked]="hasRole('RESPONSABLE_ADMINISTRATIF')"
                [disabled]="disableBtnModification"
              />
              <span class="slider"></span>
            </label>
            <span id="oui-option" class="ds-body-2--regular label-r ms-2"> Oui</span>
          </div>
        </div>
      </div>
    </section>
    <!-- FIN de Identité -->

    <!-- Contact -->
    <div class="form-step contact">
      <h3 class="form-step__title">Contact</h3>
      <div class="form-step__content">
        <!-- telephone -->
        <div
          id="input-telephone"
          #inputTelephone
          class="ds-input-group"
          [class.ds-input-group--error]="!contactForm.controls['telephone'].valid && contactForm.controls['telephone'].touched"
        >
          <label id="id_input-telephone-label" for="telephone" class="input-telephone-label ds-input-group__label"
            >Téléphone<lib-asterisk></lib-asterisk
          ></label>
          <span class="ds-input-group__input">
            <input
              (blur)="onBlur()"
              id="telephone"
              name="telephone"
              type="tel"
              maxlength="20"
              class="ds-input-group__input"
              formControlName="telephone"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container
            id="error-telephone"
            *ngIf="
              inputTelephone.classList.contains('ds-input-group--error') ||
              (contactForm.controls['telephone'].touched && contactForm.controls['telephone'].value === '')
            "
          >
            <div id="id_error-telephone-msg" class="error-telephone-msg ds-input-group__feedback">
              Merci de bien vouloir renseigner un numéro de téléphone valide
            </div>
          </ng-container>
        </div>

        <!-- mail -->
        <div
          id="input-email"
          class="ds-input-group"
          [class.ds-input-group--error]="!contactForm.controls['email'].valid && contactForm.controls['email'].touched"
        >
          <label for="email" class="ds-input-group__label">Mail<lib-asterisk></lib-asterisk></label>
          <span class="ds-input-group__input">
            <input
              id="email"
              name="email"
              type="text"
              maxlength="80"
              pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
              placeholder="contact@candidat.com"
              class="input_email ds-input-group__input"
              formControlName="email"
            />
          </span>
          <!-- Icône et message d'erreur -->
          <ng-container id="error-email" *ngIf="!contactForm.controls['email'].valid && contactForm.controls['email'].touched">
            <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un mail valide</div>
          </ng-container>
        </div>
      </div>
    </div>

    <div
      *ngIf="projectManagerChecked && structure?.raisonSiret === EnumMotifNonRattachement.EN_COURS_DE_CREATION"
      class="form-step personal-address"
    >
      <h3 class="form-step__title">Adresse personnelle</h3>
      <div class="form-step__content">
        <lib-structure-adresse
          [adresseForm]="contactForm.get('adresse')"
          [isPersonnePhysique]="structure?.raisonSiret === EnumMotifNonRattachement.EN_COURS_DE_CREATION"
        >
        </lib-structure-adresse>
      </div>
    </div>

    <!-- FIN de Contact -->
    <div id="rgpd" class="form-rgpd" *ngIf="this.aap?.programme">
      Pour en savoir plus sur la gestion de vos données personnelles, <a (click)="downloadRGPD()">cliquez ici</a>
      <br />
      Tout UTILISATEUR effectuant une (des) Demande(s) dans l’Extranet s’engage à informer les personnes dont il pourrait mentionner les
      données à caractère personnel au titre de la (des) Demande(s) des traitements de données à caractère personnel conformément au présent
      article.
    </div>

    <div id="action" class="form-actions" *ngIf="!contact.inactif && !isRepresentantLegalSignataireInOngoingProcedure">
      <button id="cancel-save" type="button" class="ds-btn ds-btn--secondary" (click)="onGoToConsortiumInfo()">Annuler</button>
      <button id="save-contact" type="submit" class="ds-btn ds-btn--primary" *ngIf="isStructureUpdatableByUser">
        <span *ngIf="!contactId">Ajouter</span>
        <span *ngIf="contactId">Modifier</span>
      </button>
    </div>

    <!-- Actions de bas de page sur mobile -->
    <button
      class="footer-action delete-contact-btn"
      (click)="deleteContact()"
      *ngIf="contactId && isRepresentantLegal && isStructureUpdatableByUser && !isRepresentantLegalSignataireInOngoingProcedure"
    >
      <lib-pxl-icon class="icon" icon="icon-action-delete-outline" aria-hidden="true"></lib-pxl-icon>
      Supprimer le.a représentant.e légal.e
    </button>
    <button class="footer-action delete-contact-btn" (click)="deleteContact()" *ngIf="canDeletedContact">
      <lib-pxl-icon class="icon" icon="icon-action-delete-outline" aria-hidden="true"></lib-pxl-icon>
      Supprimer le contact
    </button>
  </form>
</div>
