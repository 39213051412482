import { Component, Input, OnInit } from '@angular/core';
import {
  EnumStatutSignature,
  ProcedureSignatureStructure,
  SignataireProcedure,
  SyntheseContractualisationStructure,
  Utilisateur,
} from '@shared-ui';

declare const Yousign: any;

@Component({
  selector: 'lib-signature-contrat-frame',
  templateUrl: './signature-contrat-frame.component.html',
  styleUrls: ['./signature-contrat-frame.component.scss'],
})
export class SignatureContratFrameComponent implements OnInit {
  @Input() synthesesStructure!: SyntheseContractualisationStructure;
  @Input() currentProcedure!: ProcedureSignatureStructure;
  @Input() utilisateur!: Utilisateur;
  @Input() currentSigner!: SignataireProcedure;
  @Input() youSignUiId!: string;

  confirmation = false;
  needToSign: boolean;

  ngOnInit(): void {
    this.needToSign = this.currentSigner.signature.statut === EnumStatutSignature.A_SIGNER;
    this.confirmation = !this.needToSign;
  }

  onConfirmation() {
    if (this.confirmation && this.needToSign) {
      new Yousign({
        signatureLink: `${this.sanitizeUrl(this.currentSigner?.signature?.url)}&disable_domain_validation=true&signatureUi=/signature_uis/${
          this.youSignUiId
        }`,
        iframeContainerId: 'iframe-container',
        isSandbox: false,
        iFrameAttributes: {},
      });
      const context = {
        projetId: this.currentProcedure.projetId,
        structureId: this.currentProcedure.structureId,
        procedureId: this.currentProcedure.id,
      };
      localStorage.setItem('procedure-signature-contexte', JSON.stringify(context));
      this.resizeIFrame();
    }
  }

  private sanitizeUrl(url: string | undefined): string | null {
    if (!url) return null;
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.href;
    } catch {
      return null;
    }
  }

  private resizeIFrame() {
    const iFrame = document.getElementById('yousign-iframe') as any;
    if (iFrame) {
      iFrame.width = '100%';
      iFrame.height = window.innerHeight + 400 + 'px';
    }
  }
}
