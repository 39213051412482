import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, DestroyRef, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuTrigger } from '@angular/material/menu';
import {
  DateRangePickerComponent,
  DateTimeUtils,
  DsMultiselectComponent,
  FilterDataInterface,
  MultiSelectCriteria,
  MultiselectComponent,
  ProjetFilters,
  RangeCriteria,
  RangeDatesCriteria,
  RechercheTransverseService,
  ShowToastrService,
} from '@shared-ui';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'lib-projet-filters',
  templateUrl: './projet-filters.component.html',
  styleUrls: ['./projet-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'projet-filters-menu',
      },
    },
  ],
})
export class ProjetFiltersComponent implements AfterViewInit, OnInit {
  destroyRef = inject(DestroyRef);

  readonly THEMATIQUE = 'thematique';
  readonly POLE_DE_COMPETITIVITE = 'pole_de_competitivite_labellisateur';
  readonly OBJET_PROJET = 'description';
  readonly NB_PARTENAIRE = 'nb_de_partenaires';
  readonly DATE_DE_VERROUILLAGE = 'date_de_verrouillage';
  readonly PIA_VERT = 'pia_vert';
  readonly PIA_NUMERIQUE = 'pia_numerique';

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @ViewChild('thematiqueFilterComponent') thematiqueFilterComponent!: MultiselectComponent;
  @ViewChild('poleFilterComponent') poleFilterComponent!: MultiselectComponent;
  @ViewChild('dateDeVerrouillageComponent') dateDeVerrouillageComponent: DateRangePickerComponent;
  @ViewChild('piaVertComponent') piaVertComponent: DsMultiselectComponent;
  @ViewChild('piaNumeriqueComponent') piaNumeriqueComponent: DsMultiselectComponent;

  isOpened = false;

  @Output() filtersChanged: EventEmitter<ProjetFilters> = new EventEmitter<ProjetFilters>();

  thematiqueSelectedList: FilterDataInterface[] = [];
  thematiqueDataList: FilterDataInterface[] = [];
  poleSelectedList: FilterDataInterface[] = [];
  poleDataList: FilterDataInterface[] = [];
  piaVertDataList: FilterDataInterface[] = [];
  piaNumeriqueDataList: FilterDataInterface[] = [];
  objetProjetInputValue: string | undefined = '';
  minNbPartenaire!: number | undefined;
  maxNbPartenaire!: number | undefined;

  loadingMap = new Map();

  showFilters = false;

  constructor(private rechercheTransverseService: RechercheTransverseService, private toastrService: ShowToastrService) {
    this.loadingMap.set(this.THEMATIQUE, false);
    this.loadingMap.set(this.POLE_DE_COMPETITIVITE, false);
  }

  ngOnInit(): void {
    this.loadMultiselectValues();
  }

  ngAfterViewInit(): void {
    this.trigger.menuOpened.subscribe(() => {
      this.isOpened = true;
    });
  }

  loadMultiselectValues(): void {
    combineLatest([
      this.rechercheTransverseService.getAllFieldValues(this.PIA_VERT),
      this.rechercheTransverseService.getAllFieldValues(this.PIA_NUMERIQUE),
    ]).subscribe({
      next: ([piaVert, piaNumerique]) => {
        this.piaVertDataList = (piaVert.body as string[]).map(piaVert => ({ id: piaVert, value: piaVert }));
        this.piaNumeriqueDataList = (piaNumerique.body as string[]).map(piaNumerique => ({ id: piaNumerique, value: piaNumerique }));
        this.showFilters = true;
      },
      error: (err: HttpErrorResponse) => {
        this.toastrService.checkCodeError(err?.error);
      },
    });
  }

  applyFilters(): void {
    const rangeCriteriaMap: RangeCriteria = {};
    const fieldCriteriaMap: MultiSelectCriteria = {};
    const rangeDatesCriteriaMap: RangeDatesCriteria = {};
    rangeCriteriaMap[this.NB_PARTENAIRE] =
      this.minNbPartenaire && this.maxNbPartenaire && this.minNbPartenaire >= 0 && this.maxNbPartenaire >= 0
        ? { start: this.minNbPartenaire, end: this.maxNbPartenaire }
        : null;
    fieldCriteriaMap[this.THEMATIQUE] = this.getFieldCriteria(this.thematiqueSelectedList);
    fieldCriteriaMap[this.POLE_DE_COMPETITIVITE] = this.getFieldCriteria(this.poleSelectedList);
    fieldCriteriaMap[this.PIA_VERT] = this.getFieldCriteria(this.piaVertComponent.getSelectedValues());
    fieldCriteriaMap[this.PIA_NUMERIQUE] = this.getFieldCriteria(this.piaNumeriqueComponent.getSelectedValues());
    rangeDatesCriteriaMap[this.DATE_DE_VERROUILLAGE] =
      this.dateDeVerrouillageComponent.dateDebut && this.dateDeVerrouillageComponent.dateFin
        ? {
            start: DateTimeUtils.formatToDate(this.dateDeVerrouillageComponent.dateDebut)!,
            end: DateTimeUtils.formatToDate(this.dateDeVerrouillageComponent.dateFin)!,
          }
        : null;

    this.filtersChanged.emit({
      rangeFilters: rangeCriteriaMap,
      selectFilters: fieldCriteriaMap,
      rangeDatesCriterias: rangeDatesCriteriaMap,
      objetProjet: this.objetProjetInputValue,
    });
    if (this.isOpened) {
      this.trigger.closeMenu();
    }
  }

  private getFieldCriteria(selectedList: FilterDataInterface[]): string[] {
    return selectedList.length > 0 ? selectedList.map(item => item.value) : [];
  }

  resetFilters(): void {
    this.thematiqueFilterComponent?.reset();
    this.thematiqueSelectedList = [];
    this.poleFilterComponent?.reset();
    this.poleSelectedList = [];
    this.objetProjetInputValue = undefined;
    this.minNbPartenaire = undefined;
    this.maxNbPartenaire = undefined;
    this.dateDeVerrouillageComponent?.reset();
    this.piaVertComponent?.reset();
    this.piaNumeriqueComponent?.reset();
  }

  onMenuClosed(): void {
    if (this.isOpened) {
      this.isOpened = false;
      this.applyFilters();
    }
  }

  preventValues(event: KeyboardEvent): void {
    const forbiddenKeys = ['-', ',', '.', 'e', 'E', '+'];
    if (forbiddenKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  selectedListChange(event: FilterDataInterface[], source: string): void {
    switch (source) {
      case this.THEMATIQUE:
        this.thematiqueSelectedList = event;
        break;
      case this.POLE_DE_COMPETITIVITE:
        this.poleSelectedList = event;
        break;
    }
  }

  /**
   * Récupère l'input du composant fils, lance la recherche d'autocomplete, ensuite actualise la liste des options
   * @param event Event envoyé par le composant fils de filtre, soit le texte écrit dans l'input
   * @param source Le nom du composant fils qui a emit cet event
   */
  autocompleteEvent(event: string, source: string): void {
    this.loadingMap.set(source, true);
    const searchObject = { query: this.removeElasticsearchSpecialCharacters(event), fieldName: source, isPrefix: false };

    this.rechercheTransverseService.getAutocomplete(searchObject).subscribe({
      next: resultAutocomplete => {
        switch (source) {
          case this.THEMATIQUE:
            this.thematiqueDataList = (resultAutocomplete.body as string[]).map(thematique => ({ id: thematique, value: thematique }));
            break;
          case this.POLE_DE_COMPETITIVITE:
            this.poleDataList = (resultAutocomplete.body as string[]).map(pole => ({ id: pole, value: pole }));
            break;
        }
        this.loadingMap.set(source, false);
      },
      error: (err: HttpErrorResponse) => {
        this.toastrService.checkCodeError(err?.error);
        this.loadingMap.set(source, false);
      },
    });
  }

  private removeElasticsearchSpecialCharacters(str: string) {
    const specialCharacters = [
      '+',
      '-',
      '=',
      '&&',
      '.',
      '||',
      '>',
      '<',
      '!',
      '(',
      ')',
      '{',
      '}',
      '[',
      ']',
      '^',
      '"',
      '~',
      '*',
      '?',
      ':',
      '\\',
      '/',
    ];
    return str.replace(new RegExp(`[${specialCharacters.join('\\')}]`, 'g'), ' ');
  }
}
