<p class="info-size-message" *ngIf="showMaxFileSizeMessage">Poids maximum par fichier {{ fileSizeLimit }}Mo</p>
<div class="border-grid">
  <div class="file-upload-grid">
    <lib-pxl-icon class="icon" icon="icon-file-folder-insert-drive-file" aria-hidden="true" *ngIf="title"></lib-pxl-icon>
    <div class="infos">
      <span class="grid-title" *ngIf="title">{{ title }}</span>
      <ng-content select="[subtitle]"></ng-content>
    </div>
    <label class="file-upload__custom-input" *ngIf="canUserWrite">
      <input
        type="file"
        class="file-input"
        id="file"
        onclick="this.value=null;"
        (change)="onDocumentFileSelected($event)"
        [accept]="supportedExtensions"
      />
      <input type="hidden" class="file-input" name="fileHidden" />
      <div id="add-fichier" class="ds-btn ds-btn--secondary">
        <lib-pxl-icon class="icon" icon="icon-action-add"></lib-pxl-icon>
        {{ uploadButtonText }}
      </div>
    </label>
  </div>
  <div class="documents-container">
    <div class="document-list" *ngFor="let doc of documents">
      <lib-document-light
        [projetId]="projetId"
        [document]="doc"
        [canUserWrite]="canUserWrite"
        (deleteDocument)="onDocumentFileDeleted($event)"
      >
      </lib-document-light>
    </div>
  </div>
</div>

<!-- Icône et message d'erreur -->
<div id="error-documents" *ngIf="showError && errorMessage" class="ds-input-group--error">
  <div class="ds-input-group__feedback">
    {{ errorMessage }}
  </div>
</div>
