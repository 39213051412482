<ng-container *ngIf="structure">
  <!-- Entête de la page -->
  <header id="header-structure" class="page-header">
    <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
    <a class="page-header__back" (click)="onGoToConsortium()">
      <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
      <span *ngIf="projet.partenaireType === EnumTypePartenaire.MULTI">Consortium</span>
      <span *ngIf="projet.partenaireType === EnumTypePartenaire.INDIV">Structure</span>
    </a>
    <h2 class="page-header__title">{{ structure.raisonSocial }}</h2>
    <div class="page-header__action">
      <!-- ATTENTION : boutons dupliqués en bas de page (pour mobiles) -->
      <button
        id="delete-structure"
        class="delete-structure-btn"
        (click)="deleteStructure()"
        *ngIf="isStructureUpdatableByUser && checkIfUserNotInStructure()"
      >
        <lib-pxl-icon class="icon" icon="icon-action-delete-outline" aria-hidden="true"></lib-pxl-icon>
        Supprimer la structure
      </button>

      <button
        *ngIf="autresContacts?.length >= 6 && isStructureUpdatableByUser"
        title="La limite des 6 contacts autorisés par structure a été atteinte"
        id="max-contact"
        class="ds-btn ds-btn--primary"
        (click)="inviteContact()"
        [disabled]="true"
      >
        <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline" aria-hidden="true"></lib-pxl-icon>
        Inviter un contact
      </button>
      <button
        *ngIf="autresContacts?.length < 6 && isStructureUpdatableByUser"
        id="add-contact"
        class="ds-btn ds-btn--primary"
        (click)="inviteContact()"
        [disabled]="!isStructureUpdatableByUser"
      >
        <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline" aria-hidden="true"></lib-pxl-icon>
        Inviter un contact
      </button>
    </div>
  </header>
  <!-- FIN de Entête de la page -->

  <!-- Contenu de la page -->
  <div class="page-content">
    <!-- Message d'information read only -->
    <div class="card--info card--blue card--readonly" *ngIf="!isStructureUpdatableByUser">
      <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
      Vous êtes en lecture seule sur cette page. Vous ne pouvez pas éditer son contenu.
      {{ projet.inactif ? 'Pour effectuer des modifications sur le projet, vous devez d’abord le réactiver.' : null }}
    </div>
    <!-- Message d'information demande de correction -->
    <div class="card--info card--blue card--readonly" *ngIf="demandeDeCorrectionExist && !demandeDeCorrectionTreated">
      <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
      Vous avez fait une demande de correction de vos informations siège à Bpifrance. Dès que ces informations seront corrigées, vous serez
      notifié.
    </div>
    <div class="card--info card--blue card--readonly" *ngIf="demandeDeCorrectionExist && demandeDeCorrectionTreated">
      <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
      Les informations siège ont été mises à jour le
      {{ structure?.adresse?.demandeRectification?.dateTraitement | date : 'dd/MM/YYYY' }}
    </div>
    <!-- Alerte si la responsable projet n'est pas saisie -->
    <ng-container *ngIf="isStructureUpdatableByUser">
      <div
        class="card card--consortium-alert"
        *ngIf="(!aap.equipePresent || !aap.equipeObligatoire) && (!responsableProjetBoolean || !responsableAdministratifBoolean)"
      >
        <div id="responsable-incomplet" class="card__message">
          Vous devez déclarer un “Responsable projet” et un “Responsable administratif et financier” au niveau des contacts
        </div>
      </div>
      <div
        class="card card--consortium-alert"
        *ngIf="
          aap.equipePresent &&
          aap.equipeObligatoire &&
          (!responsableProjetBoolean || !responsableProjetEquipeBoolean || !responsableAdministratifBoolean)
        "
      >
        <div id="responsable-incomplet" class="card__message">
          Vous devez déclarer le “Responsable projet” dans les sections contacts projet et équipe ainsi qu’un “Responsable administratif et
          financier” dans la section contacts projet
        </div>
      </div>
      <lib-card-info
        *ngIf="demandesComplementsEncours?.length"
        color="yellow"
        message="Veuillez vous référer à la zone de documents complémentaires."
        content='Des documents complémentaires vous ont été demandés. Vous pouvez les ajouter dans la section "documents complémentaires" en bas de page.'
      ></lib-card-info>
    </ng-container>

    <!-- Informations de la structure -->
    <section class="page-section">
      <h3 class="page-section__title">Informations du siège/ de la structure</h3>

      <!-- Carte 'structure' -->
      <div
        id="structure"
        class="card card--structure"
        role="button"
        aria-label="Modifier la structure"
        (click)="modifyStructure()"
        [ngClass]="{ 'card--warning': !structureValidationFunction.checkInfosSiegeInfosStructure(structure) }"
        [class.card--inactive]="!isStructureUpdatableByUser"
        [lang]="!isStructureUpdatableByUser"
      >
        <div class="div_tuile_infos_siege">
          <h4 id="structure-title" class="card__title card_title_flex_overwrite">
            <p class="p_title_raison_social">{{ structure.raisonSocial }}</p>
            <p
              *ngIf="structure.typeStructure && structure.typeStructure !== null"
              id="structure-type-juridique"
              class="card_title_type_juridique"
            >
              <span *ngIf="structure.raisonSocial"> - </span>
              {{ getTypeStructure(structure.typeStructure) }}
            </p>
            <div
              class="card__tag"
              [class.card__tag--red]="structure.role === EnumRoleStructure.CHEF_DE_FILE"
              [class.card__tag--green]="isStructureMandataire || structure.role === EnumRoleStructure.CANDIDAT"
            >
              {{ EnumRoleStructure.toString(structure.role) }}
            </div>
          </h4>
          <div *ngIf="structure.siret" id="structure-siret" class="card__siret card_sire_overwrite">
            Siret du siège : {{ structure.siret }}
          </div>
          <div *ngIf="structure.raisonSiret" id="structure-raisonSiret" class="card__siret card_sire_overwrite">
            Absence de Siret : {{ absenceSiret(false) }}
          </div>
          <div *ngIf="structure?.effectifSiege" class="card__siret card_sire_overwrite">
            Effectif du siège : {{ structure.effectifSiege | number }}
          </div>
          <div *ngIf="structure?.effectifGroupe" class="card__siret card_sire_overwrite">
            Effectif du groupe : {{ structure.effectifGroupe | number }}
          </div>
          <div *ngIf="structure.adresse?.cp" id="structure-adresse" class="card__siret card_sire_overwrite">
            {{ getAdresseSiege('siege') | uppercase }}
          </div>
        </div>

        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
        ></lib-pxl-icon>
      </div>
      <!-- FIN de Carte 'Structure' -->
    </section>
    <!-- FIN de Informations de la structure -->

    <!-- Lieu de réalisation de la structure -->
    <section class="page-section" *ngIf="!isStructureMandataire">
      <h3 class="page-section__title">Lieu de réalisation</h3>

      <!-- Carte 'structure' -->
      <div
        id="card-info"
        class="card card--message"
        [ngClass]="{
          'card--warning': !structureValidationFunction.checkLieuDeRealisation(structure)
        }"
        [class.card--inactive]="!isStructureUpdatableByUser"
        (click)="modifyStructureRD()"
        *ngIf="!structure?.lieuRD?.raisonSocial"
      >
        <p class="card__title">Vous devez renseigner les informations sur le lieu de réalisation du projet</p>
        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
        ></lib-pxl-icon>
      </div>
      <div
        id="structure-rd"
        class="card card--structure"
        [ngClass]="{
          'card--warning': !structureValidationFunction.checkLieuDeRealisation(structure)
        }"
        *ngIf="structure?.lieuRD?.raisonSocial"
        role="button"
        aria-label="Modifier la structure"
        [class.card--inactive]="!isStructureUpdatableByUser"
        (click)="modifyStructureRD()"
        [lang]="!isStructureUpdatableByUser"
      >
        <div class="div_tuile_infos_siege">
          <h4 id="structure-rd-title" class="card__title card_title_flex_overwrite">
            <p class="p_title_raison_social">{{ structure.lieuRD.raisonSocial }}</p>
            <p
              *ngIf="structure.lieuRD.typeStructure && structure.lieuRD.typeStructure !== null"
              id="structure-type-juridique"
              class="card_title_type_juridique"
            >
              <span *ngIf="structure.lieuRD.raisonSocial"> - </span>
              {{ getTypeStructure(structure.lieuRD.typeStructure) }}
            </p>
          </h4>
          <div *ngIf="structure.lieuRD.siret" id="structure-rd-siret" class="card__siret card_sire_overwrite">
            Siret du siège : {{ structure.lieuRD.siret }}
          </div>
          <div *ngIf="structure.lieuRD.raisonSiret" id="structure-rd-raisonSiret" class="card__siret card_sire_overwrite">
            Absence de Siret : {{ absenceSiret(true) }}
          </div>
          <div id="structure-adresse" *ngIf="structure.lieuRD.adresse" class="card__siret card_sire_overwrite">
            {{ getAdresseSiege('lieuRD') | uppercase }}
          </div>
        </div>

        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
        ></lib-pxl-icon>
      </div>
      <!-- FIN de Carte 'Structure' -->
    </section>
    <!-- FIN de Lieu de réalisation de la structure -->

    <!-- Contacts Représentant Légal-->
    <section class="page-section" *ngIf="!isStructureMandataire">
      <h3 class="page-section__title">Représentant.e Légal.e</h3>
      <!-- Alerte si pas de représentant légal -->
      <div
        id="no-representant-legal"
        class="card card--alert"
        (click)="onGoToContact(representantLegaux, 'nouveau-representant-legal')"
        *ngIf="!structureValidationFunction.checkRepresentantLegal(structure)"
        [lang]="!isStructureUpdatableByUser"
        [class.card--inactive]="!isStructureUpdatableByUser"
      >
        <h4 *ngIf="structure?.raisonSiret !== enumRaisonSiret.EN_COURS_DE_CREATION" class="card__title">
          Vous devez déclarer un.e représentant.e légal.e pour cette structure.
        </h4>
        <h4 *ngIf="structure?.raisonSiret === enumRaisonSiret.EN_COURS_DE_CREATION" class="card__title">
          Vous devez déclarer le/ la futur.e représentant.e légal.e pour cette structure.
        </h4>

        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
        ></lib-pxl-icon>
      </div>
      <!-- FIn de Alerte si pas de représentant.e légal.e -->

      <ng-container id="representant-legaux" *ngFor="let contact of representantLegaux">
        <!-- Carte 'contact' -->
        <div
          id="representant-legal"
          class="card card--contact card--contact-overwrite"
          (click)="onGoToContact(contact)"
          [lang]="!isStructureUpdatableByUser"
          [ngClass]="{ 'card--warning': !sharedFunction.isContactValid(representantLegaux[0]) }"
          [class.card--inactive]="!isStructureUpdatableByUser"
          *ngIf="structureValidationFunction.checkRepresentantLegal(structure)"
        >
          <h4 id="representant-title" class="card__title card__title_overwrite">
            <p class="card__email_overwrite">{{ contact.nom }} {{ contact.prenom }}</p>
            <div class="card__tag card__tag--blue card__tag_overwrite">Représentant.e légal.e</div>
          </h4>
          <div id="representant-email" class="card__email card__email_overwrite">{{ contact.email }}</div>
          <div id="representant-telephone" class="card__telephone" *ngIf="contact.telephone">{{ parsePhoneNumber(contact.telephone) }}</div>

          <lib-pxl-icon
            class="card__arrow"
            icon="icon-arrow-arrow-forward-ios"
            aria-hidden="true"
            *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
          ></lib-pxl-icon>
        </div>
        <!-- FIN de Carte 'contact' -->
      </ng-container>
    </section>
    <!-- FIN de Représentant.e Légal.e-->

    <!-- Contacts projet chef de file-->
    <section class="page-section" *ngIf="!isStructureMandataire">
      <h3 class="page-section__title">
        Contact(s) projet<span
          >(1 minimum, 6 maximum dont un responsable projet et un responsable administratif et financier obligatoires)</span
        >
      </h3>
      <!-- FIN de Responsable projet -->
      <lib-card-info message="Lorsqu’un contact est invité, ce dernier peut accéder au projet."></lib-card-info>
      <!-- Autres contacts -->
      <ng-container *ngIf="structureValidationFunction.hasAutresContacts(structure)">
        <ng-container id="autre-contacts" *ngFor="let contact of autresContacts">
          <!-- Carte 'contact' -->
          <div
            id="autre-contact"
            class="card card--contact card--contact-overwrite"
            [lang]="!isStructureUpdatableByUser"
            [ngClass]="{
              'card--warning': !structureValidationFunction.isStructureContactCorrect(contact, structure?.raisonSiret)
            }"
            [class.card--inactive]="!isStructureUpdatableByUser"
            (click)="onGoToContact(contact)"
          >
            <h4 id="autre-contact-titre" class="card__title card__title_overwrite">
              <p class="card__email_overwrite">{{ contact.nom }} {{ contact.prenom }}</p>
              <div class="div_tags">
                <ng-container *ngFor="let role of contact.roles">
                  <div class="card__tag card__tag--blue card__tag_overwrite" *ngIf="displayedRoleContact(role)">
                    {{ displayedRoleContact(role) }}
                  </div>
                </ng-container>
                <ng-container *ngIf="getInvitation(contact)?.active">
                  <div class="card__tag card__tag--red card__tag--yellow card__tag_overwrite">
                    {{ statutInvitation(contact) }}
                  </div>
                </ng-container>
              </div>
            </h4>
            <div id="autre-contact-email" class="card__email card__email_overwrite">{{ contact.email }}</div>
            <div id="autre-contact-telephone" class="card__telephone" *ngIf="contact.telephone">
              {{ parsePhoneNumber(contact.telephone) }}
            </div>

            <lib-pxl-icon
              class="card__arrow"
              icon="icon-arrow-arrow-forward-ios"
              aria-hidden="true"
              *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
            ></lib-pxl-icon>
          </div>
          <!-- FIN de Carte 'contact' -->
        </ng-container>
      </ng-container>

      <!-- FIN de Autres contacts -->
      <!--Ajouter Contact Supplementaire-->
      <ng-container
        *ngIf="structureValidationFunction.hasAutresContacts(structure) && structureValidationFunction.canAddAutresContacts(structure)"
      >
        <div
          id="contact-supplementaire"
          class="card card--alert"
          style="border-color: white"
          (click)="inviteContact()"
          *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
        >
          <h4 class="card__title" style="border-color: white">Vous pouvez inviter un contact supplémentaire.</h4>

          <lib-pxl-icon
            class="card__arrow"
            icon="icon-arrow-arrow-forward-ios"
            aria-hidden="true"
            *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
          ></lib-pxl-icon>
        </div>
      </ng-container>
      <!--Fin Ajouter contact Supplémentaire-->
      <ng-container *ngIf="!structureValidationFunction.hasAutresContacts(structure)">
        <div
          id="contact-add"
          class="card card--alert"
          (click)="inviteContact()"
          *ngIf="isStructureUpdatableByUser"
          [class.card--inactive]="!isStructureUpdatableByUser"
        >
          <h4 class="card__title">Vous devez inviter un contact, à minima le responsable projet</h4>

          <lib-pxl-icon
            class="card__arrow"
            icon="icon-arrow-arrow-forward-ios"
            aria-hidden="true"
            *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
          ></lib-pxl-icon>
        </div>
      </ng-container>
    </section>
    <!-- FIN de Contacts projet chef de file-->

    <!-- Contacts projet mandataire-->
    <section class="page-section document-gap" *ngIf="isStructureMandataire">
      <h3 class="page-section__title">Contact(s) projet <span>(Obligatoire, 1 minimum - 6 maximum)</span></h3>
      <lib-card-info message="Lorsqu’un contact est invité, ce dernier peut accéder au projet."></lib-card-info>
      <!-- Alerte si pas de contact -->
      <div
        id="no-contact"
        class="card card--alert"
        (click)="inviteContact()"
        *ngIf="!structureValidationFunction.hasAutresContacts(structure)"
        [lang]="!isStructureUpdatableByUser"
        [ngClass]="{
          'card--warning': !structureValidationFunction.isStructureContactCorrect(contact, structure?.raisonSiret)
        }"
        [class.card--inactive]="!isStructureUpdatableByUser"
      >
        <h4 class="card__title">Vous devez déclarer au moins un contact.</h4>

        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
        ></lib-pxl-icon>
      </div>
      <!-- FIn de Alerte si pas de contact -->

      <!-- Carte 'contact' -->
      <div
        id="contact"
        class="card card--contact card--contact-overwrite"
        [lang]="!isStructureUpdatableByUser"
        [ngClass]="{
          'card--warning': !structureValidationFunction.isStructureContactCorrect(contact, structure?.raisonSiret)
        }"
        [class.card--inactive]="!isStructureUpdatableByUser"
        (click)="onGoToContact(contact)"
        *ngFor="let contact of contacts; index as i"
      >
        <h4 id="autre-contact-mandataire" class="card__title card_title_overwrite">
          {{ contact.nom }} {{ contact.prenom }}
          <div class="div_tag_invitation">
            <ng-container *ngIf="displayedRoleContact(contact.roles)">
              <div class="card__tag card__tag--green">
                {{ displayedRoleContact(contact.roles) }}
              </div>
            </ng-container>
            <ng-container *ngIf="getInvitation(contact)?.active">
              <div class="card__tag card__tag--red card__tag--invitation">
                {{ statutInvitation(contact) }}
              </div>
            </ng-container>
          </div>
        </h4>

        <div id="contact-email" class="card__email">{{ contact.email }}</div>
        <div id="contact-telephone" class="card__telephone" *ngIf="contact.telephone">{{ parsePhoneNumber(contact.telephone) }}</div>

        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
        ></lib-pxl-icon>
      </div>
      <!-- FIN de Carte 'contact' -->
      <!--Ajouter Contact Supplementaire-->
      <ng-container
        *ngIf="structureValidationFunction.hasAutresContacts(structure) && structureValidationFunction.canAddAutresContacts(structure)"
      >
        <div
          id="contact-supplementaire-mandataire"
          class="card card--alert"
          style="border-color: white"
          (click)="inviteContact()"
          *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
        >
          <h4 class="card__title" style="border-color: white">Vous pouvez inviter un contact supplémentaire.</h4>

          <lib-pxl-icon
            class="card__arrow"
            icon="icon-arrow-arrow-forward-ios"
            aria-hidden="true"
            *ngIf="isStructureUpdatableByUser"
          ></lib-pxl-icon>
        </div>
      </ng-container>
      <!--Fin Ajouter contact Supplémentaire-->
    </section>
    <!-- FIN de Contacts projet mandataire -->

    <!-- Contacts supprimées-->
    <section class="page-section" *ngIf="autresContactsDeleted.length > 0">
      <p class="page-subsection__title">Contact(s) supprimé(s)</p>
      <div
        id="deleted-contact"
        class="card card--contact card--contact-overwrite"
        [lang]="!isStructureUpdatableByUser"
        [class.card--inactive]="!isStructureUpdatableByUser"
        (click)="onGoToContact(contact)"
        *ngFor="let contact of autresContactsDeleted; index as i"
      >
        <h4 id="deleted-contact-titre" class="card__title card_title_overwrite">
          {{ contact.nom }} {{ contact.prenom }}
          <div class="div_tags" *ngIf="getInvitation(contact)?.active">
            <div class="card__tag card__tag--red card__tag--yellow card__tag_overwrite">
              {{ statutInvitation(contact) }}
            </div>
          </div>
        </h4>

        <div id="deleted-contact-email" class="card__email">{{ contact.email }}</div>
        <div id="deleted-contact-telephone" class="card__telephone" *ngIf="contact.telephone">
          {{ parsePhoneNumber(contact.telephone) }}
        </div>

        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
        ></lib-pxl-icon>
      </div>
    </section>
    <!-- FIN de Contacts supprimées -->

    <!-- Section Equipe -->
    <ng-container *ngIf="!isStructureMandataire && aap?.equipePresent">
      <app-projet-consortium-info-equipe
        [aap]="aap"
        [isProjectUpdatable]="isStructureUpdatableByUser"
        [consortiumAccessGranted]="isStructureUpdatableByUser"
        [membresEquipe]="membresEquipe"
        [structure]="structure"
        [responsableIsValid]="sharedFunction.checkEquipeAndContactHasValidResponsable(structure)"
      ></app-projet-consortium-info-equipe>
    </ng-container>

    <!--Section Budget-->
    <section
      class="page-section"
      *ngIf="
        !isStructureMandataire &&
        (etapeProjet !== EnumProjetEtape.PRE_DEPOT || (etapeProjet === EnumProjetEtape.PRE_DEPOT && !aap.budgetEstime))
      "
    >
      <h3 class="page-section__title">Budget</h3>
      <!-- Si certaines informations sont manquantes -->
      <div
        id="card"
        class="card card--alert"
        (click)="onGoToProjetBudget()"
        *ngIf="!structureValidationFunction.isBudgetSet(aap, projet, structure)"
        [class.card--inactive]="!isStructureUpdatableByUser"
      >
        <p class="card__title">Vous devez renseigner votre budget</p>
        <lib-pxl-icon class="card__arrow" icon="icon-arrow-arrow-forward-ios" aria-hidden="true" *ngIf="isStructureUpdatableByUser">
        </lib-pxl-icon>
      </div>

      <!-- Si toutes les informations sont remplies -->
      <div
        id="projet-div"
        class="card card--generic-content card_budget"
        *ngIf="structureValidationFunction.isBudgetSet(aap, projet, structure)"
        (click)="onGoToProjetBudget()"
        [class.card--inactive]="!isStructureUpdatableByUser"
      >
        <div class="card__content-wrapper project-info">
          <p class="card__title budget__value" *ngIf="getBudget() !== 'null'">{{ (getBudget() | number) + ' €' }}</p>
          <div class="card__tag card__tag_budget" *ngIf="!checkFinancement()">Ne demande pas de financement</div>
        </div>

        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
        >
        </lib-pxl-icon>
      </div>
    </section>
    <!--Fin Section Budget-->

    <!--Section Données financière -->
    <section class="page-section" *ngIf="viewElementFinancier">
      <h3 class="page-section__title" [ngClass]="{ disabled: disableDoc() }">Eléments financiers</h3>
      <lib-card-info
        message="Les éléments financiers ne peuvent être renseignés que par les contacts de la structure elle même."
      ></lib-card-info>
      <div
        class="card card--message"
        (click)="isUserInStructureContacts && onGoToDonneesFinancieres()"
        [class.card--inactive]="!isStructureUpdatableByUser"
        [class.card-disabled]="!isUserInStructureContacts"
        [class.card--warning]="isUserInStructureContacts"
        *ngIf="!disableDoc() && structure?.donneesFinancieres?.statut !== Status.VALIDE"
      >
        <p class="card__title">
          {{
            isUserInStructureContacts ? 'Vous devez renseigner vos éléments financiers' : 'Les éléments financiers ne sont pas renseignées'
          }}
        </p>
        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isUserInStructureContacts && isStructureUpdatableByUser"
        >
        </lib-pxl-icon>
        <lib-pxl-icon
          class="icon visibility_icon"
          icon="icon-visibility"
          aria-hidden="true"
          *ngIf="isUserInStructureContacts && !isStructureUpdatableByUser"
        ></lib-pxl-icon>
      </div>

      <div
        class="card card--message cursor-pointer"
        (click)="isUserInStructureContacts && onGoToDonneesFinancieres()"
        [class.card-disabled]="!isUserInStructureContacts"
        *ngIf="!disableDoc() && structure?.donneesFinancieres?.statut === Status.VALIDE"
      >
        <div>
          <p class="card__title" id="donnees-financieres"></p>
          <div id="elements-financiers" class="d-flex align-items-center">
            <lib-pxl-icon class="green-checkmark" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
            <p class="card__title">Eléments financiers renseignés</p>
          </div>
        </div>
        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isUserInStructureContacts && isStructureUpdatableByUser"
        >
        </lib-pxl-icon>
        <lib-pxl-icon
          class="icon visibility_icon"
          icon="icon-visibility"
          aria-hidden="true"
          *ngIf="isUserInStructureContacts && !isStructureUpdatableByUser"
        ></lib-pxl-icon>
      </div>

      <lib-card-info
        *ngIf="disableDoc()"
        color="green"
        message="Votre structure n’a pas de d'éléments financiers à remplir."
      ></lib-card-info>
    </section>
    <!--Fin section Données financière -->

    <!--Section Prévision économique-->
    <section class="page-section" *ngIf="viewPrevisionsEconomiques">
      <h3 class="page-section__title" [ngClass]="{ disabled: disableDoc() }">Prévisions économiques</h3>
      <lib-card-info
        message="Les prévisions économiques ne peuvent être renseignés que par les contacts de la structure elle même."
      ></lib-card-info>
      <div
        id="card-info"
        class="card card--message cursor-pointer"
        (click)="isUserInStructureContacts && openModalPrevisionsEconomiques()"
        [ngClass]="{
          'card--warning':
            (!structure?.previsionsEconomiques || structure?.previsionsEconomiques?.statut !== 'VALIDE') && isUserInStructureContacts
        }"
        [class.card--inactive]="!isStructureUpdatableByUser"
        [class.card-disabled]="!isUserInStructureContacts"
        *ngIf="!disableDoc() && (!structure?.previsionsEconomiques || structure?.previsionsEconomiques?.statut !== 'VALIDE')"
      >
        <p class="card__title">
          {{
            isUserInStructureContacts
              ? 'Vous devez renseigner vos prévisions économiques'
              : 'Les prévisions économiques ne sont pas renseignées'
          }}
        </p>
        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isUserInStructureContacts && isStructureUpdatableByUser && complementNeededReadOnly"
        >
        </lib-pxl-icon>
        <lib-pxl-icon
          class="icon visibility_icon"
          icon="icon-visibility"
          aria-hidden="true"
          *ngIf="isUserInStructureContacts && !isStructureUpdatableByUser"
        ></lib-pxl-icon>
      </div>

      <div
        id="card-info"
        class="card card--message cursor-pointer"
        (click)="isUserInStructureContacts && onGoToPrevisionsEconomiques()"
        [class.card-disabled]="!isUserInStructureContacts"
        *ngIf="!disableDoc() && structure?.previsionsEconomiques?.statut === 'VALIDE'"
      >
        <div>
          <p class="card__title" id="previsions-economiques"></p>
          <div id="previsions-economiques" class="d-flex align-items-center">
            <lib-pxl-icon class="green-checkmark" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
            <p class="card__title">Prévisions économiques renseignées</p>
          </div>
        </div>
        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isUserInStructureContacts && isStructureUpdatableByUser && complementNeededReadOnly"
        >
        </lib-pxl-icon>
        <lib-pxl-icon
          class="icon visibility_icon"
          icon="icon-visibility"
          aria-hidden="true"
          *ngIf="isUserInStructureContacts && !isStructureUpdatableByUser"
        ></lib-pxl-icon>
      </div>

      <lib-card-info
        *ngIf="disableDoc()"
        color="green"
        message="Votre structure n’a pas de prévisions économiques à remplir."
      ></lib-card-info>
    </section>

    <!--Fin Section Prévision économique-->

    <!-- Section RIB - IBAN -->
    <section *ngIf="structureNeedsToFillRibIban" class="page-section" [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.RIB_IBAN">
      <h3 class="page-section__title">RIB - IBAN</h3>
      <lib-rib-iban-card
        (click)="onGoToRibIban()"
        [selectedInformationBancaire]="selectedInformationBancaire"
        [canUserWrite]="isStructureUpdatableByUser && complementNeededReadOnly"
        [isMandatory]="!aap?.immersion"
      >
      </lib-rib-iban-card>
    </section>
    <!-- Fin section RIB - IBAN -->

    <!--Section Fiche de demande d'aide-->
    <section class="page-section" *ngIf="isFicheDemandeAidePresent && !isStructureMandataire">
      <h3 class="page-section__title" [ngClass]="{ disabled: !ficheDemandeAideNeeded }">Fiche de demande d'aide</h3>
      <lib-card-info
        message="Générer et ajouter votre fiche de demande d'aide signée"
        *ngIf="
          ficheDmdAideDocuments.length === 0 &&
          isFicheDemandeAccessible &&
          ficheDemandeAideNeeded &&
          isFicheDemandeAuthorized &&
          isSectionsRenseignees
        "
      ></lib-card-info>
      <lib-card-info
        *ngIf="(!isFicheDemandeAccessible || !isFicheDemandeAuthorized) && isStructureUpdatableByUser && ficheDmdAideDocuments.length !== 0"
        message="Vous n’êtes pas habilité.e à ajouter, visualiser ou supprimer la fiche de demande d’aide, vous pouvez vous synchroniser avec les autres contacts de votre structure ou de la structure mandataire si présente pour pouvoir être habilité.e"
      ></lib-card-info>

      <lib-card-info *ngIf="!ficheDemandeAideNeeded" color="green" message="Votre structure n'a pas de fiche de demande d'aide à remplir.">
      </lib-card-info>

      <lib-card-info
        *ngIf="!isSectionsRenseignees && isStructureUpdatableByUser && isFicheDemandeAccessible"
        [disabled]="!ficheDemandeAideNeeded"
        color="yellow"
        icon="icon-info-warning"
        message="Avant de pouvoir renseigner les éléments de cette section, il est nécessaire de compléter au niveau de votre structure
        les sections “Budget”, “Représentant.e légal.e”, “Informations du siège / de la structure” et “Lieu de réalisation”."
      ></lib-card-info>

      <lib-card-info
        *ngIf="!isInfosGeneralesRenseignees"
        [disabled]="!ficheDemandeAideNeeded"
        color="yellow"
        icon="icon-info-warning"
        message="Avant de pouvoir renseigner les éléments de cette section, le chef de file et/ou mandataire devra compléter la section
        “Informations générales” dans la partie “Projet”. Merci de vous rapprocher de la structure en charge."
      ></lib-card-info>

      <lib-card-info
        *ngIf="!isFicheDemandeAccessible || !disableDoc()"
        message="La fiche de demande d'aide ne peut être renseignée que par les contacts de la structure elle même ou du Mandataire."
        [disabled]="!ficheDemandeAideNeeded"
      ></lib-card-info>

      <lib-card-info
        *ngIf="ficheDmdAideDocuments.length === 0 && (!isFicheDemandeAccessible || !isFicheDemandeAuthorized) && !disableDoc()"
        message="Aucune fiche de demande d'aide chargée"
      ></lib-card-info>
      <div
        *ngIf="
          ficheDemandeAideNeeded &&
          isFicheDemandeAccessible &&
          ficheDemandeAideStatut === EnumStatutDemandeAide.GENERATED &&
          isFicheDemandeAuthorized
        "
        [class.dashed]="
          isSectionsRenseignees &&
          isInfosGeneralesRenseignees &&
          isFicheDemandeAccessible &&
          !ficheDmdAideDocuments.length &&
          ficheDemandeAideNeeded
        "
      >
        <div
          class="card card-fiche-demande"
          [ngClass]="{
            disabled: !isSectionsRenseignees || !isInfosGeneralesRenseignees || !ficheDemandeAideNeeded
          }"
        >
          <div
            class="d-flex"
            libDnd
            (fileDropped)="onUploadFicheDemandeAide($event)"
            *ngIf="isFicheDemandeAccessible && isFicheDemandeAuthorized"
          >
            <input
              type="file"
              class="dnd"
              #fileDropRef
              (change)="onUploadFicheDemandeAide($event)"
              [attr.disabled]="disabledFicheDemandeAide() === true ? true : null"
            />
            <lib-pxl-icon class="dnd" icon="icon-file-folder-insert-drive-file-new" aria-hidden="true"></lib-pxl-icon>
            <div>
              <div class="fw-bold ms-2">
                <span class="text-decoration-underline me-1">Ajouter un document </span> ou glisser/déposer ici.
              </div>
              <div class="ms-2">Formats acceptés : {{ FICHE_DMD_AIDE_FILE_EXTENSION }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-2" *ngFor="let document of ficheDmdAideDocuments">
        <lib-document-card
          [document]="document"
          [hasCheckbox]="false"
          [canUserWrite]="isFicheDemandeAccessible && isFicheDemandeAuthorized"
          (deleteDocEmitter)="onDeleteFicheDmdAideDocument($event)"
          [showDownload]="isFicheDemandeAccessible && isFicheDemandeAuthorized"
          [deleteDisabled]="document.etape !== sharedFunction.getProjectEtapeName(projet)"
          [showNomAsType]="true"
        >
        </lib-document-card>
      </div>

      <ng-container *ngIf="error">
        <div id="error-size" class="file-error-message" *ngIf="!isFileSizeValid(file)">
          {{ nameFileNotSupported?.length === 1 ? 'Le fichier ' : 'Les fichiers ' }} {{ nameFileNotSupported?.join(', ') }}
          {{ nameFileNotSupported?.length === 1 ? ' est trop lourd ' : 'sont trop lourds' }}, merci de sélectionner un autre fichier.
        </div>
        <div
          id="error-type"
          class="file-error-message"
          *ngIf="!fileDemandeAide.name.toLowerCase().endsWith(this.FICHE_DMD_AIDE_FILE_EXTENSION)"
        >
          Le type {{ nameFileNotSupported?.length === 1 ? 'du document ' : 'des documents' }} {{ nameFileNotSupported?.join(', ') }} n'est
          pas supporté, formats supportés (.pdf), merci de sélectionner un autre fichier.
        </div>
      </ng-container>

      <div class="d-flex justify-content-end">
        <button
          *ngIf="isFicheDemandeAccessible && ficheDemandeAideNeeded && isFicheDemandeAuthorized"
          class="ds-btn float-end mt-3"
          [ngClass]="{
            'ds-btn--primary': !ficheDemandeAideStatut,
            'ds-btn--secondary': ficheDemandeAideStatut || ficheDmdAideDocuments.length > 0,
          }"
          [disabled]="!isSectionsRenseignees || !isInfosGeneralesRenseignees || !ficheDemandeAideNeeded"
          (click)="onGoToFicheDemandeAide()"
        >
          Accéder au formulaire
        </button>
      </div>
    </section>
    <!--Fin Section Fiche de demande d'aide-->

    <!-- Signataire(s) du contrat -->
    <section
      class="page-section"
      *ngIf="!isStructureMandataire && checkFinancement()"
      [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.PARCOURS_SIGNATAIRES"
    >
      <h3 class="page-section__title">Signataire(s) du contrat</h3>
      <!-- Alerte si Signataire(s) -->
      <div
        id="no-signataire"
        class="card card--alert"
        *ngIf="signataires.length === 0"
        (click)="onGoToSignataire()"
        [class.card--inactive]="!isStructureUpdatableByUser"
      >
        <h4 class="card__title">Vous devez renseigner le(s) signataire(s) du contrat</h4>
        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
        ></lib-pxl-icon>
      </div>
      <!-- Fin Alerte si Signataire(s)-->
      <lib-signataire-list
        [signataires]="signatairesValide"
        [isStructureUpdatableByUser]="isStructureUpdatableByUser && complementNeededReadOnly"
      ></lib-signataire-list>
      <!-- FIn de Alerte si pas Signataire(s)0 -->
      <ng-container *ngIf="isStructureUpdatableByUser && signataires.length > 0 && signataires.length < 6">
        <div id="ajout-signataire" class="card card--alert mt-2" style="border-color: white" (click)="onGoToSignataire()">
          <h4 class="card__title" style="border-color: white">Vous pouvez ajouter un signataire supplémentaire.</h4>

          <lib-pxl-icon
            class="card__arrow"
            icon="icon-arrow-arrow-forward-ios"
            aria-hidden="true"
            *ngIf="isStructureUpdatableByUser && complementNeededReadOnly"
          ></lib-pxl-icon>
        </div>
      </ng-container>

      <!-- Signataire(s) invalides -->
      <div class="page-section" *ngIf="signatairesInvalide.length > 0">
        <p class="page-subsection__title">Contact(s) signataire(s) invalidé(s)</p>
        <lib-signataire-list
          [signataires]="signatairesInvalide"
          [isStructureUpdatableByUser]="isStructureUpdatableByUser && complementNeededReadOnly"
        ></lib-signataire-list>
      </div>
      <!-- Fin Signataire(s) invalides -->
    </section>

    <!-- Section grille d'impacts -->
    <section class="page-section" *ngIf="isKpiStructureNeeded && !isStructureMandataire">
      <lib-card-info *ngIf="!checkFinancement()" color="green" message="Votre structure n'a pas de grille d'impacts à remplir.">
      </lib-card-info>
      <h3 class="page-section__title" [ngClass]="{ disabled: !checkFinancement() }">Grille d'impacts de la structure</h3>
      <lib-card-info
        *ngIf="!isGrilleImpactsAccessible"
        message="La grille d'impacts au niveau structure est accessible en lecture / écriture uniquement des contacts déclarés sur la structure"
        [disabled]="!checkFinancement()"
      ></lib-card-info>
      <div
        id="card-info"
        class="card card--message"
        (click)="onGoToGrilleImpacts(structure.role)"
        [ngClass]="{ 'card--warning': checkFinancement(), disabled: !checkFinancement() }"
        [class.card--inactive]="!isGrilleImpactsAccessible"
        *ngIf="!isStatutCompleted"
      >
        <p class="card__title">Vous devez renseigner les éléments de la grille d'impacts de la structure dans le cadre du projet</p>
        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isStructureUpdatableByUser && isGrilleImpactsAccessible"
        ></lib-pxl-icon>
        <lib-pxl-icon
          class="icon visibility_icon"
          icon="icon-visibility"
          aria-hidden="true"
          *ngIf="!isStructureUpdatableByUser && isGrilleImpactsAccessible"
        ></lib-pxl-icon>
      </div>

      <div
        id="card-info"
        class="card card--message"
        (click)="onGoToGrilleImpacts(structure.role)"
        [class.card--inactive]="!isGrilleImpactsAccessible"
        *ngIf="isStatutCompleted"
      >
        <div>
          <p class="card__title" id="kpi-complete-container">
            <lib-pxl-icon class="icon green-checkmark" icon="icon-action-check-circle" aria-hidden="true"></lib-pxl-icon>
            Grille d'impacts de la structure dans le cadre du projet renseignée.
          </p>
        </div>
        <lib-pxl-icon
          class="card__arrow"
          icon="icon-arrow-arrow-forward-ios"
          aria-hidden="true"
          *ngIf="isStructureUpdatableByUser && isGrilleImpactsAccessible"
        ></lib-pxl-icon>
        <lib-pxl-icon
          class="icon visibility_icon"
          icon="icon-visibility"
          aria-hidden="true"
          *ngIf="!isStructureUpdatableByUser && isGrilleImpactsAccessible"
        ></lib-pxl-icon>
      </div>
    </section>
    <!-- Fin de section grille d'impacts  -->

    <!-- Carte Document -->
    <section class="page-section">
      <lib-card-info
        *ngIf="
          !isTypeStructureAndBudgetSaisi &&
          etapeProjet !== EnumProjetEtape.INSTRUCTION &&
          !isStructureMandataire &&
          isStructureUpdatableByUser
        "
        color="red"
        message="Merci de renseigner votre type de structure et votre budget pour déterminer la liste des documents à fournir."
      ></lib-card-info>
    </section>
    <section class="page-section">
      <lib-card-info
        *ngIf="
          !structureValidationFunction.structureTypeComplete(structure) &&
          etapeProjet !== EnumProjetEtape.INSTRUCTION &&
          isStructureMandataire &&
          isStructureUpdatableByUser
        "
        color="red"
        message="Merci de renseigner votre type de structure pour déterminer la liste des documents à fournir."
      ></lib-card-info>
    </section>
    <!-- FIN de Carte Document -->

    <!-- Document de la structure -->
    <section
      class="page-section"
      [ngClass]="{
        disabled:
          disableDoc() ||
          (structureValidationFunction.structureComplete(projet, aap, structure) &&
            !structureValidationFunction.needsDocuments(projet, aap, structure))
      }"
    >
      <h3
        [ngClass]="{
          disabled:
            disableDoc() ||
            (structureValidationFunction.structureComplete(projet, aap, structure) &&
              !structureValidationFunction.needsDocuments(projet, aap, structure))
        }"
        class="page-section__title"
      >
        Document(s) de la structure
      </h3>

      <p class="info-size p-document-size" *ngIf="!disableDoc() && structureValidationFunction.needsDocuments(projet, aap, structure)">
        Poids maximum par fichier {{ FILE_SIZE_LIMIT }}Mo
      </p>

      <p *ngIf="!isStructureMandataire && showDossierCandidatureLink()">
        Merci d’ajouter ici tous vos documents en rapport avec la structure. Le dossier de candidature est à télécharger
        <a [href]="dossier" target="_blank" [ngClass]="{ disabled: disableDoc() }" class="lien_dossier_candidature">ici</a>
      </p>

      <lib-card-info
        *ngIf="!disableDoc() && structureValidationFunction.needsDocuments(projet, aap, structure)"
        message="Les documents de la structure peuvent être chargés par les contacts de la structure elle même, du Chef de file ou Mandataire. Le
        contenu des documents n'est visible que par la personne qui les a chargés. Le nom des documents est visible par l'ensemble des
        contacts invités sur le projet"
        [disabled]="disableDoc() || listDocAapStructure.length === 0"
      ></lib-card-info>

      <lib-card-info
        *ngIf="
          disableDoc() ||
          (structureValidationFunction.structureComplete(projet, aap, structure) &&
            !structureValidationFunction.needsDocuments(projet, aap, structure))
        "
        color="green"
        message="Votre structure n’a aucun document à fournir."
      ></lib-card-info>

      <!-- Carte liste 'Document' -->
      <lib-document-list
        [scope]="EnumScope.STRUCTURE"
        [documents]="listDocumentStructure"
        [canUserWrite]="isStructureUpdatableByUser && complementNeededReadOnly"
        [projectEtapeName]="sharedFunction.getProjectEtapeName(projet)"
        [utilisateur]="utilisateur"
        [showTag]="isStructureUpdatableByUser"
        [documentConfig]="listDocAapStructure"
        (uploadDocument)="onUploadDocumentDnd($event, EnumScope.STRUCTURE)"
        [fileTooBig]="fileTooBig"
        [fileNotSupported]="fileNotSupported"
        [connaissanceClientFileNotSupported]="connaissanceClientFileNotSupported"
        [nameFileNotSupported]="nameFileNotSupported"
        [isCandidat]="true"
        (deleteDocument)="updateDocumentsAfterDelete($event)"
        [hidden]="
          disableDoc() ||
          (structureValidationFunction.structureComplete(projet, aap, structure) &&
            !structureValidationFunction.needsDocuments(projet, aap, structure))
        "
      ></lib-document-list>
    </section>
    <!-- FIN de Document de la structure -->

    <!--  Document structure actionnariale de la structure -->
    <section
      class="page-section"
      *ngIf="!isStructureMandataire"
      [ngClass]="{ disabled: disableDoc() || listDocAapStructureActio.length === 0 }"
    >
      <h3 class="page-section__title" [ngClass]="{ disabled: disableDoc() || listDocAapStructureActio.length === 0 }">
        Structure actionnariale
      </h3>

      <p class="info-size p-document-size" *ngIf="!disableDoc() && listDocAapStructureActio.length > 0">
        Poids maximum par fichier {{ FILE_SIZE_LIMIT }}Mo
      </p>

      <p *ngIf="showDossierCandidatureLink()">
        Merci d’ajouter ici tous vos documents en rapport avec la structure actionnariale. Le dossier de candidature est à télécharger
        <a
          [href]="dossier"
          target="_blank"
          [ngClass]="{ disabled: disableDoc() || listDocAapStructureActio.length === 0 }"
          class="lien_dossier_candidature"
          >ici</a
        >
      </p>

      <lib-card-info
        *ngIf="!disableDoc() && listDocAapStructureActio.length > 0"
        message="Les documents de la structure actionnariale peuvent être chargés par les contacts de la structure elle même, du Chef de file ou
        Mandataire. Le contenu des documents ainsi que leur nom ne sont visibles que par la personne qui les a chargés"
        [disabled]="disableDoc() || listDocAapStructureActio.length === 0"
      ></lib-card-info>

      <lib-card-info
        *ngIf="listDocAapStructureActio.length < 1 || disableDoc()"
        color="green"
        message="Votre structure n’a aucun document de structure actionnariale à fournir."
      ></lib-card-info>

      <!-- Carte liste 'Document' -->
      <lib-document-list
        [scope]="EnumScope.STRUCTURE_ACTIONARIAL"
        [documents]="listDocumentStructure"
        [canUserWrite]="isStructureUpdatableByUser && complementNeededReadOnly"
        [projectEtapeName]="sharedFunction.getProjectEtapeName(projet)"
        [utilisateur]="utilisateur"
        [showTag]="isStructureUpdatableByUser"
        [documentConfig]="listDocAapStructureActio"
        (uploadDocument)="onUploadDocumentDnd($event, EnumScope.STRUCTURE_ACTIONARIAL)"
        [fileTooBig]="fileTooBig"
        [fileNotSupported]="fileActioNotSupported"
        [connaissanceClientFileNotSupported]="connaissanceClientFileActioNotSupported"
        [nameFileNotSupported]="nameFileActioNotSupported"
        [isCandidat]="true"
        (deleteDocument)="updateDocumentsAfterDelete($event)"
        [hidden]="disableDoc() || listDocAapStructureActio.length < 1"
      ></lib-document-list>
    </section>
    <!-- FIN de Document structure actionnariale de la structure -->

    <!-- Documents complementaires -->
    <section class="page-section" *ngIf="demandesComplements?.length">
      <h3 class="page-section__title">Document(s) complémentaire(s)</h3>
      <p class="info-size p-document-size">
        Poids maximum par fichier {{ FILE_SIZE_LIMIT }}Mo<br />Les documents disponibles dans cette rubrique ne sont visibles que de
        Bpifrance.
      </p>

      <lib-card-info
        *ngIf="demandesComplements?.length > 0"
        message="Les documents de la rubrique 'document(s) complémentaire(s)' partie structure peuvent être chargés par les contacts de la structure elle même.
        Le contenu des documents ainsi que leur nom ne sont visibles que par la personne qui les a chargés."
      ></lib-card-info>

      <lib-document-list
        [scope]="EnumScope.STRUCTURE"
        [documents]="documentsComplementaires"
        [documentConfig]="documentConfigsForComplement"
        [canUserWrite]="isStructureUpdatableByUser"
        [projectEtapeName]="sharedFunction.getProjectEtapeName(projet)"
        [utilisateur]="utilisateur"
        [showTag]="isStructureUpdatableByUser"
        (uploadDocument)="onUploadDocumentDnd($event, EnumScope.STRUCTURE)"
        [fileTooBig]="fileTooBig"
        [fileNotSupported]="fileNotSupported"
        [connaissanceClientFileNotSupported]="connaissanceClientFileNotSupported"
        [videoTooBig]="videoTooBig"
        [nameFileNotSupported]="nameFileNotSupported"
        [isCandidat]="true"
        (deleteDocument)="updateDocumentsAfterDelete($event)"
      ></lib-document-list>
    </section>
    <!-- Fin Documents complementaires -->

    <div id="rgpd" class="rgpd">
      Pour en savoir plus sur la gestion de vos données personnelles, <a (click)="downloadRGPD()">cliquez ici</a>
      <br />
      Tout UTILISATEUR effectuant une (des) Demande(s) dans l’Extranet s’engage à informer les personnes dont il pourrait mentionner les
      données à caractère personnel au titre de la (des) Demande(s) des traitements de données à caractère personnel conformément au présent
      article.
    </div>

    <!-- Actions de bas de page sur mobile -->
    <button
      id="delete-structure-actio"
      class="footer-action delete-structure-btn"
      (click)="deleteStructure()"
      *ngIf="isStructureUpdatableByUser && checkIfUserNotInStructure()"
    >
      <lib-pxl-icon class="icon" icon="icon-action-delete-outline" aria-hidden="true"></lib-pxl-icon>
      Supprimer la structure
    </button>

    <button
      id="add-contact-actio"
      class="footer-action footer-action--cta ds-btn ds-btn--primary"
      (click)="inviteContact()"
      *ngIf="isStructureUpdatableByUser"
      [disabled]="!isStructureUpdatableByUser || autresContacts?.length >= 6"
    >
      <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline" aria-hidden="true"></lib-pxl-icon>
      Inviter un contact
    </button>
  </div>
  <!-- FIN de Contenu de la page -->
</ng-container>
