<h2 id="modal-title" class="modal__title">{{ getTitre() }}</h2>

<form id="input-typeDoc" class="modal__content" [formGroup]="autoAffectationForm">
  <div
    [hidden]="!canUserSetPerimeter"
    id="input-perimetre"
    class="form-step__activite ds-input-group"
    [class.ds-input-group--error]="
      !autoAffectationForm.controls['perimetresDE'].valid && autoAffectationForm.controls['perimetresDE'].touched
    "
  >
    <div class="ds-input-group__label">Mon périmètre<span class="ds-input-group__label-asterisk"> *</span></div>
    <div *ngIf="perimetresDE.length > 0" class="select-container">
      <div class="activite-select">
        <ng-container formArrayName="perimetresDE" *ngFor="let item of perimetresDE; let i = index">
          <input [formControlName]="i" type="checkbox" [name]="item" [id]="item" hidden [checked]="userPerimetres?.includes(item)" />
          <label [for]="item">{{ EnumTypeNotificationTags.toString(item) }}</label>
        </ng-container>
      </div>
    </div>
    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-perimetre"
      *ngIf="!autoAffectationForm.controls['perimetresDE'].valid && autoAffectationForm.controls['perimetresDE'].touched"
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir sélectionner votre périmètre</div>
    </ng-container>
  </div>
  <div
    id="input-activite"
    class="form-step__activite ds-input-group"
    [class.ds-input-group--error]="!autoAffectationForm.controls['activites'].valid && autoAffectationForm.controls['activites'].touched"
  >
    <div class="ds-input-group__label">Mes activités <lib-asterisk></lib-asterisk></div>
    <div *ngIf="activites.length > 0; else noActivite" class="select-container">
      <div class="activite-select">
        <ng-container formArrayName="activites" *ngFor="let item of activites; let i = index">
          <input [formControlName]="i" type="checkbox" name="activite" [id]="item" hidden />
          <label [for]="item">{{ EnumActivite.toString(item) }}</label>
        </ng-container>
      </div>
    </div>
    <ng-template #noActivite>
      <p class="ds-input-group__tips">Aucune activité à séléctionner</p>
    </ng-template>
    <!-- Icône et message d'erreur -->
    <ng-container
      id="error-activite"
      *ngIf="!autoAffectationForm.controls['activites'].valid && autoAffectationForm.controls['activites'].touched"
    >
      <div class="ds-input-group__feedback">Merci de bien vouloir sélectionner votre activité</div>
    </ng-container>
  </div>
  <div class="d-flex">
    <div id="input_referent" class="form-step__referent ds-input-group" *ngIf="showReferent">
      <div class="d-flex">
        <label class="ds-input-group__label">Référent</label>
        <div
          [matTooltip]="
            'L\'affectation en tant que Référent sur le projet est un statut à titre indicatif. Il permet d\'indiquer la personne qui centralise les activités de la DE autour du projet.'
          "
          class="d-flex align-items-center mb-2"
        >
          <lib-pxl-icon class="icon-info ms-2" icon="icon-action-info-circle-outline" aria-hidden="true"></lib-pxl-icon>
        </div>
      </div>
      <div>
        <span id="referent-non-option" class="ds-body-2--regular label-r align-bottom me-2">Non</span>
        <label for="ref" class="ds-switch ds-switch--m">
          <input type="checkbox" id="ref" formControlName="referent" />
          <span class="slider"></span>
        </label>
        <span id="referent-oui-option" class="ds-body-2--regular label-r align-bottom ms-2">Oui</span>
      </div>
    </div>

    <div
      id="input_jpi"
      class="form-step__jpi ds-input-group ms-5"
      *ngIf="showIntervenantPI"
      [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.INTERVENTION_JPI"
    >
      <div class="d-flex">
        <label class="ds-input-group__label">Intervention J/PI</label>
        <div
          [matTooltip]="
            'L\'affectation en tant qu\'Intervenant J/PI permet aux experts de la DJPI d\'évaluer le risque J/PI d\'un projet en phase d\'Instruction. En sélectionnant Oui, vous aurez le droit d\'écriture sur le formulaire de recommandations mais vous serez en consultation seule sur les autres activités de l\'Instruction.'
          "
          class="d-flex align-items-center mb-2"
        >
          <lib-pxl-icon class="icon-info ms-2" icon="icon-action-info-circle-outline" aria-hidden="true"></lib-pxl-icon>
        </div>
      </div>
      <div>
        <span id="intervenant-pi-non-option" class="ds-body-2--regular label-r align-bottom me-2">Non</span>
        <label for="intervenantPI" class="ds-switch ds-switch--m">
          <input type="checkbox" id="intervenantPI" formControlName="intervenantPI" />
          <span class="slider"></span>
        </label>
        <span id="intervenant-pi-oui-option" class="ds-body-2--regular label-r align-bottom ms-2">Oui</span>
      </div>
    </div>
  </div>
  <div class="ds-input-group--error">
    <!-- Icône et message d'erreur -->

    <section
      *ngIf="autoAffectationForm.controls['referent'].value === true && checkProjetReferent()"
      class="card--info card--yellow flex-wrap"
      id="error-referent"
    >
      <lib-pxl-icon class="icon" icon="icon-info-warning"></lib-pxl-icon>
      <p class="card--info--text">Il existe déjà un référent présent. En vous auto-affectant, vous écraserez le référent existant.</p>
    </section>
  </div>
</form>

<div id="modal-btn" class="modal__actions">
  <button class="ds-btn ds-btn--secondary" mat-dialog-close>
    <lib-pxl-icon class="icon" icon="icon-action-close"></lib-pxl-icon>
    Annuler
  </button>
  <button class="ds-btn ds-btn--primary" (click)="save()" cdkFocusInitial>
    <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon>
    Enregistrer
  </button>
</div>
