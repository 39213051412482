import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  DocumentConfigModel,
  DocumentHelper,
  DocumentProjet,
  DocumentService,
  EnumScope,
  EnumTypeDocument,
  ShowToastrService,
  UploadDocModalResultModel,
  Utilisateur,
  FileUtils,
  FILE_SIZE,
  Environment,
  SITE_ADMIN,
  MoveDocumentConfig,
} from '@shared-ui';
import { catchError, take } from 'rxjs';

@Component({
  selector: 'lib-documents-upload-list',
  templateUrl: './documents-upload-list.component.html',
})
export class DocumentsUploadListComponent implements OnInit {
  @Input() scope: EnumScope;
  @Input() projetId: string;
  @Input() structureId: string;
  @Input() etapeCleId: string;
  @Input() documentType: EnumTypeDocument;
  @Input() documentConfigs: DocumentConfigModel[];
  @Input() moveDocumentConfig: MoveDocumentConfig;
  @Input() utilisateur: Utilisateur;
  @Input() canUserWrite: boolean;
  @Input() suiviProjetEnable: boolean;
  @Input() supportedExtensions: string[] = FileUtils.FINNO_EXTENSIONS;
  @Output() documentStatutChange = new EventEmitter<DocumentProjet>();

  documents: DocumentProjet[];
  nameFileNotSupported: string[] = [];
  fileNotSupported = false;
  fileTooBig = false;
  isSiteAdmin: boolean;

  constructor(
    public documentService: DocumentService,
    private showToastrService: ShowToastrService,
    @Inject('environment') public environment: Environment,
    private destroyRef$: DestroyRef
  ) {}

  ngOnInit(): void {
    this.getDocuments();
    this.isSiteAdmin = this.environment.site === SITE_ADMIN;
  }

  getDocuments(): void {
    this.documentService
      .getDocuments(this.projetId, [this.documentType], this.structureId, this.isSiteAdmin, this.etapeCleId)
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: result => {
          this.documents = result;
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  onUploadDocument(result: UploadDocModalResultModel): void {
    this.fileTooBig = false;
    this.fileNotSupported = false;
    this.nameFileNotSupported = [];

    if (result.files) {
      for (const item of Array.from(result.files)) {
        const fileToUpload = item as File;
        const documentProjet: DocumentProjet = DocumentHelper.buildDocumentProjetWithProjetId(
          fileToUpload.name,
          this.projetId,
          result.document
        );
        documentProjet.scope = this.scope;
        documentProjet.typeDoc = this.documentType;
        documentProjet.structureId = this.structureId;
        documentProjet.etapeCleId = this.etapeCleId;

        const fileValidity = FileUtils.getFileTypeAndSizeValidity(fileToUpload, this.supportedExtensions, FILE_SIZE);

        if (fileToUpload && fileValidity.isFileSizeValid && fileValidity.isFileTypeValid) {
          this.uploadDocumentProjet(documentProjet, fileToUpload);
        } else {
          this.fileTooBig = !fileValidity.isFileSizeValid;
          this.fileNotSupported = !fileValidity.isFileTypeValid;
          this.nameFileNotSupported = fileValidity.nameFileNotSupported;
        }
      }
    }
  }

  uploadDocumentProjet(documentProjet: DocumentProjet, file: File | undefined): void {
    this.documentService
      .uploadDocument(documentProjet, file)
      .pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe({
        next: (documentCreated: DocumentProjet) => {
          this.documents = [...this.documents, documentCreated];
          this.showToastrService.success('Le document a bien été ajouté');
        },
        error: err => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  onDeleteDocument(documentProjet: DocumentProjet) {
    this.documents = this.documents.filter(doc => doc.id !== documentProjet.id);
  }

  onDocumentStatutChange($event: DocumentProjet) {
    this.documentService
      .updateDocument($event, this.isSiteAdmin)
      .pipe(
        take(1),
        catchError((err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
          return [];
        })
      )
      .subscribe((result: DocumentProjet) => {
        const document = this.documents.find(doc => doc.id === result.id);
        if (document) {
          document.controlesValidite = result.controlesValidite;
        }
      });
    this.documentStatutChange.emit($event);
  }
}
