import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProjetListViewService } from '@services-candidat/projet-list-view.service';
import { ConfirmModalModule } from '@shared-candidat/components/modals/confirm-modal/confirm-modal.module';
import { MaterialModule } from '@shared-candidat/modules/material.module';
import { SharedModule } from '@shared-candidat/shared.module';
import { SharedUiModule, ToProjetTagPipe } from '@shared-ui';
import { ProjetListComponent } from './projet-list.component';
import { QdtVizModule } from '@shared-candidat/components/qdt/qdt-viz.module';

@NgModule({
  declarations: [ProjetListComponent, ToProjetTagPipe],
  imports: [CommonModule, MaterialModule, ConfirmModalModule, MatDividerModule, SharedModule, SharedUiModule, MatTooltipModule, QdtVizModule],
  providers: [ProjetListViewService],
})
export class ProjetListModule {}
