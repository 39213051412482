import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { SignalService } from '../../shared/services/signal.service';
export interface ModalDataAnneeCumul {
  title: string;
  years: number[];
  textGoButton: string;
  textReturnButton: string;
  updateMode?: boolean;
}
@Component({
  selector: 'lib-annee-cumul',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatDialogModule],
  templateUrl: './annee-cumul.component.html',
  styleUrl: './annee-cumul.component.scss',
})
export class AnneeCumulComponent implements OnInit {
  inputForm!: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<AnneeCumulComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalDataAnneeCumul,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private signalService: SignalService
  ) {}

  cancelModal() {
    if (this.inputForm.dirty) {
      const cn = confirm('Les modifications apportées ne seront peut-être pas sauvegardées. Etes-vous sûr de vouloir quitter cette page ?');
      if (cn) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }

  saveModal() {
    if (this.inputForm.valid) {
      this.dialogRef.close(this.inputForm.getRawValue());
    } else {
      this.inputForm.markAllAsTouched();
    }
  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm() {
    const controlsConfig: ControlConfig = {
      currentYear: [null, Validators.required],
      soldeTresorerieDernierBilan: [null, Validators.required],
    };
    this.inputForm = this.formBuilder.group(controlsConfig);
    if (this.modalData.updateMode) {
      this.inputForm.get('soldeTresorerieDernierBilan')?.setValue(this.signalService.getLastBalance());
      this.inputForm.get('currentYear')?.setValue(this.signalService.getCurrentFinancialYear());
    }
  }

  protected readonly Number = Number;
}

export type ControlConfig = {
  currentYear: (Validators | null)[];
  soldeTresorerieDernierBilan: (Validators | null)[];
};
