<div class="page-content page-content--no-scroll">
  <div class="projets__header">
    <h1 class="projets__title">Tous mes projets</h1>

    <div class="card__action-button-wrapper div_button_list_projets">
      <button id="switch-to-evaluateur" class="ds-btn ds-btn--secondary button_evaluateur_overwrite" (click)="redirectEvaluateur()">
        Accéder à mon espace evaluateur
      </button>
      <button id="add-new-projet" class="ds-btn ds-btn--primary" (click)="goToProjetParcours()" [disabled]="aaps?.length === 0">
        <lib-pxl-icon class="icon" icon="icon-action-add-circle-outline" aria-hidden="true"></lib-pxl-icon>
        Nouveau projet
      </button>
      <div id="add-new-projet-tooltip" class="card__action-button-tooltip" *ngIf="aaps?.length === 0">
        <p>Aucun appel à projets ouvert actuellement.</p>
      </div>
    </div>
  </div>
  <section *ngIf="showQlikSenseElements" class="qlik-sense-container">
    <qdt-component [component]="viz1.component" [options]="viz1.options" [connection]="viz1.connection" [style]="viz1.style"></qdt-component>
    <qdt-component [component]="viz2.component" [options]="viz2.options" [connection]="viz2.connection" [style]="viz2.style"></qdt-component>
  </section>
  <div id="projet" class="projets__content">
    <div class="projets__list">
      <article
        class="projet"
        *ngFor="let projet of projetService.combinedProjets$ | async; index as i"
        (click)="goToProjet(projet.id, projet.isEnSuivi)"
        matTooltip="{{ projet.isEnSuivi && projet.displayStatut ? projet.tooltipText : '' }}"
      >
        <header id="nomAap">
          <div class="projet__aap">{{ projet.nomAap }}</div>
          <h2 class="projet__nom">{{ projet.nom }}</h2>
        </header>

        <div id="etapesProjet" class="projet__tags">
          <div class="projet__tag" [ngClass]="{ 'projet__tag-suivi': projet.isEnSuivi }">{{ projet.etape }}</div>
          <div class="projet__tag" [ngClass]="{ 'projet__tag-suivi': projet.isEnSuivi }" *ngIf="projet.displayStatut">
            {{ projet.statut }}
          </div>
          <div *ngIf="projet?.tag && projet?.tag !== 'FERME'" class="projet__tag" [ngClass]="{ 'projet__tag-suivi': projet.isEnSuivi }">
            {{ projet?.tag | toProjetTag }}
          </div>
        </div>

        <div id="dateCreationProjet" class="projet__infos">
          <div class="info" *ngIf="!projet.isEnSuivi">
            <div class="info__label">Création</div>
            <div class="info__value">{{ (projet.dateCreation | date : 'dd/MM/yyyy') || '-' }}</div>
          </div>

          <div id="releveAap" class="info">
            <div class="info__label">Prochaine {{ projet.isEnSuivi ? 'étape clé' : 'relève' }}</div>
            <div class="info__value">{{ projet.dateProcaineReleveOuEC }}</div>
          </div>
        </div>
      </article>
    </div>
  </div>
  <div class="qlik-sense-button" [libMyRemoveIfFeatureOff]="EnumFeatureFlipping.INTEGRATION_QLIKSENSE">
    <p class="ds-input-group__label">Afficher les éléments QlikSense</p>
    <div class="switch-grp">
      <label for="obligatoire" class="ds-switch ds-switch--m">
        <input type="checkbox" id="obligatoire" [value]="showQlikSenseElements" (input)="onInputChange()" />
        <span class="slider"></span>
      </label>
    </div>
  </div>
</div>
