<div class="info-panel">
  <lib-accordion titre="Ce que je dois savoir" [accordionOpen]="open" (click)="onClickAccordion()">
    <article class="ce-que-je-dois-savoir">
      <p>
        Au niveau de la grille d’impacts, les données attendues et saisies sont à lier à la phase dans laquelle le projet se trouve. La
        phase est rappelée via une étiquette au niveau de chaque indicateur de la grille d’impacts et peut prendre trois valeurs :
      </p>
      <ul>
        <li>
          <b>Cible :</b> la donnée attendue correspond à la cible en fin de projet que vous estimez atteindre pour l’indicateur. Cette phase
          est associée au dépôt de votre projet.
        </li>
        <li>
          <b>Cible révisée :</b> la donnée attendue correspond à la cible en fin de projet que vous estimez atteindre pour l’indicateur,
          initialisée lors du dépôt de votre projet et dont la valeur peut être mise à jour à chaque étape clé.
        </li>
        <li>
          <b>Réalisé :</b> la donnée attendue correspond au réalisé que vous avez atteint pour l’indicateur, et dont la valeur est récupérée
          à la dernière étape clé du projet.
        </li>
      </ul>
      <p>
        Merci de vous reporter à la définition ci-dessus, aux aides et à la valeur de la phase pour chaque indicateur pour connaitre la
        nature de la donnée attendue.
      </p>
    </article>
  </lib-accordion>
</div>
