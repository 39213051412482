import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { EnumFeatureFlipping, FeatureFlagService, ShowToastrService } from '@shared-ui';
import { filter } from 'rxjs/operators';
import { AppConfig } from '../app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'pxl_front_candidat';

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private showToastrService: ShowToastrService,
    public appConfig: AppConfig,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit(): void {
    if (this.featureFlagService.featureOn(EnumFeatureFlipping.INTEGRATION_QLIKSENSE)) {
      this.authQLS();
    }

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const rt = this.getChild(this.activatedRoute);

      rt.data.subscribe(data => {
        this.titleService.setTitle(data.title);
      });
    });

    // If there is an error param from evaluateur, we display a toastr
    this.activatedRoute.queryParams.subscribe(params => {
      const errorParam = params.error;

      if (errorParam) {
        this.showToastrService.checkCodeError(errorParam);
      }
    });
  }

  authQLS() {
    const socket = new WebSocket('wss://staging.qls-nprd.cloud.bpifrance.fr/mcb/app/ef0c77d8-1a61-4f37-9831-9f2254e01b85');
    socket.addEventListener('message', ($event: any) => {
      if (!$event.data) {
        return;
      }
      const data = JSON.parse($event.data);
      if (data.method === 'OnAuthenticationInformation' && data.params.mustAuthenticate) {
        window.open('https://staging.qls-nprd.cloud.bpifrance.fr/mcb/content/AuthPage_PXL/PXLAuthPage.html', '_self');
      } else {
        socket.close();
      }
    });
    socket.addEventListener('open', () => {
      socket.send(JSON.stringify({ type: 'blockRedirect' }));
    });
  }

  getChild(activatedRoute: ActivatedRoute): ActivatedRoute {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
}
