import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-expandable-text',
  templateUrl: './expandable-text.component.html',
})
export class ExpandableTextComponent {
  @Input() limit: number = 750;

  _text: string = '';
  displayedText: string = '';
  isTextExpanded = false;
  isExpansionButtonDisplayed = false;
  expansionButtonText: string;

  @Input() set text(value: string) {
    this._text = value;
    if (value.length <= this.limit) {
      this.displayedText = value;
      this.isExpansionButtonDisplayed = false;
      this.expansionButtonText = '';
    } else {
      this.displayedText = value.slice(0, this.limit) + '...';
      this.isExpansionButtonDisplayed = true;
      this.expansionButtonText = 'Voir plus';
    }
  }

  showMore() {
    this.displayedText = this._text;
    this.isTextExpanded = true;
    this.expansionButtonText = 'Voir moins';
  }

  showLess() {
    this.displayedText = this._text.slice(0, this.limit) + '...';
    this.isTextExpanded = false;
    this.expansionButtonText = 'Voir plus';
  }
}
