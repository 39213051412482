<lib-expansion-panel [title]="'Logos des entreprises'" [showValidIcon]="showValidIcon()" [showMandatoryAsterisk]="true">
  <div panel-content>
    <lib-card-info
      message="Merci de vérifier que les images chargées dans cette section s’affichent correctement (bonne qualité, non tronquée,...)et de, si possible, faire apparaitre le nom de l'entreprise dans le (ou les) logo(s) téléchargé.
      Les images seront reprises dans la fiche de communication."
    ></lib-card-info>
    <form class="form" [formGroup]="logosStructuresForm" (ngSubmit)="onLogosStructuresSubmitted()">
      <div class="d-flex flex-wrap" formArrayName="logosStructures">
        <!-- Logo Structures -->
        <ng-container *ngFor="let structure of structureList; let j = index">
          <div class="ds-input-group__label fw-bold">Logo de l'entreprise {{ structure.raisonSocial }}<lib-asterisk></lib-asterisk></div>
          <div
            id="logoStructure"
            [formGroupName]="j"
            class="form-step__logoStructure file-upload ds-input-group w-100"
            [class.ds-input-group--error]="
              !logosStructuresArray.at(j)?.get('logoStructure')?.valid && logosStructuresArray.at(j)?.get('logoStructure')?.touched
            "
          >
            <div id="info-upload-fichier" class="file-upload__hint" *ngIf="!imgLogoStructureList[j]?.id">
              Poids maximum par fichier 17Mo. Taille requise : 113x37px. Format requis : PNG, JPEG.<br />
              <ng-container
                id="error-logo"
                *ngIf="
                  !logosStructuresArray.at(j)?.get('logoStructure')?.valid && logosStructuresArray.at(j)?.get('logoStructure')?.touched
                "
              >
                <div class="ds-input-group__feedback error-logo">Merci de bien vouloir renseigner un fichier</div>
              </ng-container>
            </div>

            <label class="file-upload__custom-input">
              <input
                type="file"
                id="file"
                *ngIf="!imgLogoStructureList[j]?.id"
                (change)="onUpload($event.target, structure, j)"
                accept=".jpg, .png"
              />
              <input type="hidden" name="fileHidden" formControlName="logoStructure" />
              <div
                id="add-fichier"
                class="ds-btn ds-btn--primary"
                *ngIf="!imgLogoStructureList[j]?.id && canUserWrite && !isValideFicheCom"
              >
                Ajouter un fichier
              </div>
              <div *ngIf="imgLogoStructureList[j]?.id" class="sidenav-projet__logo img-div-render" [class.ratio-error]="ratio[j]">
                <img class="img-render" [alt]="imgLogoStructureList[j]?.nom" [src]="imagePath[j]" />
              </div>
            </label>
            <div id="logo-value" class="file-upload__hint file-value" *ngIf="imgLogoStructureList[j].id">
              <div class="doc-infos">
                <span>{{ imgLogoStructureList[j].nom }}</span>
                <br />
                <span *ngIf="imgLogoStructureList[j].dateCreation !== null"
                  >Chargé le {{ imgLogoStructureList[j]?.dateCreation | date : 'dd/MM/yyyy' }}
                  {{ getNomCreateurDocument(imgLogoStructureList[j]) }}</span
                >
              </div>
              <div class="doc-actions">
                <div class="card__action-button-wrapper">
                  <button
                    type="button"
                    id="download-document"
                    class="card__action-button"
                    [ngClass]="{
                      'card__action-button--error': isScanedDocument(imgLogoStructureList[j]) && imgLogoStructureList[j].scan !== SAFE
                    }"
                    (click)="downloadDocument(imgLogoStructureList[j])"
                    [disabled]="!isScanedDocument(imgLogoStructureList[j]) || imgLogoStructureList[j].scan !== SAFE"
                  >
                    <lib-pxl-icon class="icon" icon="icon-action-save-alt"></lib-pxl-icon>
                  </button>
                  <div class="card__action-button-tooltip" *ngIf="!isScanedDocument(imgLogoStructureList[j])">
                    <p>Le test antimalware est en cours.</p>
                  </div>
                  <div
                    class="card__action-button-tooltip"
                    *ngIf="isScanedDocument(imgLogoStructureList[j]) && imgLogoStructureList[j].scan !== SAFE"
                  >
                    <p>Ce fichier a été identifié comme étant potentiellement dangereux et ne peut donc pas être téléchargé.</p>
                  </div>
                </div>
                <div class="card__action-button-wrapper" *ngIf="canUserWrite && !isValideFicheCom">
                  <button
                    type="button"
                    id="delete-document"
                    class="card__action-button"
                    (click)="onDeleteDocument(imgLogoStructureList[j], j)"
                  >
                    <lib-pxl-icon class="icon" icon="icon-action-delete"></lib-pxl-icon>
                  </button>
                </div>
              </div>
            </div>
            <div id="error-logo-type" class="file-upload__error" *ngIf="fileNotSupported[j]">
              Le type du document sélectionné n'est pas supporté, merci de sélectionner un autre fichier.
            </div>
            <div id="error-logo-size" class="file-upload__error" *ngIf="fileTooBig[j]">
              Le fichier sélectionné est trop lourd, merci de sélectionner un autre fichier.
            </div>
          </div>
          <!-- Icône et message d'erreur -->
        </ng-container>

        <!-- FIN de Logo Structures -->

        <div class="w-100 d-flex">
          <ng-container *ngIf="formSubmitted && logosStructuresForm.touched && !logosStructuresForm.valid">
            <div class="ds-input-group ds-input-group--error w-100">
              <div class="ds-input-group__feedback">Merci de renseigner les champs entourés en rouge.</div>
            </div>
          </ng-container>

          <div class="w-100 d-flex justify-content-end button-container" *ngIf="canUserWrite && !isValideFicheCom">
            <button type="button" class="ds-btn ds-btn--secondary me-2" (click)="cancel()">
              <span>Annuler</span>
            </button>
            <button type="submit" class="ds-btn ds-btn--primary">
              <span>Enregistrer</span>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</lib-expansion-panel>
