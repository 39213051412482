<div class="modal_content">
  <h2 id="modal-title" class="modal__title">
    {{ edited ? 'Modifier IBAN' : 'Nouvel IBAN' }}
  </h2>
  <span id="modal-subtitle" class="modal__sub_title">
    <span *ngIf="!edited">Vous avez indiqué que votre IBAN n'était pas dans la liste des IBAN précédemment présentés.</span>
    <span class="fw-bold">Veuillez entrer votre nouvel IBAN ci-dessous.</span>
    <br />
    Une première vérification du format de l'IBAN sera effectuée, suivi par une certification de notre équipe afin que vous puissiez
    l'utiliser.
  </span>

  <form id="form-edition-iban" class="modal__content" [formGroup]="form">
    <!-- Libelle IBAN -->
    <div
      class="ds-input-group ds-input-group--date"
      [class.ds-input-group--error]="form.controls.libelle.invalid && form.controls.libelle.touched"
    >
      <label class="ds-input-group__label">
        Libellé de l'IBAN
        <lib-asterisk></lib-asterisk>
      </label>
      <span class="ds-input-group__input">
        <input matInput class="ds-input-group__input" formControlName="libelle" placeholder="Libellé de l'IBAN" maxlength="50" />
      </span>
      <ng-container *ngIf="form.controls.libelle.invalid && form.controls.libelle.touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un libellé valide.</div>
      </ng-container>
    </div>

    <!-- Numero IBAN -->
    <div
      class="ds-input-group ds-input-group--date"
      [class.ds-input-group--error]="(form.controls.iban.invalid && form.controls.iban.touched) || showInvalidIban"
    >
      <label class="ds-input-group__label">
        Numéro IBAN
        <lib-asterisk></lib-asterisk>
      </label>
      <span class="ds-input-group__input">
        <input
          libIban
          matInput
          class="ds-input-group__input"
          formControlName="iban"
          placeholder="XXXX XXXX XXXX XXXX XXXX XXXX XXXX XXXX"
          maxlength="34"
          (change)="showInvalidIban = false"
        />
      </span>
      <ng-container *ngIf="(form.controls.iban.invalid && form.controls.iban.touched) || showInvalidIban">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un IBAN valide.</div>
      </ng-container>
    </div>

    <!-- BIC -->
    <div class="ds-input-group ds-input-group--date" [class.ds-input-group--error]="form.controls.bic.invalid && form.controls.bic.touched">
      <label class="ds-input-group__label">
        BIC
        <lib-asterisk></lib-asterisk>
      </label>
      <span class="ds-input-group__input">
        <input matInput class="ds-input-group__input" formControlName="bic" placeholder="BIC" maxlength="11" />
      </span>
      <ng-container *ngIf="form.controls.bic.invalid && form.controls.bic.touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un BIC valide.</div>
      </ng-container>
    </div>

    <!-- Nom banque -->
    <div
      class="ds-input-group ds-input-group--date"
      [class.ds-input-group--error]="form.controls.nomBanque.invalid && form.controls.nomBanque.touched"
    >
      <label class="ds-input-group__label">
        Nom de la banque
        <lib-asterisk></lib-asterisk>
      </label>
      <span class="ds-input-group__input">
        <input matInput class="ds-input-group__input" formControlName="nomBanque" placeholder="Nom de la banque" maxlength="250" />
      </span>
      <ng-container *ngIf="form.controls.nomBanque.invalid && form.controls.nomBanque.touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un nom valide.</div>
      </ng-container>
    </div>

    <!-- Nom agence -->
    <div
      class="ds-input-group ds-input-group--date"
      [class.ds-input-group--error]="form.controls.nomAgence.invalid && form.controls.nomAgence.touched"
    >
      <label class="ds-input-group__label">
        Nom de l'agence
        <lib-asterisk></lib-asterisk>
      </label>
      <span class="ds-input-group__input">
        <input matInput class="ds-input-group__input" formControlName="nomAgence" placeholder="Nom de l'agence" maxlength="250" />
      </span>
      <ng-container *ngIf="form.controls.nomAgence.invalid && form.controls.nomAgence.touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un nom valide.</div>
      </ng-container>
    </div>

    <!-- Adresse agence -->
    <div
      class="ds-input-group ds-input-group--date"
      [class.ds-input-group--error]="form.controls.adresseAgence.invalid && form.controls.adresseAgence.touched"
    >
      <label class="ds-input-group__label">
        Adresse de l'agence
        <lib-asterisk></lib-asterisk>
      </label>
      <span class="ds-input-group__input">
        <input matInput class="ds-input-group__input" formControlName="adresseAgence" placeholder="Adresse de l'agence" maxlength="500" />
      </span>
      <ng-container *ngIf="form.controls.adresseAgence.invalid && form.controls.adresseAgence.touched">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une adresse valide.</div>
      </ng-container>
    </div>

    <div class="ds-input-group">
      <label for="commentaire-input" class="ds-input-group__label"> Commentaire </label>
      <textarea
        matInput
        id="commentaire-input"
        name="input-field"
        rows="5"
        col="0"
        maxlength="2500"
        class="ds-input-group__textarea"
        formControlName="commentaire"
        type="text"
        #textInput
      ></textarea>
      <div class="form-input__length-hint" [class.form-input__length-hint--full]="textInput.value?.length === 2500">
        <span>{{ textInput.value?.length || 0 }}/2500</span>
      </div>
    </div>

    <div class="ds-input-group" [class.ds-input-group--error]="showDocumentRequired">
      <label for="file" class="ds-input-group__label">
        Document(s)
        <lib-asterisk></lib-asterisk>
      </label>

      <lib-document-async-uploader
        *ngIf="documentsLoaded"
        #documentAsyncUploaderComponent
        [(documents)]="documents"
        title="Document(s) RIB"
        [projetId]="projet.id"
        [canUserWrite]="true"
        [populateDocFields]="getDocFieldsCompleter()"
        [acceptedDocuments]="ACCEPTED_DOCUMENTS"
      >
        <div class="d-inline" subtitle>
          <br />
          <span> Formats acceptés : pdf, jpeg, jpg, png, bmp, tiff</span>
        </div>
      </lib-document-async-uploader>

      <ng-container *ngIf="showDocumentRequired">
        <div class="ds-input-group__feedback">Merci de bien vouloir charger un document.</div>
      </ng-container>
    </div>
  </form>

  <div id="modal-btn" class="modal__actions">
    <button (click)="cancel()" class="ds-btn ds-btn--secondary">
      <lib-pxl-icon class="icon" icon="icon-action-close"></lib-pxl-icon>
      Annuler
    </button>
    <button class="ds-btn ds-btn--primary" (click)="save()">
      <lib-pxl-icon class="icon" icon="icon-action-send"></lib-pxl-icon>
      Confirmer
    </button>
  </div>
</div>
