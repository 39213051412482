import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Kpi } from '../models/kpi.model';
import { Environment } from '../models/environment.model';
import { KpisByCategorie } from '../models/kpis-by-categorie.model';
import { GrilleImpacts } from '../models/grille-impacts.model';
import { EnumScope } from '../enums/enum.scope';
import { GrilleImpactStatutModel } from '../models/grille-impact-statut.model';

@Injectable({
  providedIn: 'root',
})
export class GrilleImpactHttpService {
  constructor(@Inject('environment') private environment: Environment, public httpClient: HttpClient) {}

  getKpis(isLegacy: boolean | null = null): Observable<HttpResponse<Kpi[]>> {
    let params = new HttpParams();
    if (isLegacy !== null) {
      params = params.append('legacy', isLegacy.toString());
    }
    return this.httpClient.get<Kpi[]>(this.environment.apiUrl + 'kpis', {
      params: params,
      observe: 'response',
    });
  }

  getKpisByCategorie(id: string, type: string): Observable<HttpResponse<KpisByCategorie[]>> {
    return this.httpClient.get<KpisByCategorie[]>(this.environment.apiUrl + `kpis/aaps/${id}?type=${type}`, { observe: 'response' });
  }

  getGrilleImpactByFilters(
    projetId: string,
    structureId: string,
    scope: EnumScope,
    enSuivi?: boolean
  ): Observable<HttpResponse<GrilleImpacts[]>> {
    const params: { projetId?: string; structureId?: string; scope?: string; enSuivi?: boolean } = {};
    if (projetId !== null) {
      params.projetId = projetId;
    }
    if (structureId !== null) {
      params.structureId = structureId;
    }
    if (scope !== null) {
      params.scope = scope.toString().toUpperCase();
    }
    if (enSuivi !== null) {
      params.enSuivi = enSuivi;
    }

    return this.httpClient.get<GrilleImpacts[]>(this.environment.apiUrl + `grille-impacts`, { params, observe: 'response' });
  }

  saveGrilleImpacts(grilleImpact: GrilleImpacts): Observable<HttpResponse<GrilleImpacts>> {
    return this.httpClient.post<GrilleImpacts>(this.environment.apiUrl + `grille-impacts`, JSON.stringify(grilleImpact), {
      observe: 'response',
    });
  }

  getProjectAndStructuresKpisStatus(projetId: string): Observable<GrilleImpactStatutModel> {
    return this.httpClient.get<GrilleImpactStatutModel>(this.environment.apiUrl + 'grille-impacts/projets/' + projetId + '/statuts');
  }

  getProjectAndStructuresKpisStatusSuivi(projetId: string, etapesId: string, structureId?: string): Observable<GrilleImpactStatutModel> {
    return this.httpClient.post<GrilleImpactStatutModel>(this.environment.apiSuiviUrl + 'suivi-projets/resume-validation-gi', {
      projetsId: projetId ? [projetId] : [],
      etapesId: etapesId ? [etapesId] : [],
      structuresId: structureId ? [structureId] : [],
    });
  }
}
