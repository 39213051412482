import { Component, Input } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '@shared-candidat/components/modals/confirm-modal/confirm-modal.component';
import { DisplayColumn, TableCell } from '@shared-ui';
import { ProjetInfoDepensesHelperService } from '../projet-info-depenses.helper.service';

export interface StepKey {
  id: string;
  nombreMois: string;
  date: Date;
}

@Component({
  selector: 'pxl-projet-info-depenses-calendar',
  templateUrl: './projet-info-depenses-calendar.component.html',
  styleUrls: ['./projet-info-depenses-calendar.component.scss'],
})
export class ProjetInfoDepensesCalendrierComponent {
  @Input() set calendarForm(value: UntypedFormGroup) {
    this._calendarForm = value;
    this.dataSourceArray = this._calendarForm.get('etapesCles')?.value || [];
    this.updateTable();
  }

  _calendarForm: UntypedFormGroup;
  tooltipAction = 'Supprimer cette étape clé';

  displayedColumns: DisplayColumn[] = [
    { def: 'id', label: 'Etape clé' },
    { def: 'nombreMois', label: "Délais d'étape clé", editable: true, mandatory: true, placeholder: 'nombre de mois' },
    { def: 'date', label: 'Date étape clé', deleteAction: true, date: true, tooltipAction: this.tooltipAction },
  ];
  dataSource: { id: TableCell; nombreMois: TableCell; date: TableCell }[] = [];
  dataSourceArray: StepKey[] = [];

  constructor(public matDialog: MatDialog) {}

  updateTable(): void {
    this.dataSource =
      this.dataSourceArray.length > 0
        ? this.dataSourceArray.map(element => {
            return {
              id: new TableCell(element.id),
              nombreMois: new TableCell(element.nombreMois),
              date: new TableCell(element.date?.toString()),
            };
          })
        : [];
  }

  addDate(): void {
    this.dataSourceArray.push({
      id: this.computeId(),
      nombreMois: '',
      date: null,
    });
    (this._calendarForm.controls['etapesCles'] as FormArray)?.push(ProjetInfoDepensesHelperService.getEtapeCleForm(undefined));
    this.updateTable();
  }

  private computeId() {
    return Math.max(...this.dataSourceArray.map(elt => parseInt(elt.id)), 0) + 1 + '';
  }

  onDateChange(): void {
    this.dataSourceArray.forEach(f => {
      this.onDelaiChange(f);
    });
  }

  onDelaiChange(element: StepKey): void {
    if (element.nombreMois) {
      const date = new Date(this._calendarForm.controls['dateT0']?.value);
      date.setMonth(date.getMonth() + parseInt(element.nombreMois, 10));
      element.date = date;
    } else {
      element.date = null;
    }

    this.dataSourceArray.sort((a, b) => (parseInt(a.nombreMois, 10) < parseInt(b.nombreMois, 10) ? -1 : 1));
    this._calendarForm.controls['etapesCles'].setValue(this.dataSourceArray);

    this.updateTable();
  }

  onDeleteElement(index: number): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: "Suppression de l'étape clé",
        description: `<p>En confirmant l'action, cette étape clé va être supprimée. Confirmez vous l'action ?</p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
      },
    });

    (this._calendarForm.controls['etapesCles'] as FormArray)?.removeAt(index);
    dialogRef.afterClosed().subscribe(hasValidated => {
      if (!hasValidated) {
        return;
      }

      this.dataSourceArray.splice(index, 1);
      this.dataSourceArray.forEach((element, index) => {
        element.id = index + 1 + '';
      });
      this.updateTable();
    });
  }

  onkeypress($event: KeyboardEvent, element: StepKey): boolean {
    if ($event.key) {
      if ($event.key === 'Enter') {
        this.updateData(element);
      }
      if (/[^\d]/.test($event.key)) {
        return false;
      }
    }
    return true;
  }

  updateData($event: { id: string; nombreMois: string; date: Date }): void {
    const index = this.dataSourceArray.findIndex((element: any) => element.id === $event.id);
    this.dataSourceArray[index] = $event;
    this.onDelaiChange($event);
  }
}
