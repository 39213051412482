import { Component, OnInit } from '@angular/core';
import { DonneesFinancieres, Structure } from '@shared-ui';
import { Observable } from 'rxjs';
import { ProjetConsortiumDonneesFinancieresComponent } from './projet-consortium-donnees-financieres.component';

@Component({
  selector: 'lib-projet-consortium-donnees-financieres',
  templateUrl: './projet-consortium-donnees-financieres.component.html',
  styleUrls: ['./projet-consortium-donnees-financieres.component.scss'],
})
export class AdminProjetConsortiumDonneesFinancieresComponent extends ProjetConsortiumDonneesFinancieresComponent implements OnInit {
  protected override putStructureDonneesFinancieres(structureId: string, donneesFinancieres: DonneesFinancieres): Observable<Structure> {
    return this.httpService.adminPutStructureDonneesFinancieres(structureId, donneesFinancieres);
  }
}
