<!-- Entête de la page -->
<header id="header-structure" class="page-header">
  <pxl-sidebar-button class="page-header__burger"></pxl-sidebar-button>
  <a class="page-header__back" (click)="onGoToConsortiumInfo()">
    <lib-pxl-icon class="icon" icon="icon-arrow-arrow-back-ios" aria-hidden="true"></lib-pxl-icon>
    Ma structure
  </a>
  <h2 class="page-header__title">Les éléments de la grille d'impacts</h2>
</header>
<!-- FIN de Entête de la page -->
<div class="page-content" *ngIf="grilleImpactsLoaded && grilleImpactsResponsesLoaded">
  <div class="card--info card--blue card--readonly" *ngIf="!isStructureUpdatableByUser || selectedVersion">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    Vous êtes en lecture seule sur cette page. Vous ne pouvez pas éditer son contenu.
    {{ projet.inactif ? 'Pour effectuer des modifications sur le projet, vous devez d’abord le réactiver.' : null }}
  </div>

  <div class="card--info card--blue card--readonly" *ngIf="isGrilleImpactsProjetValid && isStructureUpdatableByUser">
    <lib-pxl-icon class="icon" icon="icon-action-info"></lib-pxl-icon>
    La grille d'impacts est validée il n'est plus possible de la modifier.
  </div>

  <lib-grille-impact-info-panel [open]="ceQueJeDoisSavoirOpen" (openChange)="closeOrOpenCeQueJeDoisSavoir()"></lib-grille-impact-info-panel>

  <section class="card card__kpi-version" *ngIf="viewVersionGI">
    <h4 class="card__title">Consulter les versions de la grille d'impacts de la structure</h4>
    <div class="card__restore-button" *ngIf="selectedVersion?.statut === StatutGrilleImpacts.FINALISED">
      <button class="ds-btn ds-btn--tertiary" (click)="restoreKpiVersion()" *ngIf="isSelection">
        <lib-pxl-icon class="icon" icon="icon-history"></lib-pxl-icon>
        Restaurer la version en cours
      </button>
    </div>

    <div class="card__select-version ds-input-group__select ds-input-group__select--with-icon">
      <select id="selectVersion" name="selectVersion" [(ngModel)]="selectedVersion" (change)="onChangeKpiVersion()">
        <option [ngValue]="null" selected disabled hidden>Selectionnez une version</option>
        <option *ngFor="let item of kpiVersions" [ngValue]="item">
          {{ item.dateValidation | date : 'dd/MM/yyyy' }}
          <span>Version {{ EnumProjetEtape.toString(item.typeVersion) }}</span>
        </option>
      </select>
    </div>
  </section>

  <lib-grille-impact
    [kpisByCategorieList]="kpisByCategorieList"
    [scope]="'STRUCTURE'"
    [IdAap]="aap.id"
    [idProjet]="projetId"
    [grilleImpacts]="grilleImpacts"
    [idStructure]="structureId"
    [isUpdatableByUser]="isGrilleImpactUpdatable && !selectedVersion"
    [isSelection]="isSelection"
  ></lib-grille-impact>
</div>
