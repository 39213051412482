<ng-container *ngIf="!hidden">
  <div class="mt-3" *ngFor="let documentConfig of documentConfigs">
    <label class="fw-bold mb-2">
      {{
        documentConfig.demandeComplementDocumentId
          ? documentConfig.enSuivi
            ? EnumDocumentComplementaireTypeSuivi.toString(documentConfig.nomDocument)
            : EnumDocumentComplementaireType.toString(documentConfig.nomDocument)
          : documentConfig.nomDocument
      }}
      <button
        *ngIf="checkDocNonFourni(documentConfig) && canSeeCommentaire(documentConfig)"
        class="card__action-button comment-button"
        (click)="openModalNoDocument(documentConfig)"
        matTooltipClass="tooltip"
        [matTooltip]="getPasDeDocCommentaire(documentConfig)"
      >
        <lib-pxl-icon class="icon comment-icon" icon="icon-comment"></lib-pxl-icon>
      </button>
    </label>
    <div *ngIf="documentConfig.demandeComplementDocumentId && documentConfig.description" class="doc-desc">
      Description du document complémentaire : {{ documentConfig.description }}
    </div>

    <div class="mb-3">
      <div
        *ngIf="canUserWrite"
        class="card dashed"
        [class.dashed-red]="documentConfig.obligatoire && !checkAtLeastOneDocUploaded(documentConfig)"
      >
        <div class="d-flex" libDnd (fileDropped)="onFileDropped($event, documentConfig)">
          <input type="file" multiple #fileDropRef (change)="fileBrowseHandler($event, documentConfig)" />
          <lib-pxl-icon icon="icon-file-folder-insert-drive-file-new" aria-hidden="true"></lib-pxl-icon>
          <div>
            <div class="fw-bold"><span class="text-decoration-underline me-1">Ajouter un document </span> ou glisser/déposer ici.</div>
            <div>Formats acceptés : {{ acceptedFormats[documentConfig.nomDocument] }}</div>
          </div>
        </div>
      </div>

      <div class="card card--message card--inactive" *ngIf="!checkAtLeastOneDocUploaded(documentConfig) && !canUserWrite">
        <div class="card__title">Aucun document chargé</div>
      </div>

      @if (documents?.length) {
      <div>
        @for (document of documents; track document) {
        <div>
          @if ((documentConfig?.demandeComplementDocumentId ? document.demandeComplementDocumentId ===
          documentConfig.demandeComplementDocumentId : document.type === documentConfig.nomDocument) && document.scope === scope &&
          (!structureId || document.structureId === structureId)) {
          <lib-document-card
            [document]="document"
            [moveDocumentConfig]="moveDocumentConfig"
            [hasCheckbox]="false"
            [canUserWrite]="canUserWrite"
            (deleteDocEmitter)="onDeleteDocument($event)"
            [showDownload]="canDownload(document) || !isCandidat"
            [deleteDisabled]="(projectEtapeName && document.etape !== projectEtapeName && isCandidat) || documentConfig.archived"
            [isCandidat]="isCandidat"
            [isSiteAdmin]="isSiteAdmin"
            [isSiteEval]="isSiteEval"
            [utilisateur]="utilisateur"
            [validationEnabled]="suiviProjetEnable"
            [statut]="document?.controlesValidite?.statut"
            [commentaireDocumentInvalide]="document?.controlesValidite?.commentaire"
            [motifDocumentInvalide]="document?.controlesValidite?.motif"
            (documentStatutChange)="documentChange($event, document)"
          >
            <ng-container document-modification-tag>
              <div
                class="card__action-button-wrapper"
                *ngIf="showTag && document.completudeSecondNiveauDocument?.statut === 'MODIFICATION_DEMANDEE'"
              >
                <div class="card__tag card__tag--yellow">Demande de modification</div>
              </div>
            </ng-container>
          </lib-document-card>
          }
          <ng-container>
            <div
              class="card card--message card--inactive"
              *ngIf="
                document.typeDoc === EnumTypeDocument.DOCUMENT_NON_FOURNI && !canUserWrite && checkAtLeastOneDocUploaded(documentConfig)
              "
            >
              <div class="card__title">Aucun document chargé</div>
            </div>
          </ng-container>
        </div>
        }
      </div>
      }
    </div>

    <div
      class="flex mt-2 mb-2"
      *ngIf="documentConfig.absenceDocument && canUserWrite && isCandidat && !checkAtLeastOneDocUploaded(documentConfig)"
    >
      <button type="button" class="ds-btn ds-btn--secondary float-end" (click)="openModalNoDocument(documentConfig)">
        Je n'ai pas ce document
      </button>
    </div>

    <ng-container *ngIf="error && documentConfig.nomDocument === errorName">
      <div id="error-logo-size" class="file-error-message" *ngIf="fileTooBig">
        {{ nameFileNotSupported?.length === 1 ? 'Le fichier ' : 'Les fichiers ' }} {{ nameFileNotSupported?.join(', ') }}
        {{ nameFileNotSupported?.length === 1 ? ' est trop lourd ' : 'sont trop lourds' }}, merci de sélectionner un autre fichier.
      </div>
      <div class="file-error-message" *ngIf="videoTooBig">
        {{ nameFileNotSupported?.length === 1 ? 'La taille du fichier video' : 'La taille des fichiers vidéos' }}
        {{ nameFileNotSupported?.join(', ') }} dépasse 500 Mo. Merci de sélectionner un fichier de taille égale ou inférieure pour
        poursuivre le téléchargement.
      </div>
      <div id="error-logo-type" class="file-error-message" *ngIf="fileNotSupported">
        Le type {{ nameFileNotSupported?.length === 1 ? 'du document ' : 'des documents' }} {{ nameFileNotSupported?.join(', ') }} n'est pas
        supporté, formats acceptés ( {{ acceptedFormats[documentConfig.nomDocument] }}), merci de sélectionner un autre fichier.
      </div>
      <div id="error-logo-type" class="file-error-message" *ngIf="connaissanceClientFileNotSupported">
        Le type {{ nameFileNotSupported?.length === 1 ? 'du document ' : 'des documents' }} {{ nameFileNotSupported?.join(', ') }} n'est pas
        supporté, formats supportés (.pdf, .gif, .png, .jpg, .jpeg, .bmp, .tif, .tiff), merci de sélectionner un autre fichier.
      </div>
    </ng-container>
  </div>
</ng-container>
