import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { noop } from 'rxjs';
import { DynamicTableComponent, DynamicTableDataSource } from '../../dynamic-table/dynamic-table.component';
import { Cell, CellNumberValue } from '../../dynamic-table/dynamic-table.model';
import {
  TableDataRecord,
  ValueByPeriod,
  ValueByPeriodBySaisie,
  WarningAmountsByPeriod,
} from '../../../shared/models/donnees-financieres.model';
import { SignalService } from '../../../shared/services/signal.service';
import {
  SaisieLabel_PlanFinancement as SLPF,
  SaisieLabel_PlanFinancement,
  SectionFinanciere_NameSpace_PlanFinancement,
  SectionFinanciere_PlanFinancement as SFPF,
  TOOLTIP_BY_ROW_PLAN_FINANCEMENT,
} from './plan-financement.model';
import { DONNEES_FINANCIERES_COMMON_SAISIE_LABELS as COMMON_SAISIE_LABELS } from '../donnees-financieres-common-labels.model';
import { map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DonneesFinancieresService, YearButtonAction } from '../../../shared/services/_public_services';

@Component({
  selector: 'lib-projet-consortium-df-plan-financement',
  templateUrl: '../../dynamic-table/dynamic-table.component.html',
  styleUrls: ['../../dynamic-table/dynamic-table.component.scss'],
})
export class ProjetConsortiumDfPlanFinancementComponent extends DynamicTableComponent implements OnInit {
  private destroyRef: DestroyRef = inject(DestroyRef);
  @Input() public upTitleRow = false;

  public valueBySaisieBySectionByPeriod: TableDataRecord = {};

  private sumSaisies = (colDef: string, sfpf: SFPF, saisiesToSum: SLPF[]): number => {
    return saisiesToSum.map(saisie => this.valueBySaisieBySectionByPeriod[colDef][sfpf][saisie]).reduce((sum, current) => sum + current, 0);
  };

  public resultsCalculatorBySectionFinanciere: Record<SFPF, (colDef: string) => void> = {
    [SFPF.TOTAL_BESOINS]: (colDef: string) => {
      // Total des besoins
      const idp: number = this.valueBySaisieBySectionByPeriod[colDef][SFPF.TOTAL_BESOINS][SLPF.IDP];
      const bfrdim: number = this.valueBySaisieBySectionByPeriod[colDef][SFPF.TOTAL_BESOINS][SLPF.BFRDIM];
      const totalBesoins: number =
        this.sumSaisies(colDef, SFPF.TOTAL_BESOINS, [SLPF.ILICRP, SLPF.IC, SLPF.BFRAUG, SLPF.RC, SLPF.DIVERS]) + idp - bfrdim;
      this.valueBySaisieBySectionByPeriod[colDef][SFPF.TOTAL_BESOINS][SFPF.TOTAL_BESOINS] = totalBesoins;
      this.updateDataSource(colDef, SFPF.TOTAL_BESOINS, totalBesoins);
      this.emitIdpValue(colDef, idp);
      this.resultsCalculatorBySectionFinanciere[SFPF.TOTAL_RESSOURCES](colDef);
    },

    [SFPF.TOTAL_RESSOURCES]: (colDef: string) => {
      // Total des ressources
      const totalRessources = this.sumSaisies(colDef, SFPF.TOTAL_RESSOURCES, [
        SLPF.AC,
        SLPF.ACC,
        SLPF.CA,
        SLPF.EDN,
        SLPF.ERN,
        SLPF.AE,
        SLPF.AAPP,
      ]);
      this.valueBySaisieBySectionByPeriod[colDef][SFPF.TOTAL_RESSOURCES][SFPF.TOTAL_RESSOURCES] = totalRessources;
      this.updateDataSource(colDef, SFPF.TOTAL_RESSOURCES, totalRessources);

      // Solde de trésorerie
      const totalBesoins = this.valueBySaisieBySectionByPeriod[colDef][SFPF.TOTAL_BESOINS][SFPF.TOTAL_BESOINS] || 0;
      const soldeTresorerie: number = totalRessources - totalBesoins;
      this.valueBySaisieBySectionByPeriod[colDef][SFPF.SOLDE_TRESORERIE] = {
        [SFPF.SOLDE_TRESORERIE]: soldeTresorerie,
      };
      this.updateDataSource(colDef, SFPF.SOLDE_TRESORERIE, soldeTresorerie);

      // Cumul de trésorerie :
      let cumulTresorerie = 0;
      const colIndex: number = this.displayedColumns.findIndex((col: string) => col === colDef);
      if (colIndex === 1) {
        // Si année courante
        cumulTresorerie = this.signalService.getLastBalance() + soldeTresorerie;
      } else {
        // Si année postérieure
        const previousIndex: number = colIndex - 1;
        const previousCol: string = this.displayedColumns[previousIndex];
        const previousCumulTresorerieByPeriode: Record<string, number> | undefined =
          this.valueBySaisieBySectionByPeriod[previousCol][SFPF.CUMUL_TRESORERIE];
        const previousCumulTresorerie: number =
          (previousCumulTresorerieByPeriode && previousCumulTresorerieByPeriode[SFPF.CUMUL_TRESORERIE]) || 0;
        cumulTresorerie = previousCumulTresorerie + soldeTresorerie;
      }
      this.valueBySaisieBySectionByPeriod[colDef][SFPF.CUMUL_TRESORERIE] = {
        [SFPF.CUMUL_TRESORERIE]: cumulTresorerie,
      };
      this.updateDataSource(colDef, SFPF.CUMUL_TRESORERIE, cumulTresorerie);
    },

    [SFPF.SOLDE_TRESORERIE]: () => noop(),
    [SFPF.CUMUL_TRESORERIE]: () => noop(),
  };

  constructor(protected override service: DonneesFinancieresService, private signalService: SignalService) {
    super(service);
    this.readonlyRows = [SaisieLabel_PlanFinancement.CA];
  }

  ngOnInit(): void {
    this.service
      .getYearActionFromComptesResultat()
      .pipe(this.takeUntilDestroyed())
      .subscribe((yearAction: YearButtonAction) => {
        if (yearAction === YearButtonAction.ADD) {
          this.addYear();
        }
        if (yearAction === YearButtonAction.REMOVE) {
          this.removeYear();
        }
      });
  }

  public override initDonneesFinancieres = (): void => {
    this.signalService.setPlanFinancement(this.periodesFinancieres);
    this.isRemoveYearButtonDisabled = this.periodesFinancieres.length <= this.minNbOfColumns;
    this.buildColumns();
    this.rows = this.buildRows(this.upTitleRow, undefined, TOOLTIP_BY_ROW_PLAN_FINANCEMENT);
    this.dataSource = new DynamicTableDataSource(this.rows);
    this.valueBySaisieBySectionByPeriod = this.initDataRecord();
    this.service.completeWarningAmountsByPeriod_PlanFinancement(this.valueBySaisieBySectionByPeriod);
    this.initTableCalculation(this.periodes);

    this.manageSharedSectionsChanges();
  };

  private manageSharedSectionsChanges(): void {
    this.service
      .getAutocalculatedValueBySaisieByPeriodObs()
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map((valueByPeriodBySaisie: ValueByPeriodBySaisie) => valueByPeriodBySaisie[COMMON_SAISIE_LABELS.CA])
      )
      .subscribe((valueByPeriod: ValueByPeriod) => {
        if (valueByPeriod) {
          const periodes: string[] = Object.keys(valueByPeriod);
          periodes.forEach((periode: string) => {
            if (!this.valueBySaisieBySectionByPeriod[periode]) {
              return;
            }
            this.valueBySaisieBySectionByPeriod[periode][SFPF.TOTAL_RESSOURCES][SLPF.CA] = valueByPeriod[periode];
            this.updateDataSource(periode, SLPF.CA, valueByPeriod[periode]);
          });
          this.initTableCalculation(this.periodes);
        }
      });
  }

  private initTableCalculation(periodes: string[]) {
    periodes.forEach((periode: string) => {
      this.resultsCalculatorBySectionFinanciere[SFPF.TOTAL_BESOINS](periode);
    });
  }

  public override onAddYear = (): void => {
    this.service.setYearActionFromPlanFinancement(YearButtonAction.ADD);
    this.service.setYearActionFromPlanFinancement(YearButtonAction.NONE);
    this.addYear();
  };

  private addYear(): void {
    const period: string = this.service.generateAddedYear(this.periodesFinancieres.length);
    this.service.addWarningAmountsByPeriod(period);
    this.periodesFinancieres = this.service.addPlansFinancementPeriodeFinanciere(this.periodesFinancieres, period);
    this.initDonneesFinancieres();
  }

  public override onRemoveYear = (): void => {
    this.service.setYearActionFromPlanFinancement(YearButtonAction.REMOVE);
    this.service.setYearActionFromPlanFinancement(YearButtonAction.NONE);
    this.removeYear();
  };

  private removeYear(): void {
    if (this.periodesFinancieres.length > this.minNbOfColumns) {
      const lastPeriod: string | undefined = this.periodesFinancieres.at(-1)?.periode;
      if (lastPeriod) {
        this.service.clearIdpSaisieWarningAmountsByPeriod(lastPeriod);
      }
      this.periodesFinancieres.pop();
    }
    this.initDonneesFinancieres();
  }

  public override onDataChange = (event: Event, cell: Cell): void => {
    const newValue: string | number | undefined = (event?.target as HTMLInputElement)?.value ?? '';
    const { colDef, sectionLabel, rowDef } = cell;
    const cellNumberValue = new CellNumberValue(newValue).value;
    this.valueBySaisieBySectionByPeriod[colDef][sectionLabel][rowDef] = cellNumberValue;
    this.signalService.updatePlanFinancement(colDef, sectionLabel, rowDef, cellNumberValue);

    const sectionFinanciereLabel: SFPF | undefined = SectionFinanciere_NameSpace_PlanFinancement.toEnum(sectionLabel);
    if (sectionFinanciereLabel) {
      this.resultsCalculatorBySectionFinanciere[sectionFinanciereLabel](colDef);
      // Recalculer tous les cumuls de trésoreries pour les années postérieures
      const colIndex: number = this.displayedColumns.findIndex((col: string) => col === colDef);
      for (let i = colIndex + 1; i < this.nbOfColumns; i++) {
        const postCol: string = this.displayedColumns[i];
        this.resultsCalculatorBySectionFinanciere[SFPF.TOTAL_BESOINS](postCol);
      }
    }
  };

  private emitIdpValue(colDef: string, idp: number): void {
    const warningAmountsByPeriod: WarningAmountsByPeriod = this.service.getWarningAmountsByPeriod();
    warningAmountsByPeriod[colDef].idpSaisie$.next(idp);
    this.service.setWarningAmountsByPeriod(warningAmountsByPeriod);
  }
}
