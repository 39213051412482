import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-grille-impact-info-panel',
  templateUrl: './grille-impact-info-panel.component.html',
  styleUrl: './grille-impact-info-panel.component.scss',
})
export class GrilleImpactInfoPanelComponent {
  @Input() open: boolean;
  @Output() openChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  onClickAccordion() {
    this.openChange.emit(!this.open);
  }
}
