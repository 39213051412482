<button [matMenuTriggerFor]="menu" class="ds-btn ds-btn--secondary">Interne Bpifrance</button>
<mat-menu #menu="matMenu" (closed)="onMenuClosed()">
  <div
    class="date-filters-container"
    (click)="$event.stopPropagation()"
    (keydown.tab)="$event.stopPropagation()"
    (keydown.shift.tab)="$event.stopPropagation()"
  >
    <div class="single-filter-container">
      <span class="ds-input-group__label">Direction Régionale Bpifrance</span>
      <lib-custom-multiselect
        #directionRegionalBpifranceFilterComponent
        (autocompleteEvent)="autocompleteEvent($event, DIRECTION_REGIONALE_BPIFRANCE)"
        (selectedListChange)="selectedListChange($event, DIRECTION_REGIONALE_BPIFRANCE)"
        [(dataList)]="directionRegionaleDataList"
        [selectedList]="directionRegionaleSelectedList"
        placeholder="Direction régionale Bpifrance"
        name="directionRegionalBpifrance"
        class="filter"
        [isLoading]="loadingMap.get(DIRECTION_REGIONALE_BPIFRANCE)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
    </div>
    <div class="single-filter-container">
      <span class="ds-input-group__label">Personne affectée Eligibilité-LCBFT-OAD</span>
      <lib-custom-multiselect
        #eligibiliteLcbftOadFilterComponent
        (autocompleteEvent)="autocompleteEvent($event, PERSONNE_AFFECTEE_ELIGIBILITE_LCBFT_OAD)"
        (selectedListChange)="selectedListChange($event, PERSONNE_AFFECTEE_ELIGIBILITE_LCBFT_OAD)"
        [(dataList)]="affecteeEligibiliteLcbftOadDataList"
        [selectedList]="affecteeEligibiliteLcbftOadSelectedList"
        placeholder="Personne affectée Eligibilité-LCBFT-OAD"
        name="eligibilite-lcbft-oad"
        class="filter"
        [isLoading]="loadingMap.get(PERSONNE_AFFECTEE_ELIGIBILITE_LCBFT_OAD)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
    </div>
    <div class="single-filter-container">
      <span class="ds-input-group__label">Personne affectée à l'instruction </span>
      <lib-custom-multiselect
        #affecteeInstructionFilterComponent
        (autocompleteEvent)="autocompleteEvent($event, PERSONNE_AFFECTEE_INSTRUCTION)"
        (selectedListChange)="selectedListChange($event, PERSONNE_AFFECTEE_INSTRUCTION)"
        [(dataList)]="affecteeInstructionDataList"
        [selectedList]="affecteeInstructionSelectedList"
        placeholder="Personne affectée à l'instruction"
        name="affecteeInstruction"
        class="filter"
        [isLoading]="loadingMap.get(PERSONNE_AFFECTEE_INSTRUCTION)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
    </div>
    <div class="single-filter-container">
      <span class="ds-input-group__label">COEX: Chargé de développement</span>
      <lib-custom-multiselect
        #coexChargeDevFilterComponent
        (autocompleteEvent)="autocompleteEvent($event, COEX_CHARGE_DEVELOPPEMENT)"
        (selectedListChange)="selectedListChange($event, COEX_CHARGE_DEVELOPPEMENT)"
        [(dataList)]="coexChargeDevDataList"
        [selectedList]="coexChargeDevSelectedList"
        placeholder="COEX: Chargé de développement"
        name="coexChargeDev"
        class="filter"
        [isLoading]="loadingMap.get(COEX_CHARGE_DEVELOPPEMENT)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
    </div>
    <div class="single-filter-container">
      <span class="ds-input-group__label">Expertise juridique et/ou PI interne</span>
      <lib-custom-multiselect
        #expertiseJuridiqueFilterComponent
        (autocompleteEvent)="autocompleteEvent($event, EXPERTISE_JURIDIQUE_PI_INTERNE)"
        (selectedListChange)="selectedListChange($event, EXPERTISE_JURIDIQUE_PI_INTERNE)"
        [(dataList)]="expertiseJPIDataList"
        [selectedList]="expertiseJPISelectedList"
        placeholder="Expertise juridique et/ou PI interne"
        name="expertiseJuridique"
        class="filter"
        [isLoading]="loadingMap.get(EXPERTISE_JURIDIQUE_PI_INTERNE)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
    </div>
    <div class="single-filter-container">
      <span class="ds-input-group__label">Référent contractuel</span>
      <lib-custom-multiselect
        #referentContractuelFilterComponent
        (autocompleteEvent)="autocompleteEvent($event, REFERENT_CONTRACTUEL)"
        (selectedListChange)="selectedListChange($event, REFERENT_CONTRACTUEL)"
        [(dataList)]="referentContractuelDataList"
        [selectedList]="referentContractuelSelectedList"
        placeholder="Référent contractuel"
        name="referentContractuel"
        class="filter"
        [isLoading]="loadingMap.get(REFERENT_CONTRACTUEL)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
    </div>
    <div class="single-filter-container">
      <span class="ds-input-group__label">Responsable de suivi</span>
      <lib-custom-multiselect
        #responsableSuiviFilterComponent
        (autocompleteEvent)="autocompleteEvent($event, RESPONSABLE_SUIVI)"
        (selectedListChange)="selectedListChange($event, RESPONSABLE_SUIVI)"
        [(dataList)]="responsableSuiviDataList"
        [selectedList]="responsableSuiviSelectedList"
        placeholder="Responsable de suivi"
        name="responsableSuivi"
        class="filter"
        [isLoading]="loadingMap.get(RESPONSABLE_SUIVI)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
    </div>
    <div class="single-filter-container">
      <label class="ds-input-group__label filter-label">Délai de caducité de la DPM (en mois)</label>
      <lib-date-range-picker #delaiCaduciteDPMComponent></lib-date-range-picker>
    </div>
    <div class="single-filter-container">
      <span class="ds-input-group__label">Numéro CP (Client Partenaire source BCP)</span>
      <lib-custom-multiselect
        #numeroCPComponent
        (autocompleteEvent)="autocompleteEvent($event, NUMERO_CP_CLIENT_PARTENAIRE_SOURCE_BCP)"
        (selectedListChange)="selectedListChange($event, NUMERO_CP_CLIENT_PARTENAIRE_SOURCE_BCP)"
        [(dataList)]="numeroCPDataList"
        [selectedList]="numeroCPSelectedList"
        placeholder="Numéro CP (Client Partenaire source BCP)"
        name="numeroCP"
        class="filter"
        [isLoading]="loadingMap.get(NUMERO_CP_CLIENT_PARTENAIRE_SOURCE_BCP)"
        [autocompleteStart]="1"
      >
      </lib-custom-multiselect>
    </div>
    <div class="single-filter-container">
      <span class="ds-input-group__label filter-label">Instruction affectée autre opérateur</span>
      <lib-ds-multiselect
        *ngIf="showFilters"
        #instructionAffecteeAutreOperateurComponent
        class="filter"
        [dataList]="instructionAffecteeAutreOperateurDataList"
        [selectOptions]="{ placeholder: 'Instruction affectée autre opérateur' }"
        name="instruction_affectee_autre_operateur"
      >
      </lib-ds-multiselect>
    </div>
    <div class="filters-actions">
      <button id="cancel-btn" class="ds-btn ds-btn--tertiary" (click)="resetFilters()">Réinitialiser</button>

      <button id="save-btn" class="ds-btn ds-btn--primary" (click)="applyFilters()">Appliquer</button>
    </div>
  </div>
</mat-menu>
