import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, inject, Inject, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ProjetService } from '@services-candidat/projet.service';
import {
  bicValidator,
  BMP,
  DocumentAsyncUploaderComponent,
  DocumentProjet,
  EnumScope,
  EnumTypeDocument,
  InformationBancaire,
  InformationsBancairesHttpService,
  JPEG,
  JPG,
  PDF,
  PNG,
  Projet,
  ShowToastrService,
  Structure,
  TIF,
  TIFF,
  Utilisateur,
} from '@shared-ui';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export interface ModalData {
  projet: Projet;
  structure: Structure;
  utilisateur: Utilisateur;
  informationBancaire?: InformationBancaire;
}

interface InformationBancaireForm {
  libelle: FormControl<string | null | undefined>;
  iban: FormControl<string | null | undefined>;
  bic: FormControl<string | null | undefined>;
  nomBanque: FormControl<string | null | undefined>;
  nomAgence: FormControl<string | null | undefined>;
  adresseAgence: FormControl<string | null | undefined>;
  commentaire: FormControl<string | null | undefined>;
}

@Component({
  selector: 'pxl-information-bancaire-modal',
  templateUrl: './information-bancaire-modal.component.html',
  styleUrls: ['./information-bancaire-modal.component.scss'],
})
export class InformationBancaireModalComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  readonly ACCEPTED_DOCUMENTS = [PDF, JPG, JPEG, PNG, TIF, TIFF, BMP];

  form: FormGroup<InformationBancaireForm>;

  utilisateur!: Utilisateur;
  projet!: Projet;
  structure!: Structure;
  documents: DocumentProjet[] = [];
  deletedDocuments: DocumentProjet[] = [];

  edited?: InformationBancaire;

  showInvalidIban = false;
  showDocumentRequired = false;
  documentsLoaded = false;

  @ViewChild('documentAsyncUploaderComponent') documentAsyncUploaderComponent: DocumentAsyncUploaderComponent;

  constructor(
    private dialogRef: MatDialogRef<InformationBancaireModalComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: ModalData,
    private projetService: ProjetService,
    private informationBancaireService: InformationsBancairesHttpService,
    private formBuilder: UntypedFormBuilder,
    private showToastrService: ShowToastrService
  ) {
    this.form = this.formBuilder.group({
      libelle: ['', Validators.required],
      iban: ['', Validators.required],
      bic: ['', [Validators.required, bicValidator()]],
      nomBanque: ['', Validators.required],
      nomAgence: ['', Validators.required],
      adresseAgence: ['', Validators.required],
      commentaire: [''],
    });
  }

  ngOnInit(): void {
    this.utilisateur = this.modalData.utilisateur;
    this.projet = this.modalData.projet;
    this.structure = this.modalData.structure;
    this.edited = this.modalData.informationBancaire;

    this.initForm();
    this.loadDocuments();
  }

  initForm(): void {
    if (this.edited) {
      this.form.patchValue(this.edited);
    }
  }

  loadDocuments(): void {
    if (this.edited) {
      this.projetService
        .getDocumentsStructure(this.projet.id, this.structure.id, [EnumTypeDocument.RIB])
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          map(documents => documents.body || [])
        )
        .subscribe(documents => {
          this.documents = documents.filter(doc => doc.informationBancaireId === this.edited!.id);
          this.documentsLoaded = true;
        });
    } else {
      this.documents = [];
      this.documentsLoaded = true;
    }
  }

  save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    if (this.documents.length === 0) {
      this.showDocumentRequired = true;
      return;
    }
    const informationBancaire = {
      ...this.form.value,
      projetId: this.projet.id,
      structureId: this.structure.id,
    } as InformationBancaire;
    if (this.edited) {
      informationBancaire.id = this.edited.id;
    }
    this.informationBancaireService
      .createOrUpdateInformationBancaire(informationBancaire)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(err => this.handleError(err)),
        map(response => response.body!)
      )
      .subscribe(informationBancaire => {
        this.documents.forEach(doc => {
          doc.informationBancaireId = informationBancaire.id;
        });
        this.documentAsyncUploaderComponent
          .saveDocuments()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: () => {
              this.showToastrService.success('Informations bancaires enregistrées avec succès');
              this.form.reset();
              this.dialogRef.close(informationBancaire);
            },
            error: (err: HttpErrorResponse) => this.handleError(err),
          });
      });
  }

  cancel(): void {
    if (this.form.touched) {
      const confirmation = confirm('La saisie ne sera peut-être pas sauvegardées. Etes-vous sûr de vouloir quitter cette page ?');
      if (confirmation) {
        this.dialogRef.close();
      }
    } else {
      this.dialogRef.close();
    }
  }

  getDocFieldsCompleter(): (document: DocumentProjet) => void {
    return (document: DocumentProjet) => {
      document.typeDoc = EnumTypeDocument.RIB;
      document.projetId = this.projet.id;
      document.structureId = this.structure.id;
      document.createur = this.utilisateur.matricule;
      document.scope = EnumScope.STRUCTURE;
    };
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    const invalidIbanCodes = ['2548', '2549'];
    if (invalidIbanCodes.includes(err?.error?.code)) {
      this.showInvalidIban = true;
    } else {
      this.showToastrService.checkCodeError(err?.error);
    }
    return EMPTY;
  }
}
