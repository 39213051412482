"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isByteArray = void 0;
const isByteArray = input => {
  if (input == null || typeof input != 'object') return false;
  return isFinite(input.length) && input.length >= 0;
};
exports.isByteArray = isByteArray;
