import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DisplayColumn } from '@shared-ui';

@Component({
  selector: 'lib-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TableComponent {
  @Input() set dataArray(data: any[]) {
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.data = data;
  }

  @Input() set dataArray2(data: any[]) {
    this.dataSourceExpandableRow = new MatTableDataSource(data);
    this.dataSourceExpandableRow.data = data;
  }
  @Input() set dataArray3(data: any[]) {
    this.dataSourceExpandableRow2 = new MatTableDataSource(data);
    this.dataSourceExpandableRow2.data = data;
  }

  @Input() set columns(cols: DisplayColumn[]) {
    this.cols = cols;
    this.displayedColumns = cols.map(c => c.def);
    this.displayedColumnslength = cols.length;
    this.containsAction = cols.some(c => c.def === 'action');
  }

  @Input() disable: 'DISABLED' | 'VALID' | 'INVALID';

  @Input() set responsive(value: boolean) {
    this.tableReponsive = value;
  }

  @Input() set expandableArSub(value: boolean) {
    this.tableExpandableArSub = value;
  }

  @Input() set expandableTable(value: boolean) {
    this.tableExpandableTable = value;
  }

  @Input() set expandableAnnexe(value: boolean) {
    this.tableExpandableAnnexe = value;
  }

  @Input() set noHeaderDatatable(value: boolean) {
    this.noHeader = value;
  }

  @Input() set regimeChange(value: any) {
    this.allRowsExpanded = false;
    this.expandedElement = [];
  }
  @Input() showTotal = false;

  @Output() keyPress: EventEmitter<any> = new EventEmitter();
  @Output() dataChange: EventEmitter<any> = new EventEmitter();
  @Output() deleteElement: EventEmitter<any> = new EventEmitter();
  @Output() zoneForcage: EventEmitter<any> = new EventEmitter();
  @Output() cellSelectedEvent: EventEmitter<{
    columnName: string;
    index: number;
    editable: boolean;
  }> = new EventEmitter<{ columnName: string; index: number; editable: boolean }>();

  tableReponsive: boolean;
  tableExpandableArSub = false;
  tableExpandableTable = false;
  tableExpandableAnnexe = false;
  noHeader = false;
  cols: DisplayColumn[];
  displayedColumns: Iterable<string>;
  displayedColumnslength: number;
  containsAction: boolean;
  dataSource: MatTableDataSource<any>;
  dataSourceExpandableRow: MatTableDataSource<any>;
  dataSourceExpandableRow2: MatTableDataSource<any>;
  private regex = new RegExp(/^[0-9.,]+$/g);
  private dateRegex = new RegExp(/^[0-9/]+$/g);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Enter'];
  ecAr: number[] = [];
  ecSub: number[] = [];
  arrayAnnexe: any[] = [];
  expandedElement: any[] = [];
  arrayRegime: any[] = [];
  allRowsExpanded = false;
  anneesLength = 0;
  rowEcArSub: Map<string, number[]> = new Map<string, number[]>();

  constructor(private cdref: ChangeDetectorRef) {}

  onKeyPress($event: KeyboardEvent, element: any, isDatePicker = false): void {
    if (this.specialKeys.indexOf($event.key) !== -1) {
      return;
    }
    const current: string = $event.key;
    const next: string = current.concat($event.key);
    if (next && !String(next).match(isDatePicker ? this.dateRegex : this.regex)) {
      $event.preventDefault();
    }
    const data = Object.keys(element).reduce((acc: { [p: string]: any }, key) => {
      acc[key] = element[key].value;
      return acc;
    }, {});
    this.keyPress.emit({ event: $event, element: data });
  }

  onDataChange(element: any): void {
    const data = Object.keys(element).reduce((acc: { [p: string]: any }, key) => {
      acc[key] = element[key].value;
      return acc;
    }, {});
    this.dataChange.emit(data);
  }

  onDeleteElement(i: number): void {
    this.deleteElement.emit(i);
  }

  onZoneForcage(element: any, subAr: string): void {
    const aideEtapesCles = [];

    for (let i = 0; i < element.ecLength.value; i++) {
      aideEtapesCles.push({
        index: i,
        montant: element[`EC_${i}`].value,
        montantSub: element[`ECSUB_${i}`].value,
        montantAr: element[`ECAR_${i}`].value,
      });
    }

    const elementTmp = {
      name: element.name.value,
      idStructure: element.idStructure.value,
      regime: element.regime.value,
      versementAideRegime: {
        aide: {
          montant: element.aide.value,
          montantSub: element.aideSub.value,
          montantAr: element.aideAr.value,
        },
        aideT0: {
          montantSub: element.aideT0Sub.value,
          montantAr: element.aideT0Ar.value,
        },
        aideEtapesCles: aideEtapesCles,
        forcageAr: element.forcageAr,
        forcageSub: element.forcageSub,
      },
    };

    this.zoneForcage.emit({ elementTmp, subAr });
    this.allRowsExpanded = false;
    this.expandedElement = [];
  }

  onDateChange(element: any): void {
    element.editable = false;
    this.onDataChange(element);
  }

  rowEC(row: any) {
    this.ecAr = [];
    this.ecSub = [];

    if (!Array.isArray(row)) {
      row = new Array(row);
    }

    row.forEach((element: any) => {
      for (let i = 0; i < element.ecLength.value + 1; i++) {
        if (element[`ECAR_${i}`]) {
          this.ecAr.push(element[`ECAR_${i}`].value);
        }
        if (element[`ECSUB_${i}`]) {
          this.ecSub.push(element[`ECSUB_${i}`].value);
        }
      }

      if (element.regime) {
        this.rowEcArSub.set(element.idStructure.value + '_' + element.regime.value + '_ecAr', this.ecAr);
        this.rowEcArSub.set(element.idStructure.value + '_' + element.regime.value + '_ecSub', this.ecSub);
      } else {
        this.rowEcArSub.set(element.idStructure.value + '_ecAr', this.ecAr);
        this.rowEcArSub.set(element.idStructure.value + '_ecSub', this.ecSub);
      }

      this.ecAr = [];
      this.ecSub = [];
    });
  }

  rowAnnexe() {
    this.arrayAnnexe = [];

    this.dataSourceExpandableRow2.data.map((element: any) => {
      this.arrayAnnexe.push(element.arrayAnnexe);
      this.arrayAnnexe = this.arrayAnnexe.flat();
    });
  }

  rowRegime() {
    this.arrayRegime = [];
    this.anneesLength = 0;

    this.dataSourceExpandableRow.data.map((element: any) => {
      element.map((element2: any) => {
        this.arrayRegime.push(element2.arrayRegime);
      });
    });

    this.anneesLength = Math.max(...this.arrayRegime.map((element: any) => element.regime.remboursementAr.montantsAnnees.length));

    this.arrayRegime.forEach((element: any) => {
      const diff = this.anneesLength - element.regime.remboursementAr.montantsAnnees.length;
      for (let i = 0; i < diff; i++) {
        element.regime.remboursementAr.montantsAnnees.push({ montant: '' });
      }
    });
  }

  getTotalCost(column: DisplayColumn): number {
    if (column.total) {
      return this.getColumnTotal(total => +total[column.def]?.value);
    } else {
      return 0;
    }
  }

  private getColumnTotal(p: (total: any) => number): number {
    return this.dataSource.filteredData.map(p).reduce((acc, value) => acc + value, 0);
  }

  cellSelected(col: DisplayColumn, index: number) {
    if (col.editable) {
      this.cellSelectedEvent.emit({
        columnName: col?.def ? col.def : '',
        index,
        editable: col?.editable ? col.editable : false,
      });
    }
  }

  getBackgroundColor(element: any, col: any): string {
    if (element[col.def]?.error) {
      return '#e5ccc7';
    }
    if (element[col.def]?.highlight) {
      return '#ffcd00';
    }
    return '';
  }

  getBackgroundColorExpand(element: any, col: string): string {
    if (element[col]?.error) {
      return '#e5ccc7';
    }
    return '';
  }

  public toggleAllRowsExpand() {
    this.allRowsExpanded = !this.allRowsExpanded;
    if (this.allRowsExpanded) {
      if (this.tableExpandableArSub) {
        this.rowEC(this.dataSource.data);
      } else if (this.tableExpandableTable) {
        this.rowRegime();
      } else {
        this.rowAnnexe();
      }
      this.expandedElement = [...this.dataSource.data];
    } else {
      this.expandedElement = [];
    }
  }

  expandOneRow(row: any) {
    const isExpanded = row.open ? 'collapsed' : this.isExpandedOrCollapsed(row);
    if (row?.open) {
      row.open = false;
    }
    if (isExpanded === 'collapsed') {
      this.expandedElement.push(row);
    } else {
      const index = this.expandedElement.findIndex((x: any) => {
        if (this.tableExpandableArSub && row.regime?.value) {
          return x.name.value === row.name.value && x.regime?.value === row.regime.value;
        } else {
          return x.name.value === row.name.value;
        }
      });
      if (index !== -1) {
        this.expandedElement.splice(index, 1);
      }
    }
  }

  isExpandedOrCollapsed(row: any) {
    if (row?.open) {
      this.rowEC(row);
      this.expandedElement.push(row);
      this.dataSource.data.find(
        element => element.idStructure.value === row.idStructure.value && element.regime.value === row.regime.value
      ).open = false;
      this.cdref.detectChanges();
      return 'expanded';
    }

    return this.expandedElement.some((x: any) => {
      if (this.tableExpandableArSub && row.regime?.value) {
        return x.name?.value === row.name?.value && x.regime?.value === row.regime?.value;
      } else {
        return x.name?.value === row.name?.value;
      }
    })
      ? 'expanded'
      : 'collapsed';
  }
}

export class TableCell {
  value: string | number;
  editable?: boolean;
  error?: boolean = false;
  selected?: boolean = false;
  highlight?: boolean = false;
  metaData?: any;
  typeField: number | string = 'string';
  constructor(
    value: string | number | undefined,
    editable: boolean = false,
    error: boolean = false,
    selected: boolean = false,
    highlight?: boolean,
    metaData?: any,
    typeField: number | string = 'string'
  ) {
    if (value || value === 0) {
      this.value = value;
    }
    this.editable = editable;
    this.error = error;
    this.selected = selected;
    this.highlight = highlight;
    this.metaData = metaData;
    this.typeField = typeField;
  }
}
