import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Inject, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  Aap,
  ConfirmModalComponent,
  DocumentHelper,
  DocumentProjet,
  EnumScanDocument,
  EnumScope,
  Environment,
  FicheCom,
  FicheComHelperService,
  FormComponentInterface,
  LOGO_FILE_SIZE,
  LogoEntrepriseFicheCom,
  Projet,
  DocumentService,
  ShowToastrService,
  SignedUrlResponseModel,
  Structure,
  UploadDocumentHttpService,
  Utilisateur,
  resizeImage,
} from '@shared-ui';
import { Observable, switchMap, take, throwError } from 'rxjs';

@Component({
  selector: 'lib-fiche-com-logos-structures',
  templateUrl: './fiche-com-logos-structures.component.html',
  styleUrls: ['./fiche-com-logos-structures.component.scss'],
})
export class FicheComLogosStructuresComponent implements OnInit, FormComponentInterface {
  formSubmitted = false;
  savedLogosStructures: LogoEntrepriseFicheCom[] = [];
  fileNotSupported: boolean[] = [];
  fileTooBig: boolean[] = [];
  @Input() aap: Aap;
  @Input() projet: Projet;
  @Input() logosStructuresForm: FormGroup;
  @Input() ficheCom: FicheCom;
  @Input() canUserWrite: boolean;
  @Input() utilisateur: Utilisateur;
  @Input() listLogoProjet: DocumentProjet[] = [];
  @Input() isValideFicheCom = false;
  @Output() saveFicheCom = new EventEmitter();

  fileName = '';
  fileToUpload: File;

  docLogosPublicBaseUrl: string;

  structureList: Structure[];
  imgLogoStructureList: DocumentProjet[] = [];

  readonly SAFE = (EnumScanDocument as any)[EnumScanDocument.SAFE.toString()];
  readonly UNSAFE = (EnumScanDocument as any)[EnumScanDocument.UNSAFE.toString()];

  imagePath: string[] = [];
  logoChange = false;
  imgWidth = 113;
  imgHeight = 37;
  oneMo = 1048576;
  ratio: boolean[] = [];

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return !(this.logosStructuresForm.dirty || this.logoChange);
  }

  constructor(
    @Inject('environment') private environment: Environment,
    public matDialog: MatDialog,
    private uploadDocumentService: UploadDocumentHttpService,
    private projetService: DocumentService,
    private showToastrService: ShowToastrService,
    public resizeImage: resizeImage
  ) {}

  ngOnInit(): void {
    this.docLogosPublicBaseUrl = this.environment.docLogosPublicBaseUrl;
    this.setForm();
  }

  showValidIcon(): boolean {
    return (
      this.ficheCom?.logoEntreprises?.length > 0 &&
      this.ficheCom?.logoEntreprises?.length === this.logosStructuresArray.length &&
      this.ficheCom?.logoEntreprises?.filter(logo => {
        return logo?.pathS3Image === null && logo?.structureId === undefined;
      }).length === 0
    );
  }

  setImagePath(): void {
    this.imagePath = [];
    this.structureList.forEach((structure: Structure, index: number) => {
      this.imagePath.push(
        this.docLogosPublicBaseUrl +
          FicheComHelperService.getDocumentPath(this.projet.id, structure.id, this.imgLogoStructureList[index]?.nom)
      );
      this.ratio.push(false);
    });
  }

  get logosStructuresArray(): FormArray {
    return this.logosStructuresForm.get('logosStructures') as FormArray;
  }

  private setForm(): void {
    if (this.initFormbuilderStructure().length === 0) return;

    this.logosStructuresForm = new FormGroup({
      logosStructures: new FormArray(this.initFormbuilderStructure()),
    });

    this.feedLogosStructuresForm();
  }

  initFormbuilderStructure(): FormArray[] {
    const logosStructure: any[] = [];

    this.structureList = this.projet?.structures.filter(
      structure => !structure.closed && structure.role !== 'CHEF_DE_FILE' && structure.role !== 'MANDATAIRE'
    );

    this.structureList?.forEach((structure: Structure, index: number) => {
      logosStructure.push(
        new FormGroup({
          logoStructure: new FormControl({ value: '', disabled: !this.canUserWrite }, Validators.required),
        })
      );

      this.listLogoProjet?.forEach((doc: DocumentProjet) => {
        if (doc.scope === EnumScope.STRUCTURE && doc.structureId === structure.id) {
          this.imgLogoStructureList[index] = doc;
        }
      });

      if (!this.imgLogoStructureList[index]?.id) {
        this.imgLogoStructureList[index] = new DocumentProjet();
      }

      this.fileNotSupported.push(false);
      this.fileTooBig.push(false);
    });

    this.setImagePath();
    return logosStructure;
  }

  feedLogosStructuresForm(): void {
    this.structureList.forEach((structure: Structure, index: number) => {
      this.logosStructuresForm.controls['logosStructures']
        .get(index.toString())
        ?.get('logoStructure')
        ?.setValue(this.imgLogoStructureList[index].nom);
    });

    this.savedLogosStructures = this.logosStructuresForm.getRawValue()?.logosStructures;
  }

  onLogosStructuresSubmitted(): void {
    this.formSubmitted = true;
    this.logosStructuresForm.markAllAsTouched();

    if (this.logosStructuresForm.valid) {
      this.savedLogosStructures = this.logosStructuresForm.getRawValue()?.logosStructures;
      this.ficheCom.logoEntreprises = [];

      this.savedLogosStructures.forEach((logoStructure: LogoEntrepriseFicheCom, index: number) => {
        this.ficheCom.logoEntreprises.push({
          structureId: this.structureList[index].id,
          pathS3Image: {
            bucketName: 'logo_bucket',
            httpUrl:
              this.environment.docLogosPublicBaseUrl +
              FicheComHelperService.getDocumentPath(this.projet.id, this.structureList[index].id, logoStructure.logoStructure),
          },
        });
      });

      this.saveFicheCom.emit(this.ficheCom);
      this.logosStructuresForm.markAsPristine();
      this.logoChange = false;
    }
  }

  cancel(): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        title: 'Annuler les modifications en cours',
        description: `<p>En confirmant l'action, Vous allez perdre vos modification en cours.<br>
        Confirmez-vous l’action ?</p>`,
        textGoButton: 'Confirmer',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        if (this.savedLogosStructures != null) {
          this.logosStructuresForm.setValue({ logosStructures: this.savedLogosStructures });
          this.logosStructuresForm.markAsPristine();
          return;
        }
        this.logosStructuresForm.reset({
          description: '',
        });
      }
    });
  }

  onUpload(target: EventTarget | null | undefined, structure: Structure, index: number): void {
    const files = (<HTMLInputElement>target)?.files;

    this.fileTooBig[index] = false;
    this.fileNotSupported[index] = false;
    this.fileToUpload = files!.item(0)!;

    if (this.fileToUpload.size >= LOGO_FILE_SIZE * this.oneMo) {
      this.fileTooBig[index] = true;
    } else if (this.fileToUpload && this.fileToUpload.type !== 'image/jpeg' && this.fileToUpload.type !== 'image/png') {
      this.fileNotSupported[index] = true;
    } else {
      this.resizeImage
        .processImage(this.fileToUpload, this.imgWidth, this.imgHeight)
        .pipe(
          switchMap((file: File | null) => {
            if (file) {
              this.fileToUpload = new File([file], file.name, { type: file.type });
              Object.assign(this.imgLogoStructureList[index], {
                nom: this.fileToUpload.name,
                projetId: this.projet.id,
                scope: EnumScope.STRUCTURE,
                typeDoc: 'MEDIA_IMAGE',
                structureId: structure.id,
              });

              if (resizeImage.getWidth() < this.imgWidth / 2 || resizeImage.getHeight() < this.imgHeight * 2) {
                this.ratio[index] = true;
              } else {
                this.ratio[index] = false;
              }

              return this.projetService.createDocument(this.imgLogoStructureList[index]);
            } else {
              return throwError(() => new Error('Failed to process image.'));
            }
          }),
          switchMap((rep: HttpResponse<DocumentProjet>) => {
            this.imgLogoStructureList[index] = rep.body!;
            const path = FicheComHelperService.getDocumentPath(this.projet.id, structure.id);

            return this.uploadDocumentService.getValueForLogoUploadFicheDeCom(
              path,
              this.fileToUpload.name,
              this.imgLogoStructureList[index].id
            );
          }),
          switchMap((response: HttpResponse<{ url: string }>) => {
            const url = response.body!.url!;
            return this.uploadDocumentService.uploadLogo(url, this.fileToUpload);
          })
        )
        .subscribe({
          next: () => {
            this.logosStructuresForm.controls['logosStructures']
              .get(index.toString())
              ?.get('logoStructure')
              ?.setValue(this.fileToUpload?.name);
            this.setImagePath();
            this.logoChange = true;
          },
        });
    }
  }

  getNomCreateurDocument(document: DocumentProjet): string {
    return DocumentHelper.getNomCreateurDocument(document);
  }

  /*
   * Vérifie si le document uploadé a passé le test antivirus
   * */
  isScanedDocument(document: DocumentProjet): boolean {
    if (document?.scan === this.SAFE || document?.scan === this.UNSAFE) {
      return true;
    }
    return false;
  }

  /*
   * Download a document
   * */
  downloadDocument(document: DocumentProjet): void {
    const path = FicheComHelperService.getDocumentPath(this.projet.id, document.structureId, document.nom);
    this.uploadDocumentService
      .getValueForDocLogoDownload(path)
      .pipe(take(1))
      .subscribe({
        next: (response: HttpResponse<SignedUrlResponseModel>) => {
          const url = response.body?.url;
          window.open(url);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  /*
   * Affiche la modale pour supprimer un document
   * */

  onDeleteDocument(document: DocumentProjet, index: number): void {
    const dialogRef = this.matDialog.open(ConfirmModalComponent, {
      data: {
        description: `<p>Êtes-vous sûr de vouloir supprimer ce fichier.</p>
                          <p>Cette action est irréversible. </p>`,
        textGoButton: 'Oui',
        textReturnButton: 'Non',
        icon: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (!result) {
        return;
      }
      this.projetService.deleteDocument(this.projet.id, document.id).subscribe({
        next: () => {
          this.showToastrService.success('Le fichier a bien été supprimé');
          this.imgLogoStructureList[index] = new DocumentProjet();
          this.logosStructuresForm.controls['logosStructures'].get(index.toString())?.get('logoStructure')?.setValue(null);
          this.logosStructuresForm.controls['logosStructures'].get(index.toString())?.get('logoStructure')?.markAsTouched();
          this.setImagePath();
          if (document.scan === (EnumScanDocument as any)[EnumScanDocument.UNSAFE.toString()]) {
            return;
          }
          const path = FicheComHelperService.getDocumentPath(this.projet.id, document.structureId, document.nom);
          this.uploadDocumentService.deleteDocLogo(path).subscribe({
            next: () => {
              this.ficheCom.logoEntreprises = this.ficheCom.logoEntreprises.filter(logo => logo.structureId !== document.structureId);
              this.saveFicheCom.emit(this.ficheCom);
              this.logoChange = true;
            },
            error: (err: HttpErrorResponse) => {
              this.showToastrService.checkCodeError(err?.error);
            },
          });
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    });
  }

  isFormDirty(): boolean {
    return this.logosStructuresForm.dirty;
  }
}
