import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DemandeComplement, Projet } from '../../../shared/models/_public_models';
import { EnumScope } from '../../../shared/enums/enum.scope';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ShowToastrService } from '../../../shared/services/show-toastr.service';
import { DemandeModificationHttpService } from '../../../shared/services/demande-modification.http.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EnumStatutDemandeDocumentComplementaireTech } from '../../../shared/enums/enum.statutDemandeDocumentComplementaire';

@Component({
  selector: 'lib-demande-document-complementaire-candidat',
  templateUrl: './demande-document-complementaire-candidat.component.html',
  styleUrls: ['./demande-document-complementaire-candidat.component.scss'],
})
export class DemandeDocumentComplementaireCandidatComponent implements OnInit {
  @Input() demande: DemandeComplement;
  @Input() canWrite: boolean;
  @Input() projet: Projet;
  @Input() etapeCleTitle: string;
  @Output() demandeComplementaireSent = new EventEmitter<boolean>();

  demandeComplementaireForm: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public showToastrService: ShowToastrService,
    public httpService: DemandeModificationHttpService
  ) {}

  ngOnInit(): void {
    this.demandeComplementaireForm = this.formBuilder.group({
      description: [null],
    });
  }

  validateDocumentComplementaire(): void {
    if (this.demandeComplementaireForm.valid) {
      const demande: DemandeComplement = new DemandeComplement();
      demande.commentaire = this.demandeComplementaireForm.controls['description'].value;
      demande.statut = EnumStatutDemandeDocumentComplementaireTech.RENVOYEE;
      this.httpService.updateDocumentComplementaire(this.demande.id, demande).subscribe({
        next: () => {
          this.showToastrService.success('La correction a été envoyée.');
          this.demandeComplementaireSent.emit(true);
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
    }
  }

  protected readonly EnumScope = EnumScope;
}
