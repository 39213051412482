import { enumKeys } from '../../utils/enum-utils';

export enum EnumTypeNotificationTags {
  DPP = 'DPP',
  DFI_RS = 'DFI_RS',
  DFI_G = 'DFI_G',
  DOSPI = 'DOSPI',
  DJPI = 'DJPI',
}

export namespace EnumTypeNotificationTags {
  export function toString(notif: EnumTypeNotificationTags): string {
    switch (notif) {
      case EnumTypeNotificationTags.DPP:
        return 'DPP';
      case EnumTypeNotificationTags.DFI_RS:
        return 'DFI-RS';
      case EnumTypeNotificationTags.DFI_G:
        return 'DFI-G';
      case EnumTypeNotificationTags.DOSPI:
        return 'DOSPI';
      case EnumTypeNotificationTags.DJPI:
        return 'DJPI';
    }
  }

  export function all(): EnumTypeNotificationTags[] {
    return enumKeys(EnumTypeNotificationTags) as unknown as EnumTypeNotificationTags[];
  }
}
