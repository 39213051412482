import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments-candidat/environment';
import { DemandeComplements, EnumScope } from "@shared-ui";
import { Observable } from 'rxjs';

@Injectable()
export class DemandeDeDocumentComplementaireService {
  demandeComplementsUrl = environment.apiUrl + 'demande-complements';

  constructor(private httpClient: HttpClient) {}

  getDemandesComplements(
    idProjet: string,
    idStructure?: string,
    enSuivi?: boolean,
    etapeCleId?: string
  ): Observable<HttpResponse<DemandeComplements[]>> {
    const scope = idStructure ? EnumScope.STRUCTURE : EnumScope.PROJET;
    let queryParams = '?projetId=' + idProjet
    queryParams += idStructure ? '&structureId=' + idStructure : '';
    queryParams += '&scope=' + scope;
    if (enSuivi != null) {
      queryParams += '&enSuivi=' + enSuivi;
      if (etapeCleId != null) {
        queryParams += '&etapeCleId=' + etapeCleId;
      }
    }
    return this.httpClient.get<DemandeComplements[]>(this.demandeComplementsUrl + queryParams, { observe: 'response' });
  }
}
