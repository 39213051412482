<lib-years-buttons
  *ngIf="displayYearsButtons"
  [isRemoveYearButtonDisabled]="isRemoveYearButtonDisabled"
  (updateCurrentFinancialYearEmitter)="onUpdateCurrentFinancialYear()"
  (addYearEmitter)="onAddYear()"
  (removeYearEmitter)="onRemoveYear()"
>
</lib-years-buttons>
<div class="table-wrapper">
  <table mat-table multiTemplateDataRows [dataSource]="dataSource" class="table-responsive mat-elevation-z0">
    <ng-container *ngFor="let col of columns">
      <ng-container [matColumnDef]="col.def" [sticky]="col.sticky || false">
        <th
          mat-header-cell
          *matHeaderCellDef
          class="column-key"
          [ngStyle]="{ 'max-width': col.width, 'min-width': col.width, 'text-align': 'center' }"
          class="fw-bold"
        >
          {{ col.label }}
        </th>
        <td
          mat-cell
          *matCellDef="let element; let i = dataIndex"
          [attr.colspan]="element.rowType === RowType.TITLE ? nbOfColumns : 1"
          [ngStyle]="{ 'max-width': col.width, 'min-width': col.width }"
          [matTooltip]="element[col.def]?.tooltip"
          matTooltipClass="tooltip"
          [matTooltipPosition]="'above'"
        >
          <div>
            <input
              *ngIf="!isEvaluateur && element[col.def]?.isEditable; else notEditable"
              libNumericNegatifAndDotOnly
              [disabled]="isDisabled"
              [id]="col.def + element.headerRowLabel"
              [name]="col.def"
              [placeholder]="col.placeholder || ''"
              [value]="element[col.def]?.value ?? ''"
              (change)="onDataChange($event, element[col.def])"
              type="text"
              class="input-cell ds-input-group__input"
              [ngClass]="{
                'warning-background':
                  isComptesResultats &&
                  element.sectionLabel === IsolatedEditableRow_ComptesResultats.MP &&
                  (isWarningTriggered(col.def) | async)
              }"
            />
          </div>
          <ng-template #notEditable>
            <div [ngClass]="element[col.def]?.isRowHeader ? 'not-editable' : 'not-editable-center'">
              <span [style]="element.style">
                {{ element[col.def]?.value ?? '' }}
              </span>
              <lib-pxl-icon *ngIf="displayTooltipIcon(element[col.def])" class="icon" icon="icon-action-info"></lib-pxl-icon>
            </div>
          </ng-template>
        </td>
      </ng-container>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns; let i = dataIndex"></tr>
  </table>
</div>
