import { DemandeInterventionPI, DepensesProjetConfig } from '@shared-ui';
import { EnumTypePartenaire } from '../enums/enum.partenaireType';
import { EnumProjetStatut } from '../enums/enum.projetStatut';
import { EnumProjetTag } from '../enums/enum.projetTag';
import { ProjetInterface } from '../interfaces/projet.interface';
import { Aap } from './aap.model';
import { AapOrigine } from './aapOrigine.model';
import { Budget } from './budget.model';
import { CentreMesure } from './centreMesure.model';
import { ProjetComiteSynthese } from './comite/comite-synthese.model';
import { ContractualisationProjet } from './contractualisation-projet.model';
import { DecisionPremierMinistre } from './decision-premier-ministre.model';
import { DocumentProjet } from './documentProjet.model';
import { Domaine } from './domaine.model';
import { Eligibilite } from './eligibilite.model';
import { Enquete } from './enquete.model';
import { EvaluateurAffectation } from './evaluateur-affectation.model';
import { FicheCom } from './fiche-com.model';
import { GrilleImpactProjet } from './grille-impact-projet.model';
import { InstructeurAffectation } from './instructeurAffectation.model';
import { InstructionProjet } from './instruction-projet.model';
import { InterventionPI } from './intervention-pi.model';
import { Pole } from './pole.model';
import { ProjetEtape } from './projetEtape.model';
import { Secteur } from './secteur.model';
import { Structure } from './structure.model';
import { Thematique } from './thematique.model';
import { Verrouillage } from './verrouillage.model';

export class Projet implements ProjetInterface {
  id: string;
  identifiantPXL: string;
  nom: string;
  acronyme: string;
  description: string;
  budget: Budget = new Budget();
  aap: Aap = new Aap();
  etapes: ProjetEtape[];
  document: DocumentProjet = new DocumentProjet();
  statut: EnumProjetStatut;
  structures: Structure[] = [];
  dateCreation: Date;
  dateModification: Date;
  dateEvaluation: Date;
  dateAudition: Date;
  relevePreDepot: string;
  releveDepot: string;
  structurePilote: string;
  poles: Pole[] = [];
  partenaireType: EnumTypePartenaire;
  secteurs: Array<Secteur>;
  domaines: Domaine[];
  nouvelleStructure?: Structure;
  thematique: Thematique = new Thematique();
  tag: EnumProjetTag;
  phaseEvaluation = false;
  phaseAudition = false;
  enquete: Enquete = new Enquete();
  instructeurs: InstructeurAffectation[] = [];
  verrouillage: Verrouillage = new Verrouillage();
  eligibilite: Eligibilite = new Eligibilite();
  contractualisation?: ContractualisationProjet;
  grilleImpacts: GrilleImpactProjet = new GrilleImpactProjet();
  instruction: InstructionProjet = new InstructionProjet();
  centreMesure: CentreMesure;
  synthesesComites: ProjetComiteSynthese[];
  decisionPremierMinistre: DecisionPremierMinistre;
  aapsOrigines: AapOrigine[] = [];
  ficheCom: FicheCom = new FicheCom();
  numeroCollaboratif: string;
  evaluateurs: EvaluateurAffectation[] = [];
  demandeInterventionPI: DemandeInterventionPI;
  interventionPI: InterventionPI;
  depensesProjetConfig: DepensesProjetConfig;
  legacy: boolean;
  inactif: boolean;
}
