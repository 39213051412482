<div class="form-step__content">
  <form [formGroup]="signataireForm" class="signataire" id="signataire">
    <!-- Civilité / Genre  -->
    <div
      [class.ds-input-group--error]="signataireForm.controls['civilite'].invalid && signataireForm.controls['civilite'].touched"
      class="ds-input-group genre_selection_option_overwrite genre"
      id="signataire-genre"
    >
      <label class="ds-input-group__label" for="civilite-mr"
        >Civilité
        <lib-asterisk></lib-asterisk>
      </label>
      <div class="ds-input-group__input div_genre_checkboxs">
        <label>
          <input formControlName="civilite" name="civilite" [value]="EnumGenre.MONSIEUR" class="btnlike" id="civilite-mr" type="radio" />
          <span
            [ngClass]="{ genre_invalid: signataireForm.controls['civilite'].invalid && signataireForm.controls['civilite'].touched }"
            class="unselectable"
            >{{ EnumGenre.toString(EnumGenre.MONSIEUR) }}</span
          >
        </label>
        <label>
          <input formControlName="civilite" name="civilite" [value]="EnumGenre.MADAME" class="btnlike" id="civilite-mme" type="radio" />
          <span
            [ngClass]="{ genre_invalid: signataireForm.controls['civilite'].invalid && signataireForm.controls['civilite'].touched }"
            class="unselectable"
            >{{ EnumGenre.toString(EnumGenre.MADAME) }}</span
          >
        </label>
      </div>

      <!-- Icône et message d'erreur -->
      <ng-container *ngIf="signataireForm.controls['civilite'].invalid && signataireForm.controls['civilite'].touched" id="error-raison">
        <div class="ds-input-group__feedback invalid_msg_overwrite">Merci de bien vouloir renseigner une civilité valide.</div>
      </ng-container>
    </div>

    <!-- Fonction  -->
    <div
      [class.ds-input-group--error]="!signataireForm.controls['fonction'].valid && signataireForm.controls['fonction'].touched"
      class="ds-input-group fonction"
      id="signataire-fonction"
    >
      <label class="ds-input-group__label" for="fonction"
        >Fonction
        <lib-asterisk></lib-asterisk>
      </label>
      <span class="ds-input-group__input">
        <input
          class="input_fonction ds-input-group__input"
          formControlName="fonction"
          id="fonction"
          maxlength="50"
          name="fonction"
          placeholder="Votre fonction"
          type="text"
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container *ngIf="!signataireForm.controls['fonction'].valid && signataireForm.controls['fonction'].touched" id="error-fonction">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner une fonction valide</div>
      </ng-container>
    </div>

    <!-- Nom -->
    <div
      [class.ds-input-group--error]="!signataireForm.controls['nom'].valid && signataireForm.controls['nom'].touched"
      class="ds-input-group nom"
      id="signataire-nom"
    >
      <label class="ds-input-group__label" for="nom"
        >Nom
        <lib-asterisk></lib-asterisk>
      </label>
      <span class="ds-input-group__input">
        <input
          class="ds-input-group__input"
          formControlName="nom"
          id="nom"
          maxlength="30"
          name="nom"
          pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
          placeholder="Votre nom"
          type="text"
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container *ngIf="!signataireForm.controls['nom'].valid && signataireForm.controls['nom'].touched" id="error-nom">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un nom valide</div>
      </ng-container>
    </div>

    <!-- Téléphone -->
    <div
      [class.ds-input-group--error]="!signataireForm.controls['telephone'].valid && signataireForm.controls['telephone'].touched"
      class="ds-input-group ds-input-group--telephone telephone"
      id="signataire-telephone"
    >
      <lib-telephone-input #signataireTelephone [form]="signataireForm" [id]="'telephone'" [required]="true"></lib-telephone-input>
    </div>

    <!-- Prénom  -->
    <div
      [class.ds-input-group--error]="!signataireForm.controls['prenom'].valid && signataireForm.controls['prenom'].touched"
      class="ds-input-group prenom"
      id="signataire-prenom"
    >
      <label class="ds-input-group__label" for="prenom"
        >Prénom
        <lib-asterisk></lib-asterisk>
      </label>
      <span class="ds-input-group__input">
        <input
          class="input_prenom ds-input-group__input"
          formControlName="prenom"
          id="prenom"
          maxlength="30"
          name="prenom"
          pattern="[a-zA-ZÀ-ÖØ-Ýà-öø-ÿ\s-]+"
          placeholder="Votre prénom"
          type="text"
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container *ngIf="!signataireForm.controls['prenom'].valid && signataireForm.controls['prenom'].touched" id="error-prenom">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un prénom valide</div>
      </ng-container>
    </div>

    <!-- mail -->
    <div
      [class.ds-input-group--error]="!signataireForm.controls['email'].valid && signataireForm.controls['email'].touched"
      class="ds-input-group email"
      id="signataire-email"
    >
      <label class="ds-input-group__label" for="email"
        >Mail
        <lib-asterisk></lib-asterisk>
      </label>
      <span class="ds-input-group__input">
        <input
          class="input_email ds-input-group__input"
          formControlName="email"
          id="email"
          maxlength="80"
          name="email"
          pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
          placeholder="signataire@candidat.com"
          type="text"
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container *ngIf="!signataireForm.controls['email'].valid && signataireForm.controls['email'].touched" id="error-email">
        <div class="ds-input-group__feedback">Merci de bien vouloir renseigner un mail valide</div>
      </ng-container>
    </div>

    <div
      id="signataire-representantLegale"
      class="ds-input-group representantLegale-container representantLegale"
      [ngClass]="{
        'ds-input-group--error': !signataireForm.controls['representantLegal'].valid && signataireForm.controls['representantLegal'].touched
      }"
    >
      <div>
        <label class="fw-bold">Est-ce que le signataire déclaré est un.e représentant.e légal.e de la structure ?</label>
        <lib-asterisk></lib-asterisk>
        <!-- Icône et message d'erreur -->
        <ng-container
          *ngIf="!signataireForm.controls['representantLegal'].valid && signataireForm.controls['representantLegal'].touched"
          id="error-representantLegal"
        >
          <div class="ds-input-group__feedback">Merci de bien vouloir répondre par oui ou non.</div>
        </ng-container>
      </div>
      <div class="radio-btn">
        <label class="ds-radio-classic" for="representantLegal-true">
          <input
            type="radio"
            class="ds-radio__input"
            id="representantLegal-true"
            name="representantLegal"
            formControlName="representantLegal"
            [value]="true"
          />
          <span class="ds-radio__label"><span>Oui</span></span>
        </label>
        <label class="ds-radio-classic" for="representantLegal-false">
          <input
            type="radio"
            class="ds-radio__input"
            id="representantLegal-false"
            name="representantLegal"
            formControlName="representantLegal"
            [value]="false"
          />
          <span class="ds-radio__label"><span>Non</span></span>
        </label>
      </div>
    </div>
    <div id="informations" class="informations">
      <lib-card-info
        *ngIf="signataireForm.controls['representantLegal'].value !== null"
        color="yellow"
        icon="icon-info-warning"
        [message]="
          readOnly
            ? WARNING_MESSAGE_DOCUMENTS_STRUCTURE
            : signataireForm.controls['representantLegal'].value
            ? WARNING_MESSAGE_PIECE_IDENTITE
            : WARNING_MESSAGE_DELEGATION_ET_PIECE_IDENTITE
        "
      ></lib-card-info>
    </div>
    <div
      *ngIf="isSiteAdmin && (!signataire || !signataire.isFromFicheDemandeAide)"
      id="signataire-statut"
      class="ds-input-group statut-container statut"
      [ngClass]="{ 'ds-input-group--error': !signataireForm.controls['statut'].valid && signataireForm.controls['statut'].touched }"
    >
      <div>
        <label class="fw-bold">Souhaitez-vous valider ce signataire?</label>
        <lib-asterisk></lib-asterisk>
        <!-- Icône et message d'erreur -->
        <ng-container *ngIf="!signataireForm.controls['statut'].valid && signataireForm.controls['statut'].touched" id="error-statut">
          <div class="ds-input-group__feedback">Merci de bien vouloir répondre par oui ou non.</div>
        </ng-container>
      </div>
      <div class="radio-btn">
        <label class="ds-radio-classic" for="statut-valide">
          <input
            type="radio"
            class="ds-radio__input"
            id="statut-valide"
            name="statut"
            formControlName="statut"
            [value]="EnumStatutSignataire.VALIDE"
          />
          <span class="ds-radio__label"><span>Oui</span></span>
        </label>
        <label class="ds-radio-classic" for="statut-invalide">
          <input
            type="radio"
            class="ds-radio__input"
            id="statut-invalide"
            name="statut"
            formControlName="statut"
            [value]="EnumStatutSignataire.INVALIDE"
          />
          <span class="ds-radio__label"><span>Non</span></span>
        </label>
      </div>
    </div>
  </form>
</div>
<div *ngIf="!signataireForm.disabled" class="form-actions mt-4" id="action">
  <button (click)="onCancel()" class="ds-btn ds-btn--secondary" id="cancel-save" type="button">Annuler</button>
  <button (click)="submitFormSignataire()" class="ds-btn ds-btn--primary" id="save-contact" type="submit">
    <span>Enregistrer</span>
  </button>
</div>
