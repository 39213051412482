<ng-container>
  <form [formGroup]="form">
    <div
      #telephone
      id="{{ containerId }}"
      class="ds-input-group ds-input-group--telephone"
      [ngClass]="{ 'ds-input-group--error': form && !form.controls['telephone']?.valid && form.controls['telephone']?.touched }"
    >
      <label class="ds-input-group__label" for="{{ id }}">Téléphone <lib-asterisk *ngIf="required"></lib-asterisk></label>
      <span class="ds-input-group__input">
        <input
          (blur)="onBlur()"
          class="ds-input-group__input"
          formControlName="telephone"
          id="{{ id }}"
          name="{{ id }}"
          placeholder="Téléphone"
          type="tel"
        />
      </span>
      <!-- Icône et message d'erreur -->
      <ng-container *ngIf="form && !form.controls['telephone']?.valid && form.controls['telephone']?.touched" id="error-telephone">
        <div class="error-telephone-msg ds-input-group__feedback" id="id_error-telephone-msg">
          Merci de bien vouloir renseigner un numéro de téléphone valide
        </div>
      </ng-container>
    </div>
  </form>
</ng-container>
