import { DocumentConfigModel } from '../../shared/models/_public_models';
import { EnumScope } from '../../shared/enums/_public_enums';

const projetDocumentConfig: DocumentConfigModel[] = [
  {
    nomDocument: "Rapport d'avancement",
    scope: EnumScope.PROJET,
    mediaTypes: [],
    codeGed: 'F201',
    obligatoire: true,
    absenceDocument: false,
  },
  {
    nomDocument: 'Fiche de communication',
    scope: EnumScope.PROJET,
    mediaTypes: [],
    codeGed: 'H115',
    obligatoire: true,
    absenceDocument: false,
  },
  {
    nomDocument:
      'Le(s) justificatif(s) de réalisation de(s) condition(s) particulière(s) SI les conditions particulières dépendent du projet',
    scope: EnumScope.PROJET,
    mediaTypes: [],
    codeGed: 'D106',
    obligatoire: false,
  },
  {
    nomDocument: 'Livrables',
    scope: EnumScope.PROJET,
    mediaTypes: [],
    codeGed: 'F401',
    obligatoire: false,
  },
  {
    nomDocument: 'Autres documents jugés utiles par Bpifrance',
    scope: EnumScope.PROJET,
    mediaTypes: [],
    codeGed: 'B401',
    obligatoire: false,
  },
];

const structureDocumentConfig: DocumentConfigModel[] = [
  {
    nomDocument: 'Un Etat Récapitulatif des Dépenses Acquittées (ERDA), daté et signé par le partenaire',
    scope: EnumScope.STRUCTURE,
    mediaTypes: [],
    codeGed: 'F101',
    obligatoire: true,
  },
  {
    nomDocument: 'Une attestation relative à cet ERDA datée et signée par un commissaire aux comptes ou par un expert comptable',
    scope: EnumScope.STRUCTURE,
    mediaTypes: [],
    codeGed: 'F101',
    obligatoire: true,
  },
  {
    nomDocument: 'Une attestation actualisée de régularité fiscale',
    scope: EnumScope.STRUCTURE,
    mediaTypes: [],
    codeGed: 'D107',
    obligatoire: true,
  },
  {
    nomDocument: 'Une attestation actualisée de régularité sociale',
    scope: EnumScope.STRUCTURE,
    mediaTypes: [],
    codeGed: 'D107',
    obligatoire: true,
  },
  {
    nomDocument: 'La dernière liasse fiscale',
    scope: EnumScope.STRUCTURE,
    mediaTypes: [],
    codeGed: 'B105',
    obligatoire: false,
  },
  {
    nomDocument: "Le Rapport Annuel d'Exploitation (RAE)",
    scope: EnumScope.STRUCTURE,
    mediaTypes: [],
    codeGed: 'G402',
    obligatoire: false,
  },
  {
    nomDocument: "Un état récapitulatif de l'ensemble des aides publiques obtenues pour tout ou partie du programme",
    scope: EnumScope.STRUCTURE,
    mediaTypes: [],
    codeGed: 'C313',
    obligatoire: false,
  },
  {
    nomDocument:
      'Le(s) justificatif(s) de réalisation de(s) condition(s) particulière(s) SI les conditions particulières dépendent de la structure',
    scope: EnumScope.STRUCTURE,
    mediaTypes: [],
    codeGed: 'D106',
    obligatoire: false,
  },
  {
    nomDocument: 'Autres documents jugés utiles par Bpifrance',
    scope: EnumScope.STRUCTURE,
    mediaTypes: [],
    codeGed: 'B401',
    obligatoire: false,
  },
  {
    nomDocument: "RIB actualisée en mode dématérialisé (si changement d'adresse, de banque…)",
    scope: EnumScope.STRUCTURE,
    mediaTypes: [],
    codeGed: 'F304',
    obligatoire: false,
  },
];

const avanceInitialeStructureDocumentConfig: DocumentConfigModel[] = [
  {
    nomDocument: 'Le(s) justificatif(s) de réalisation de(s) condition(s) particulière(s)',
    scope: EnumScope.STRUCTURE,
    mediaTypes: [],
    codeGed: 'D106',
    obligatoire: false,
  },
];

const avanceInitialeProjetDocumentConfig: DocumentConfigModel[] = [
  {
    nomDocument: 'Le(s) justificatif(s) de réalisation de(s) condition(s) particulière(s)',
    scope: EnumScope.PROJET,
    mediaTypes: [],
    codeGed: 'D106',
    obligatoire: false,
  },
];

const lettreVersementDocumentConfig: DocumentConfigModel[] = [
  {
    nomDocument: 'Lettre de versement signée',
    scope: EnumScope.PROJET,
    mediaTypes: [],
    codeGed: 'H101',
    obligatoire: false,
    enSuivi: true,
  },
];
export {
  projetDocumentConfig,
  structureDocumentConfig,
  avanceInitialeStructureDocumentConfig,
  avanceInitialeProjetDocumentConfig,
  lettreVersementDocumentConfig,
};
