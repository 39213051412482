import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DonneesFinancieres, Environment, Structure } from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class DonneesFinancieresHttpService {
  private readonly headers: HttpHeaders;
  private readonly structureUrl: string;
  private readonly adminStructureUrl: string;

  constructor(@Inject('environment') private readonly environment: Environment, private readonly http: HttpClient) {
    this.structureUrl = this.environment.apiUrl + 'structures';
    this.adminStructureUrl = this.environment.apiAdminUrl + 'structures';
    this.headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8');
  }

  public putStructureDonneesFinancieres(structureId: string, donneesFinancieres: DonneesFinancieres) {
    const url = `${this.structureUrl}/${structureId}/donnees-financieres`;
    return this.http.put<Structure>(url, donneesFinancieres, { headers: this.headers });
  }

  public adminPutStructureDonneesFinancieres(structureId: string, donneesFinancieres: DonneesFinancieres) {
    const url = `${this.adminStructureUrl}/${structureId}/donnees-financieres`;
    return this.http.put<Structure>(url, donneesFinancieres, { headers: this.headers });
  }
}
