import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { distinctUntilChanged } from 'rxjs';
import { EnumQualifRue } from '../../shared/enums/enum.qualifRue';
import { EnumTypeVoie } from '../../shared/enums/enum.typeVoie';
import { Adresse } from '../../shared/models/adresse.model';
import { SubscriptionDestroyerComponent } from '../../shared/subscription-destroyer/subscription-destroyer.abstract';
import { ICountry } from 'ngx-countries-dropdown';

@Component({
  selector: 'lib-structure-adresse',
  templateUrl: './structure-adresse.component.html',
  styleUrls: ['./structure-adresse.component.scss'],
})
export class StructureAdresseComponent extends SubscriptionDestroyerComponent implements OnInit, OnChanges {
  @Input() adresseForm: any;
  @Input() isPersonnePhysique = false;
  @Input() readOnly = false;

  config = {
    hideCode: true,
    hideDialCode: true,
  };

  paysControl: FormControl;
  typesVoie = EnumTypeVoie;
  qualifRue = EnumQualifRue;
  isPaysFrance = false;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    const pays = this.getPays(this.adresseForm.value);
    this.paysControl = new FormControl(pays, [Validators.required]);

    this.updateState();
    this.subscribeToPaysControlChanges();
    this.bindPaysControls();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['readOnly'] || changes['isPersonnePhysique']) {
      this.updateState();
    }
  }

  updateState() {
    if (this.readOnly) {
      this.adresseForm.disable();
      const pays = this.getPays(this.adresseForm?.value);
      this.paysControl?.setValue(pays);
    } else {
      this.adresseForm.enable();
    }
  }

  subscribeToPaysControlChanges() {
    this.paysControl.valueChanges.pipe(distinctUntilChanged(), this.takeUntilDestroyed()).subscribe(this.updateAdresseFrom.bind(this));
  }

  updateAdresseFrom(pays: ICountry) {
    if (!pays) return;

    this.isPaysFrance = pays.code === 'FR';
    if (this.isPaysFrance) {
      // French address
      this.adresseForm.get('divisionAdministrative')?.reset();

      if (!this.readOnly) {
        this.adresseForm.get('numero')?.enable();
        this.adresseForm.get('typeVoie')?.enable();
      }
    } else {
      // Foreign address
      this.adresseForm.get('numero')?.reset();
      this.adresseForm.get('typeVoie')?.reset();
      this.adresseForm.get('mentionDistribution')?.reset();
      this.adresseForm.get('hameau')?.reset();
      this.adresseForm.get('complement')?.reset();
      this.adresseForm.get('complementInfo')?.reset();

      this.adresseForm.get('numero')?.disable();
      this.adresseForm.get('typeVoie')?.disable();
    }

    if (pays?.code) {
      this.adresseForm.patchValue({ codePays: pays.code, pays: pays.name });
    } else {
      this.adresseForm.patchValue({ codePays: null, pays: null });
    }

    this.changeDetectorRef.detectChanges();
  }

  // bind pays control to pays adresse control
  bindPaysControls() {
    this.adresseForm
      .get('codePays')
      ?.valueChanges.pipe(this.takeUntilDestroyed())
      .subscribe(() => {
        if (this.adresseForm.get('codePays')?.disabled) {
          this.paysControl.disable();
        } else {
          this.paysControl.enable();
        }
      });
  }

  getPays(adresse: Adresse) {
    if (adresse?.codePays) {
      return { code: adresse.codePays };
    }
    return {
      code: 'FR',
      name: 'France',
    };
  }

  onkeypress($event: KeyboardEvent): boolean {
    if ($event.key) {
      if (/[^\d]/.test($event.key)) {
        return false;
      }
    }
    return true;
  }

  // Order by ascending property value
  valueOrder(a: KeyValue<string, EnumQualifRue | EnumTypeVoie>, b: KeyValue<string, EnumQualifRue | EnumTypeVoie>): number {
    return a.value.localeCompare(b.value);
  }

  onCountryChange(country: ICountry) {
    this.adresseForm.get('codePays')?.setValue(country.code);
    this.updateAdresseFrom(country);
  }
}
