import { Component, DestroyRef, EventEmitter, inject, Inject, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  Aap,
  ActiviteInstructeur,
  EnumActivite,
  EnumFeatureFlipping,
  EnumPermissionUtilisateur,
  EnumTypeNotificationTags,
  FeatureFlagService,
  minSelectedCheckboxes,
  PermissionUtils,
  PreferencesUtilisateurService,
  Projet,
  ShowToastrService,
  Utilisateur,
} from '@shared-ui';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { HttpErrorResponse } from '@angular/common/http';

export interface AutoAffectationModalData {
  projets: Projet[];
  aap: Aap;
  user: Utilisateur;
  withIntervenantPIOption: boolean;
  withReferentOption: boolean;
  isModificationAutoAffectation: boolean;
}

@Component({
  selector: 'lib-auto-affect-modal',
  templateUrl: './auto-affectation-modal.component.html',
  styleUrls: ['./auto-affectation-modal.component.scss'],
})
export class AutoAffectationModalComponent implements OnInit {
  @Output() responseModal: EventEmitter<any> = new EventEmitter();
  private destroyRef = inject(DestroyRef);

  selectedProjets: Projet[];
  autoAffectationForm: FormGroup<{
    perimetresDE: FormArray<FormControl<boolean | null>>;
    activites: FormArray<FormControl<boolean | null>>;
    referent: FormControl<boolean | null>;
    intervenantPI: FormControl<boolean | null>;
  }>;
  activites: EnumActivite[] = [];
  showIntervenantPI = false;
  showReferent = false;
  perimetresDE: EnumTypeNotificationTags[] = EnumTypeNotificationTags.all();
  canUserSetPerimeter = false;
  userPerimetres: EnumTypeNotificationTags[];

  constructor(
    public dialogRef: MatDialogRef<AutoAffectationModalComponent>,
    private formBuilder: FormBuilder,
    private featureFlagService: FeatureFlagService,
    @Inject(MAT_DIALOG_DATA) public modalData: AutoAffectationModalData,
    private preferencesUtilisateurService: PreferencesUtilisateurService,
    private showToastrService: ShowToastrService
  ) {}

  ngOnInit(): void {
    if (this.modalData.projets) {
      this.selectedProjets = this.modalData.projets;
    }
    this.createForm();

    this.activites = this.getAuthorizedActivities();
    this.activites.forEach(() => this.autoAffectationForm.controls.activites.push(new FormControl(false)));
    this.perimetresDE.forEach(() => this.autoAffectationForm.controls.perimetresDE.push(new FormControl(false)));

    this.showIntervenantPI = this.modalData.withIntervenantPIOption && this.activites.includes(EnumActivite.INSTRUCTION);
    this.showReferent = this.modalData.withReferentOption;
  }

  createForm(): void {
    this.autoAffectationForm = this.formBuilder.group({
      perimetresDE: this.formBuilder.array<boolean>([]),
      activites: this.formBuilder.array<boolean>([], minSelectedCheckboxes(1)),
      referent: [false, [Validators.required]],
      intervenantPI: [{ value: false, disabled: true }, [Validators.required]],
    });

    if (PermissionUtils.hasPermission(this.modalData.user, EnumPermissionUtilisateur.PROJET_INSTRUCTEUR_CHOIX_PERIMETRE_WRITE)) {
      this.canUserSetPerimeter = true;
      this.getUserPerimetres();
      this.autoAffectationForm.controls.perimetresDE.setValidators(minSelectedCheckboxes(1));
    }

    this.autoAffectationForm.controls.activites.valueChanges.subscribe(activites => {
      const instructionIndex = this.activites.indexOf(EnumActivite.INSTRUCTION);
      if (instructionIndex !== -1) {
        if (activites[instructionIndex]) {
          this.autoAffectationForm.controls.intervenantPI.enable();
        } else {
          this.autoAffectationForm.controls.intervenantPI.disable();
          this.autoAffectationForm.controls.intervenantPI.setValue(false);
        }
      }
    });
  }

  save(): void {
    if (!this.autoAffectationForm.valid) {
      this.autoAffectationForm.markAllAsTouched();
      return;
    }

    const activites = this.getSelectedActivities();

    const perimetresDE = this.getSelectedPerimetresDE();

    const projetIds = this.selectedProjets.map(project => project.id);
    const referent = this.autoAffectationForm.controls.referent.value;
    const intervenantPI = this.autoAffectationForm.controls.intervenantPI.value;

    this.dialogRef.close({ projetIds, activites, perimetresDE, referent, intervenantPI });
  }

  private getSelectedPerimetresDE() {
    return (this.autoAffectationForm.controls.perimetresDE.value ?? [])
      .map((selected, index) => (selected ? this.perimetresDE[index] : null))
      .filter(Boolean);
  }

  private getSelectedActivities() {
    return (this.autoAffectationForm.controls.activites.value ?? [])
      .map((selected, index) => (selected ? this.activites[index] : null))
      .filter(Boolean);
  }

  getAuthorizedActivities(): EnumActivite[] {
    return EnumActivite.all()
      .filter(activite => this.featureFlagService.featureOn(this.getFeatureFromActivity(activite)))
      .filter(
        activite =>
          (activite !== EnumActivite.VERROUILLAGE || this.hasActivitePermission(EnumActivite.VERROUILLAGE)) &&
          (activite !== EnumActivite.ELIGIBILITE ||
            (this.modalData.aap.eligibilitePresent && this.hasActivitePermission(EnumActivite.ELIGIBILITE))) &&
          (activite !== EnumActivite.OPPORTUNITE ||
            (this.modalData.aap.notationEvaluationPresent && this.hasActivitePermission(EnumActivite.OPPORTUNITE))) &&
          (activite !== EnumActivite.AUDITION ||
            (this.modalData.aap.notationAuditionPresent && this.hasActivitePermission(EnumActivite.AUDITION))) &&
          (activite !== EnumActivite.INSTRUCTION ||
            (this.modalData.aap.instructionPresent && this.hasActivitePermission(EnumActivite.INSTRUCTION))) &&
          (activite !== EnumActivite.CONTRACTUALISATION ||
            (this.modalData.aap.contractualisationPresent && this.hasActivitePermission(EnumActivite.CONTRACTUALISATION))) &&
          (activite !== EnumActivite.SUIVI || this.hasActivitePermission(EnumActivite.SUIVI))
      );
  }

  hasActivitePermission(activite: EnumActivite) {
    switch (activite) {
      case EnumActivite.VERROUILLAGE:
        return PermissionUtils.hasAnyPermissionOnAap(
          this.modalData.user,
          [EnumPermissionUtilisateur.PROJET_VERROUILLAGE_WRITE],
          this.modalData.aap
        );
      case EnumActivite.ELIGIBILITE:
        return PermissionUtils.hasAnyPermissionOnAap(
          this.modalData.user,
          [EnumPermissionUtilisateur.PROJET_ELIGIBILITE_WRITE],
          this.modalData.aap
        );
      case EnumActivite.OPPORTUNITE:
        return PermissionUtils.hasAnyPermissionOnAap(
          this.modalData.user,
          [
            EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_WRITE,
            EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_EVALUATEUR_WRITE,
            EnumPermissionUtilisateur.PROJET_NOTATION_EVALUATION_INSTRUCTEUR_WRITE,
            EnumPermissionUtilisateur.PROJET_SYNTHESE_EVALUATION_WRITE,
            // TODO: Perms a décommissionner
            EnumPermissionUtilisateur.PROJET_EVALUATION_WRITE,
            EnumPermissionUtilisateur.PROJET_EVALUATION_WRITE_ALL,
          ],
          this.modalData.aap
        );
      case EnumActivite.AUDITION:
        return PermissionUtils.hasAnyPermissionOnAap(
          this.modalData.user,
          [
            EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_WRITE,
            EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_EVALUATEUR_WRITE,
            EnumPermissionUtilisateur.PROJET_NOTATION_AUDITION_INSTRUCTEUR_WRITE,
            EnumPermissionUtilisateur.PROJET_SYNTHESE_AUDITION_WRITE,
            // TODO: Perms a décommissionner
            EnumPermissionUtilisateur.PROJET_AUDITION_WRITE,
            EnumPermissionUtilisateur.PROJET_AUDITION_WRITE_ALL,
          ],
          this.modalData.aap
        );
      case EnumActivite.INSTRUCTION:
        return PermissionUtils.hasAnyPermissionOnAap(
          this.modalData.user,
          [
            EnumPermissionUtilisateur.PROJET_NOTATION_SELECTION_WRITE,
            EnumPermissionUtilisateur.PROJET_NOTATION_SELECTION_WRITE_ALL,
            EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE,
            EnumPermissionUtilisateur.PROJET_INSTRUCTION_WRITE_ALL,
            EnumPermissionUtilisateur.PROJET_PROPOSITION_FINANCEMENT_WRITE,
            EnumPermissionUtilisateur.PROJET_SYNTHESE_INSTRUCTION_WRITE,
          ],
          this.modalData.aap
        );
      case EnumActivite.CONTRACTUALISATION:
        return PermissionUtils.hasAnyPermissionOnAap(
          this.modalData.user,
          [
            EnumPermissionUtilisateur.PROJET_CONTRACTUALISATION_WRITE,
            EnumPermissionUtilisateur.PROJET_CONTRACTUALISATION_EDITION_RELECTURE_DONNEES_STRUCTURE_WRITE,
            EnumPermissionUtilisateur.PROJET_CONTRACTUALISATION_EDITION_RELECTURE_BDD_WRITE,
          ],
          this.modalData.aap
        );
      case EnumActivite.SUIVI:
        return PermissionUtils.hasAnyPermissionOnAap(this.modalData.user, [EnumPermissionUtilisateur.SUIVI_PROJET], this.modalData.aap);
      default:
        return false;
    }
  }

  getFeatureFromActivity(activity: EnumActivite): EnumFeatureFlipping {
    switch (activity) {
      case EnumActivite.INSTRUCTION:
        return EnumFeatureFlipping.INSTRUCTION_APPROFONDIE;
      case EnumActivite.SUIVI:
        return EnumFeatureFlipping.SUIVI_DES_PROJETS;
      default:
        return EnumFeatureFlipping[activity as keyof typeof EnumFeatureFlipping];
    }
  }

  checkProjetReferent() {
    const selectedPerimetresDE = this.getSelectedPerimetresDE();
    const selectedActivites = this.getSelectedActivities();

    return this.selectedProjets.some(projet =>
      projet.instructeurs?.some(
        instructeur =>
          instructeur.matricule !== this.modalData?.user?.matricule &&
          instructeur.activites.some(activite =>
            this.isInstructeurReferentInSameActivityAndSamePerimeter(activite, selectedActivites, selectedPerimetresDE)
          )
      )
    );
  }

  private isInstructeurReferentInSameActivityAndSamePerimeter(
    activite: ActiviteInstructeur,
    selectedActivites: (EnumActivite | null)[],
    selectedPerimetresDE: (EnumTypeNotificationTags | null)[]
  ) {
    return (
      activite.referent &&
      selectedActivites.includes(activite.nomActivite) &&
      selectedPerimetresDE.some(p => activite?.perimetresDE?.includes(p!))
    );
  }

  getUserPerimetres() {
    this.preferencesUtilisateurService
      .getPreferenceForCurrentUser()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: preferences => {
          this.userPerimetres = preferences?.preferences?.perimetresDE ? preferences.preferences.perimetresDE : [];
          this.updateFormArrayValues();
        },
        error: (err: HttpErrorResponse) => {
          if (err.status === 404) {
            this.userPerimetres = [];
          } else {
            this.showToastrService.checkCodeError(err?.error);
          }
        },
      });
  }
  updateFormArrayValues() {
    const formArray = this.autoAffectationForm.get('perimetresDE') as FormArray;

    this.perimetresDE.forEach((per, index) => {
      const isChecked = this.userPerimetres.includes(per);
      if (formArray.at(index)) {
        formArray.at(index).setValue(isChecked);
      }
    });
  }

  getTitre(): string {
    return this.modalData.isModificationAutoAffectation ? 'Je modifie mon affectation' : "Je m'auto affecte";
  }

  protected readonly EnumActivite = EnumActivite;
  protected readonly EnumFeatureFlipping = EnumFeatureFlipping;
  protected readonly EnumTypeNotificationTags = EnumTypeNotificationTags;
  protected readonly PermissionUtils = PermissionUtils;
}
