import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Environment, RapportExpertise } from '@shared-ui';

@Injectable({
  providedIn: 'root',
})
export class RapportExpertiseService {
  constructor(@Inject('environment') private environment: Environment, private httpClient: HttpClient) {}

  createRapportExpertise(rapportExpertise: RapportExpertise, expertEmail?: string): Observable<HttpResponse<RapportExpertise>> {
    let url = `${this.environment.apiUrl}rapports-expertise`;
    if (expertEmail) {
      url += `?expertEmail=${encodeURIComponent(expertEmail)}`;
    }
    return this.httpClient.post<RapportExpertise>(url, rapportExpertise, {
      observe: 'response',
    });
  }

  updateRapportExpertise(rapportExpertise: RapportExpertise, expertEmail?: string): Observable<HttpResponse<RapportExpertise>> {
    let url = `${this.environment.apiUrl}rapports-expertise/${rapportExpertise.id}`;
    if (expertEmail) {
      url += `?expertEmail=${encodeURIComponent(expertEmail)}`;
    }
    return this.httpClient.put<RapportExpertise>(url, rapportExpertise, {
      observe: 'response',
    });
  }

  getRapportsExpertiseByProjetId(projetId: string): Observable<HttpResponse<RapportExpertise[]>> {
    return this.httpClient.get<RapportExpertise[]>(`${this.environment.apiUrl}rapports-expertise?projetId=${projetId}`, {
      observe: 'response',
    });
  }
}
