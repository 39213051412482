import { EnumScope } from '../enums/enum.scope';
import { DemandeComplementInterface } from '../interfaces/demande-complement.interface';
import { Auteur } from './auteur.model';
import { Contact } from './contact.model';
import { Structure } from './structure.model';
import { DemandeComplementsDocument } from './demande-complements-document.model';

export class DemandeComplement implements DemandeComplementInterface {
  id: string;
  statut: string;
  dateCreation?: Date;
  dateModification?: Date;
  projetId: string;
  auteur?: Auteur;
  scope: EnumScope;
  structures?: Structure[] = [];
  contactsANotifier?: Contact[] = [];
  documents: DemandeComplementsDocument[] = [];
  archived: boolean;
  commentaire: string;
  etapeCleId: string;
  enSuivi?: boolean;
}
