import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, DestroyRef, EventEmitter, Output, ViewChild, ViewEncapsulation, inject } from '@angular/core';
import { MAT_MENU_DEFAULT_OPTIONS, MatMenuTrigger } from '@angular/material/menu';
import {
  FilterDataInterface,
  MultiselectComponent,
  MultiSelectCriteria,
  PartenaireFilters,
  RangeCriteria,
  RechercheTransverseService,
  ShowToastrService,
} from '@shared-ui';

@Component({
  selector: 'lib-partenaire-filters',
  templateUrl: './partenaire-filters.component.html',
  styleUrls: ['./partenaire-filters.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'partenaire-filters-menu',
      },
    },
  ],
})
export class PartenaireFiltersComponent implements AfterViewInit {
  destroyRef = inject(DestroyRef);

  readonly ASSIETTE_PRESENTEE = 'assiete_presentee';
  readonly ASSIETTE_TOTALE_RETENUE = 'assiette_totale_retenue';
  readonly MONTANT_TOTAL_AIDE_HT = 'montant_total_de_l_aide_retenue_ht';
  readonly CODE_POSTAL = 'code_postal_de_realisation_partenaire';
  readonly SIRET = 'siret';
  readonly SIREN = 'siren';
  readonly PROJET_PXL_PIC = 'n_projet_pic_pxl';
  readonly NOM_DU_GROUPE = 'nom_du_groupe';

  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  @ViewChild('codePostalFilterComponent') codePostalFilterComponent!: MultiselectComponent;
  @ViewChild('siretFilterComponent') siretFilterComponent!: MultiselectComponent;
  @ViewChild('sirenFilterComponent') sirenFilterComponent!: MultiselectComponent;
  @ViewChild('projetPxlPicFilterComponent') projetPxlPicFilterComponent!: MultiselectComponent;
  @ViewChild('nomDuGroupeFilterComponent') nomDuGroupeFilterComponent!: MultiselectComponent;
  isOpened = false;

  @Output() filtersChanged: EventEmitter<PartenaireFilters> = new EventEmitter<PartenaireFilters>();

  codePostalSelectedList: FilterDataInterface[] = [];
  codePostalDataList: FilterDataInterface[] = [];

  siretSelectedList: FilterDataInterface[] = [];
  siretDataList: FilterDataInterface[] = [];

  sirenSelectedList: FilterDataInterface[] = [];
  sirenDataList: FilterDataInterface[] = [];

  projetPxlPicSelectedList: FilterDataInterface[] = [];
  projetPxlPicDataList: FilterDataInterface[] = [];

  nomDuGroupeSelectedList: FilterDataInterface[] = [];
  nomDuGroupeDataList: FilterDataInterface[] = [];

  minAssiettePresentee!: number | undefined;
  maxAssiettePresentee!: number | undefined;

  minAssietteTotaleRetenue!: number | undefined;
  maxAssietteTotaleRetenue!: number | undefined;

  minMontantTotalAideHT!: number | undefined;
  maxMontantTotalAideHT!: number | undefined;

  loadingMap = new Map();

  constructor(private rechercheTransverseService: RechercheTransverseService, private toastrService: ShowToastrService) {
    this.loadingMap.set(this.CODE_POSTAL, false);
    this.loadingMap.set(this.SIRET, false);
    this.loadingMap.set(this.SIREN, false);
    this.loadingMap.set(this.PROJET_PXL_PIC, false);
    this.loadingMap.set(this.NOM_DU_GROUPE, false);
  }

  ngAfterViewInit(): void {
    this.trigger.menuOpened.subscribe(() => {
      this.isOpened = true;
    });
  }

  applyFilters(): void {
    const rangeCriteriaMap: RangeCriteria = {};
    const fieldCriteriaMap: MultiSelectCriteria = {};
    rangeCriteriaMap[this.ASSIETTE_PRESENTEE] = this.getRangeCriteria(this.minAssiettePresentee, this.maxAssiettePresentee);
    rangeCriteriaMap[this.ASSIETTE_TOTALE_RETENUE] = this.getRangeCriteria(this.minAssietteTotaleRetenue, this.maxAssietteTotaleRetenue);
    rangeCriteriaMap[this.MONTANT_TOTAL_AIDE_HT] = this.getRangeCriteria(this.minMontantTotalAideHT, this.maxMontantTotalAideHT);

    fieldCriteriaMap[this.CODE_POSTAL] = this.getFieldCriteria(this.codePostalSelectedList);
    fieldCriteriaMap[this.SIRET] = this.getFieldCriteria(this.siretSelectedList);
    fieldCriteriaMap[this.SIREN] = this.getFieldCriteria(this.sirenSelectedList);
    fieldCriteriaMap[this.PROJET_PXL_PIC] = this.getFieldCriteria(this.projetPxlPicSelectedList);
    fieldCriteriaMap[this.NOM_DU_GROUPE] = this.getFieldCriteria(this.nomDuGroupeSelectedList);

    this.filtersChanged.emit({
      rangeFilters: rangeCriteriaMap,
      selectFilters: fieldCriteriaMap,
    });
    if (this.isOpened) {
      this.trigger.closeMenu();
    }
  }

  private getRangeCriteria(min: number | undefined, max: number | undefined): { start: number; end: number } | null {
    return min && max && min >= 0 && max >= 0 ? { start: min, end: max } : null;
  }

  private getFieldCriteria(selectedList: FilterDataInterface[]): string[] {
    return selectedList.length > 0 ? selectedList.map(item => item.value) : [];
  }

  resetFilters(): void {
    this.codePostalFilterComponent?.reset();
    this.codePostalSelectedList = [];
    this.siretFilterComponent?.reset();
    this.siretSelectedList = [];
    this.sirenFilterComponent?.reset();
    this.sirenSelectedList = [];
    this.projetPxlPicFilterComponent?.reset();
    this.projetPxlPicSelectedList = [];
    this.nomDuGroupeFilterComponent?.reset();
    this.nomDuGroupeSelectedList = [];
    this.minAssiettePresentee = undefined;
    this.maxAssiettePresentee = undefined;
    this.minAssietteTotaleRetenue = undefined;
    this.maxAssietteTotaleRetenue = undefined;
    this.minMontantTotalAideHT = undefined;
    this.maxMontantTotalAideHT = undefined;
  }

  onMenuClosed(): void {
    if (this.isOpened) {
      this.isOpened = false;
      this.applyFilters();
    }
  }

  preventValues(event: KeyboardEvent) {
    const forbiddenKeys = ['-', ',', '.', 'e', 'E', '+'];
    if (forbiddenKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  selectedListChange(event: FilterDataInterface[], source: string): void {
    switch (source) {
      case this.CODE_POSTAL:
        this.codePostalSelectedList = event;
        break;
      case this.SIRET:
        this.siretSelectedList = event;
        break;
      case this.SIREN:
        this.sirenSelectedList = event;
        break;
      case this.PROJET_PXL_PIC:
        this.projetPxlPicSelectedList = event;
        break;
      case this.NOM_DU_GROUPE:
        this.nomDuGroupeSelectedList = event;
        break;
    }
  }

  autocompleteEvent(event: string, source: string): void {
    this.loadingMap.set(source, true);
    const searchObject = {
      query: this.removeElasticsearchSpecialCharacters(event),
      fieldName: source,
      isPrefix: source === this.CODE_POSTAL,
    };

    this.rechercheTransverseService.getAutocomplete(searchObject).subscribe({
      next: resultAutocomplete => {
        switch (source) {
          case this.CODE_POSTAL:
            this.codePostalDataList = (resultAutocomplete.body as string[]).map(cp => ({ id: cp, value: cp }));
            break;
          case this.SIRET:
            this.siretDataList = (resultAutocomplete.body as string[]).map(siret => ({ id: siret, value: siret }));
            break;
          case this.SIREN:
            this.sirenDataList = (resultAutocomplete.body as string[]).map(siren => ({ id: siren, value: siren }));
            break;
          case this.PROJET_PXL_PIC:
            this.projetPxlPicDataList = (resultAutocomplete.body as string[]).map(projet => ({ id: projet, value: projet }));
            break;
          case this.NOM_DU_GROUPE:
            this.nomDuGroupeDataList = (resultAutocomplete.body as string[]).map(nomGroupe => ({ id: nomGroupe, value: nomGroupe }));
            break;
        }
        this.loadingMap.set(source, false);
      },
      error: (err: HttpErrorResponse) => {
        this.toastrService.checkCodeError(err?.error);
        this.loadingMap.set(source, false);
      },
    });
  }

  private removeElasticsearchSpecialCharacters(str: string) {
    const specialCharacters = [
      '+',
      '-',
      '=',
      '&&',
      '.',
      '||',
      '>',
      '<',
      '!',
      '(',
      ')',
      '{',
      '}',
      '[',
      ']',
      '^',
      '"',
      '~',
      '*',
      '?',
      ':',
      '\\',
      '/',
    ];
    return str.replace(new RegExp(`[${specialCharacters.join('\\')}]`, 'g'), ' ');
  }
}
