import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, DestroyRef, HostListener, inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { ProjetService } from '@services-candidat/projet.service';
import { StructureService } from '@services-candidat/structure.service';
import { UserService } from '@services-candidat/user.service';
import { ConfirmModalComponent } from '@shared-candidat/components/modals/confirm-modal/confirm-modal.component';
import { URL_PUBLIC_DOC } from '@shared-candidat/utils/constants';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  Contact,
  DateTimeUtils,
  EnumAapStatut,
  EnumGenre,
  EnumMotifNonRattachement,
  EnumProcedureSignatureStatut,
  EnumProjetEtape,
  EnumProjetStatut,
  EnumProjetTag,
  EnumRoleContact,
  EnumRoleStructure,
  GenreItem,
  Invitation,
  Projet,
  ProjetEtape,
  ShowToastrService,
  Structure,
  StructureSyntheseHelperService,
  StructureValidationFunction,
  SuiviProjet,
  Utilisateur,
} from '@shared-ui';
import { concat, Observable } from 'rxjs';
import { concatMap, toArray } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ICountry } from 'ngx-countries-dropdown';

declare const dsLib: any;

@Component({
  selector: 'app-projet-consortium-contact',
  templateUrl: './projet-consortium-contact.component.html',
  styleUrls: ['./projet-consortium-contact.component.scss'],
})
export class ProjetConsortiumContactComponent implements OnInit {
  readonly RESPONSABLE_PROJET = EnumRoleContact[EnumRoleContact.RESPONSABLE_PROJET.toString()];
  readonly REPRESENTANT_LEGAL = EnumRoleContact[EnumRoleContact.REPRESENTANT_LEGAL.toString()];
  readonly RESPONSABLE_ADMINISTRATIF = EnumRoleContact[EnumRoleContact.RESPONSABLE_ADMINISTRATIF.toString()];
  readonly AUTRE = EnumRoleContact[EnumRoleContact.AUTRE.toString()];

  private readonly destroyRef: DestroyRef = inject(DestroyRef);

  projet: Projet = new Projet();
  structure: Structure;
  structures: Structure[];
  invitations: Invitation[] = [];
  utilisateur: Utilisateur;
  contact: Contact = new Contact();
  contactForm: UntypedFormGroup;
  projetId: string;
  structureId: string;
  contactId: string;
  targetedCreation: string;
  aap: Aap = new Aap();
  invitation?: Invitation;
  genres: Array<GenreItem> = EnumGenre.all().map(item => ({ valeur: item, checked: false }));
  countrySelectDirty: boolean;
  public projectManagerChecked: boolean;
  private projectRAChecked: boolean;
  flag = false;
  lastEtape: EnumProjetEtape;
  isRepresentantLegal = false;
  canDeletedContact = false;
  suiviProjet: SuiviProjet;

  config = {
    hideCode: true,
    hideDialCode: true,
  };

  telephone = {};
  isStructureUpdatableByUser: boolean;
  isRepresentantLegalSignataireInOngoingProcedure = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private projetService: ProjetService,
    private structureService: StructureService,
    private showToastrService: ShowToastrService,
    private aapService: AapService,
    private userService: UserService,
    public sharedFunction: SharedFunction,
    private structureSyntheseHelperService: StructureSyntheseHelperService
  ) {}

  ngOnInit(): void {
    this.utilisateur = this.route.snapshot.parent.data?.utilisateur;
    this.suiviProjet = this.route.snapshot.parent.data?.suiviProjet;
    this.createForms();
    this.getProjet();
    this.telephone = new dsLib.InputPhone('telephone');
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    this.contactForm.get('paysNaissance').markAsPristine();
    return !this.contactForm.dirty && !this.countrySelectDirty;
  }

  onBlur(): void {
    const inputPhone = document.getElementById('input-telephone');
    if (inputPhone.classList.contains('ds-input-group--error')) {
      this.contactForm.controls.telephone.setErrors({ incorrect: true });
    }
  }

  createForms(): void {
    this.contactForm = this.formBuilder.group({
      nom: [null, Validators.required],
      prenom: [null, Validators.required],
      fonction: [null, Validators.required],
      genre: [null, Validators.required],
      dateNaissance: [null, Validators.required],
      villeNaissance: [null, Validators.required],
      paysNaissance: [null, [Validators.required]],
      roles: [[this.AUTRE], Validators.required],
      telephone: [null, Validators.required],
      email: [null, Validators.required],
      adresse: this.formBuilder.group({
        codePays: [this.contact?.adresse?.codePays || null, Validators.required],
        pays: [this.contact?.adresse?.pays || null, Validators.required],
        cp: [this.contact?.adresse?.cp || null, Validators.required],
        ville: [this.contact?.adresse?.ville || null, Validators.required],
        mentionDistribution: [this.contact?.adresse?.mentionDistribution || null],
        hameau: [this.contact?.adresse?.hameau || null],
        numero: [this.contact?.adresse?.numero || null, Validators.required],
        complement: [this.contact?.adresse?.complement || null],
        typeVoie: [this.contact?.adresse?.typeVoie || null, Validators.required],
        voie: [this.contact?.adresse?.voie || null, Validators.required],
        complementInfo: [this.contact?.adresse?.complementInfo || null],
        divisionAdministrative: [this.contact?.adresse?.divisionAdministrative || null],
      }),
    });
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    this.projetId = this.route.snapshot.parent.params.id;
    this.structureId = this.route.snapshot.params.structureId;
    this.contactId = this.route.snapshot.params.contactId;

    if (this.suiviProjet) {
      this.setContactForm();
    } else if (this.structureId && this.projetId) {
      this.projetService
        .getProjetObservable()
        .pipe(
          takeUntilDestroyed(this.destroyRef),
          concatMap(responseProjet => {
            if (responseProjet) {
              this.projet = responseProjet;
              this.lastEtape = this.projet.etapes[this.projet.etapes.length - 1].nom;
              this.userService
                .getUserObservable()
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(response => {
                  if (response) {
                    this.utilisateur = response;
                  }
                });

              return this.aapService.loadAapSubject();
            }
          })
        )
        .subscribe({
          next: responseAap => {
            if (responseAap) {
              this.aap = responseAap.body;
              this.setContactForm();
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    }
  }

  setContactForm(): void {
    this.structureService
      .getStructuresListObservable()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(response => {
        if (response) {
          this.structures = response;
          this.structure = response.find(structure => structure.id === this.structureId);
          if (this.suiviProjet) {
            this.isStructureUpdatableByUser = this.sharedFunction.canUpdateStructure(this.suiviProjet, this.structure, this.utilisateur);
            this.patchStructure();
          } else {
            this.isStructureUpdatableByUser = this.sharedFunction.isStructureUpdatableByUser(
              this.aap,
              this.projet,
              this.structure,
              this.utilisateur
            );
          }

          this.loadContactInForm();
          this.isRepresentantLegal = this.hasRole(this.REPRESENTANT_LEGAL);
          this.projectManagerChecked = this.contactForm?.get('roles')?.value == 'RESPONSABLE_PROJET';
          this.canDeleteContact();
        }
      });
  }

  loadContactInForm(): void {
    if (this.structure?.adresse?.pays) {
      this.contactForm.patchValue({
        paysNaissance: {
          code: this.structure.adresse.codePays,
          name: this.structure.adresse.pays,
        },
      });
    }
    if (this.contactId) {
      switch (this.contactId) {
        case 'nouveau-responsable-projet': {
          this.targetedCreation = 'Nouveau.elle responsable projet';
          this.contactId = null;
          this.contactForm.get('roles').setValue([this.RESPONSABLE_PROJET]);
          break;
        }
        case 'nouveau-representant-legal': {
          this.targetedCreation = 'Nouveau.elle représentant.e légal.e';
          this.contactId = null;
          this.contactForm.get('roles').setValue([this.REPRESENTANT_LEGAL]);
          break;
        }
        default: {
          this.contact = this.structure.contacts[this.contactId];
          this.structureService.getInvitationsContactsStructure(this.structure.id).subscribe(response => {
            this.invitations = response.body ?? [];
            this.invitation = this.getInvitation();
          });
          if (this.contact.inactif) {
            this.contactForm.disable();
          }
          this.contactForm.patchValue(this.contact);
          this.contactForm.patchValue({
            paysNaissance: {
              code: this.contact.codePaysNaissance,
              name: this.contact.paysNaissance,
            },
          });
          this.setGenreFormFieldValue();
          if (this.contact.selectionne) {
            this.loadSynthesesContractualisation();
          }
        }
      }
    }
  }

  setProjectRAChecked(isChecked: boolean): void {
    this.projectRAChecked = isChecked;
  }

  getProjectRAChecked(): boolean {
    return this.projectRAChecked;
  }

  selectRole(event: Event, role: string): void {
    if (role === 'RESPONSABLE_PROJET') {
      this.projectManagerChecked = (event.target as HTMLInputElement).checked;
    }
    if (role === 'RESPONSABLE_ADMINISTRATIF') {
      this.setProjectRAChecked((event.target as HTMLInputElement).checked);
    }

    this.contactForm.controls.roles.markAsTouched();

    this.addRolesContact(event, role);

    if (this.contactForm.value.roles.length === 0) {
      this.contactForm.controls.roles.updateValueAndValidity();
    } else {
      this.contactForm.get('roles').setErrors(null);
    }
  }

  private addRolesContact(event: Event, role: string) {
    if ((event.target as HTMLInputElement).checked && this.contactForm.value.roles.includes('AUTRE')) {
      this.contactForm.value.roles.splice(this.contactForm.value.roles.indexOf('AUTRE'), 1);
      this.contactForm.value.roles.push(role);
    } else if ((event.target as HTMLInputElement).checked) {
      this.contactForm.value.roles.push(role);
    }

    if (!(event.target as HTMLInputElement).checked && this.contactForm.value.roles.length === 1) {
      this.contactForm.value.roles.splice(this.contactForm.value.roles.indexOf(role), 1);
      this.contactForm.value.roles.push('AUTRE');
    } else if (!(event.target as HTMLInputElement).checked) {
      this.contactForm.value.roles.splice(this.contactForm.value.roles.indexOf(role), 1);
    }
  }

  hasRole(role: string): boolean {
    return this.contactForm.value.roles?.includes(role);
  }

  /*
   * Ouvre la popin du réprésentant légal
   * */
  openPopInRL(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Nombre maximum de représentant.e.s légaux.les atteint',
        description: `<p>Dans la structure, il ne peut y avoir que 3 représentant.e.s légaux.les.<br>
                         Si vous souhaitez en remplacer un, veuillez le supprimer préalablement.</p>`,
        textGoButton: 'Oui',
        textReturnButton: '',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onGoToConsortiumInfo();
      } else {
        this.flag = false;
      }
    });
  }

  /*
   * Ouvre la pop in du responsable projet
   * */
  openPopInR(formContact: any): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Changement de responsable projet et/ou responsable administratif et financier',
        description: `<p>Dans la structure, il ne peut y avoir qu'un seul responsable projet et un seul reponsable administratif et financier.<br>
        Un responsable projet et/ou un responsable administratif et financier ont déjà été désignés.<br>
        Si vous souhaitez le(s) remplacer, cliquer sur le bouton "Oui".</p>`,

        textGoButton: 'Oui',
        textReturnButton: 'Annuler',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateRole(result, formContact);
      } else {
        this.flag = false;
      }
    });
  }

  /*
   * Ouvre la pop in de Autre
   * */
  openPopInA(): void {
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: 'Nombre maximum de participants autres atteint',
        description: `<p>Dans la structure, il ne peut y avoir que 5 participants autres.<br>
                         Si vous souhaitez en remplacer un, veuillez le supprimer préalablement.</p>`,
        textGoButton: 'Oui',
        textReturnButton: '',
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.onGoToConsortiumInfo();
      } else {
        this.flag = false;
      }
    });
  }

  selectGenre(genre: GenreItem): void {
    const itemIndex = this.genres.findIndex(item => item.valeur === genre.valeur);
    if (!genre.checked) {
      this.genres.forEach(genreItem => {
        genreItem.checked = false;
      });
      this.genres[itemIndex].checked = true;
      this.contactForm.controls['genre'].setValue(genre.valeur);
    } else {
      this.genres[itemIndex].checked = false;
      this.contactForm.controls['genre'].setValue(null);
    }
  }

  setGenreFormFieldValue(): void {
    if (this.contact.genre) {
      const itemIndex = this.genres.findIndex(item => item.valeur === this.contact.genre);
      this.genres.forEach(genre => {
        genre.checked = false;
      });
      this.genres[itemIndex].checked = true;
    }
  }

  /*
   * cette méthode met à jour ou non le responsable projet et/ou le responsable administratif en fonction de la réponse de l'utilisateur
   * */
  updateRole(result: string, formContact: any): void {
    if (result) {
      const contactsToUpdate: Contact[] = [];

      if (this.getProjectRAChecked()) {
        const contact = this.removeResponsableRole(this.RESPONSABLE_ADMINISTRATIF);
        if (contact) {
          contactsToUpdate.push(contact);
        }
      }
      if (this.projectManagerChecked) {
        const contact = this.removeResponsableRole(this.RESPONSABLE_PROJET);
        if (contact) {
          contactsToUpdate.push(contact);
        }
      }

      if (contactsToUpdate.length !== 0) {
        this.applyContactsUpdate(contactsToUpdate, formContact);
      }
    } else {
      // on déselectionne la checkbox responsable projet
      this.contactForm.value.roles.splice(this.contactForm.value.roles.indexOf(this.RESPONSABLE_PROJET), 1);
      this.contactForm.value.roles.splice(this.contactForm.value.roles.indexOf(this.RESPONSABLE_ADMINISTRATIF), 1);
    }
  }

  /*
   * cette applique la mise à jour du responsable projet et/ou du responsable administratif
   * */
  private applyContactsUpdate(contactsToUpdate: Contact[], formContact: any) {
    const ObservableArray: Observable<HttpResponse<Structure>>[] = [];

    contactsToUpdate.forEach(contact => {
      const contactId = this.structure.contacts.indexOf(contact).toString();
      contact.dateModification = this.structure.dateModification;
      contact.adresse = null;
      ObservableArray.push(this.structureService.updateContactStructure(this.structureId, contact, contactId));
    });

    concat(...ObservableArray)
      .pipe(takeUntilDestroyed(this.destroyRef), toArray())
      .subscribe({
        next: resultUpdate => {
          if (resultUpdate) {
            this.structure.dateModification = resultUpdate.at(resultUpdate.length - 1).body.dateModification;
            this.updateProjetConsortiumContact(formContact);
          }
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  /*
   * cette méthode supprime le rôle du contact qui est responsable projet ou responsable administratif et financier puis le retourne
   * */
  private removeResponsableRole(role: EnumRoleContact): Contact {
    const contactR = this.structure.contacts.find(
      contact => contact.roles.includes(role) && this.contactId !== this.structure.contacts.indexOf(contact).toString()
    );
    if (contactR === undefined) {
      return undefined;
    }

    contactR.roles.splice(contactR.roles.indexOf(role), 1);

    if (
      (role === this.RESPONSABLE_PROJET && !contactR.roles.includes(this.RESPONSABLE_ADMINISTRATIF)) ||
      (role === this.RESPONSABLE_ADMINISTRATIF && !contactR.roles.includes(this.RESPONSABLE_PROJET))
    ) {
      contactR.roles.push(this.AUTRE);
    }

    return contactR;
  }

  /*
   * Enregistre le formulaire en fonction du role de la structure
   * */
  submitFormContact(formContact: any): void {
    if (!this.isRepresentantLegal) {
      this.contactForm.get('dateNaissance')?.setValidators(null);
      this.contactForm.get('dateNaissance')?.updateValueAndValidity();
      this.contactForm.get('villeNaissance')?.setValidators(null);
      this.contactForm.get('villeNaissance')?.updateValueAndValidity();
      this.contactForm.get('paysNaissance')?.setValidators(null);
      this.contactForm.get('paysNaissance')?.updateValueAndValidity();
    }

    if (this.structure?.raisonSiret === EnumMotifNonRattachement.EN_COURS_DE_CREATION && this.projectManagerChecked) {
      this.contactForm.get('adresse')?.enable();
    } else {
      this.contactForm.get('adresse')?.disable();
    }

    this.contactForm.markAllAsTouched();
    if (!this.contactForm.valid) {
      return;
    }

    if (this.structure?.role === EnumRoleStructure.MANDATAIRE) {
      this.updateProjetConsortiumContact(formContact);
    } else if (this.contactForm.controls['roles'].value.length !== 0) {
      this.openPopInIfOverLimit(formContact, this.REPRESENTANT_LEGAL, 3);
      this.openPopInIfOverLimit(formContact, this.RESPONSABLE_PROJET, 1);
      this.openPopInIfOverLimit(formContact, this.RESPONSABLE_ADMINISTRATIF, 1);
      this.openPopInIfOverLimit(formContact, this.AUTRE, 6);
      if (!this.flag) {
        this.updateProjetConsortiumContact(formContact);
      }
    }
  }

  getInvitation(): Invitation | undefined {
    return this.invitations.find(invitation => invitation.emailUtilisateur === this.contact.email);
  }

  textRenvoyerInvitation(): string {
    if (this.contact.inactif) {
      return 'Réinviter le contact';
    }
    if (this.invitation?.expired) {
      return "L'invitation de ce contact a expiré";
    }
    return "Renvoyer l'invitation à ce contact";
  }

  /**
   * Vérifie si le nombre de contacts pour un rôle donné est supérieur à la limite et ouvre la PopIn si c'est le cas
   */
  openPopInIfOverLimit(formContact: any, role: EnumRoleContact, limit: number): void {
    let amountContactsWithRole: number;

    if (this.structure.contacts?.length > 0) {
      amountContactsWithRole = this.structure.contacts.filter(
        contact => contact.roles?.length > 0 && contact.roles.indexOf(role) !== -1
      ).length;
    } else {
      return;
    }

    if (formContact.roles.indexOf(role) !== -1 && amountContactsWithRole > limit && !this.flag) {
      this.flag = true;

      switch (role) {
        case this.REPRESENTANT_LEGAL:
          this.openPopInRL();
          break;
        case this.RESPONSABLE_ADMINISTRATIF:
          this.openPopInR(formContact);
          break;
        case this.RESPONSABLE_PROJET:
          this.openPopInR(formContact);
          break;
        case this.AUTRE:
          this.openPopInA();
          break;
      }
    } else {
      return;
    }
  }

  updateProjetConsortiumContact(formContact: any): void {
    this.countrySelectDirty = false;
    Object.assign(this.contact, formContact);
    this.contact.dateModification = this.structure.dateModification;
    this.contact.codePaysNaissance = formContact.paysNaissance?.code;
    this.contact.dateNaissance = DateTimeUtils.formatToDate(formContact.dateNaissance);
    this.contact.paysNaissance = formContact.paysNaissance?.name;

    if (!(this.structure?.raisonSiret === EnumMotifNonRattachement.EN_COURS_DE_CREATION && this.projectManagerChecked)) {
      this.contact.adresse = null;
    }

    if (this.contactId) {
      this.structureService
        .updateContactStructure(this.structureId, this.contact, this.contactId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: HttpResponse<Structure>) => {
            let message = 'Le contact a bien été modifié.';
            if (this.contact.roles.indexOf(EnumRoleContact[EnumRoleContact.REPRESENTANT_LEGAL.toString()]) !== -1) {
              message = 'Représentant.e légal.e a bien été modifié.e.';
            }
            this.showToastrService.success(message);
            this.contactForm.reset();
            this.structureService.setUniqueStructureListObservable(response?.body, this.structures);
            this.onGoToConsortiumInfo();
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    } else {
      this.structureService
        .addContactStructure(this.structureId, this.contact)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: HttpResponse<Structure>) => {
            if (this.contact.roles.indexOf(EnumRoleContact[EnumRoleContact.REPRESENTANT_LEGAL.toString()]) !== -1) {
              this.showToastrService.success('Représentant.e légal.e a bien été ajouté.e.');
            } else if (response?.status === 206) {
              // status 206 notification n'est pas envoyée
              this.showToastrService.error(
                "Une erreur s'est produite. L'invitation n'a pas été envoyée. Veuillez renvoyer l'invitation depuis la fiche contact dans la structure."
              );
            } else {
              this.showToastrService.success('Le contact a bien été ajouté.');
            }
            this.contactForm.reset();
            this.structureService.setUniqueStructureListObservable(response?.body, this.structures);
            this.onGoToConsortiumInfo();
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    }
  }

  /*
   * Supprime le contact
   * */
  deleteContact(): void {
    if (this.suiviProjet || this.aap.statut === EnumAapStatut.OUVERT || this.projet.tag === EnumProjetTag.A_CORRIGER) {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          description: this.isRepresentantLegal
            ? `<p>Êtes-vous sûr de vouloir supprimer l’ensemble des informations sauvegardées pour ce contact.</p>
                        <p>Cette action est irréversible. </p>`
            : `<p>En confirmant l'action, ce contact sera définitivement supprimé de la structure et ne pourra plus avoir accès au projet. Vous aurez la possibilité de le réinviter si besoin.</p>
                        <p>Confirmez-vous l’action ?</p>`,
          textGoButton: 'Oui',
          textReturnButton: 'Non',
          icon: true,
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.handleDeleteContact(this.isRepresentantLegal);
        }
      });
    }
  }

  handleDeleteContact(isRepresentantLegal: boolean): void {
    const observer = {
      next: response => {
        let message = 'Le contact a bien été supprimé.';
        if (isRepresentantLegal) {
          message = 'Représentant.e légal.e a bien été supprimé.e.';
        }
        this.showToastrService.success(message);
        this.structureService.setUniqueStructureListObservable(response, this.structures);
        this.onGoToConsortiumInfo();
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    };
    if (isRepresentantLegal) {
      this.structure.contacts.splice(parseInt(this.contactId, 10), 1);
      this.contact.dateModification = this.structure.dateModification;
      this.structureService
        .deleteContactStructure(this.structureId, this.contactId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(observer);
    } else {
      this.structureService
        .disableContactStructure(this.structureId, this.contactId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(observer);
    }
  }

  /*
   * renvoi invitation
   * */
  resendInvitation(): void {
    if (this.contact.inactif) {
      this.reInvitationContact();
    } else if (this.contact.email) {
      const invitation = new Invitation();
      invitation.idProjet = this.projetId;
      invitation.idStructure = this.structureId.toString();
      invitation.emailUtilisateur = this.contact.email;
      invitation.nomAap = this.aap?.nom || this.suiviProjet.nomAap;
      invitation.nomProjet = this.projet?.nom || this.suiviProjet.acronyme;
      invitation.dateExpiration = new Date();
      invitation.dateExpiration.setDate(invitation.dateExpiration.getDate() + 7);

      this.projetService
        .resendInvitation(this.projetId, this.structureId.toString(), invitation, this.contactId)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (response: any) => {
            if (response) {
              this.showToastrService.success("L'invitation a bien été renvoyée");
              this.structureService.getInvitationsContactsStructure(this.structure.id).subscribe(response => {
                this.invitations = response.body ?? [];
                this.invitation = this.getInvitation();
              });
            }
          },
          error: (err: HttpErrorResponse) => {
            this.showToastrService.checkCodeError(err?.error);
          },
        });
    }
  }

  reInvitationContact() {
    if (!StructureValidationFunction.canAddContacts(this.structure)) {
      this.showToastrService.error(
        'Vous ne pouvez pas réinviter ce contact il existe déjà 6 contacts au niveau de la section "Contact(s) du projet"'
      );
    } else if (this.checkContactExistAndActifStructure() && this.contact.inactif) {
      this.showToastrService.error('Un contact actif avec ce mail existe déjà au niveau du projet');
    } else {
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: {
          title: 'Réinviter le contact',
          description: `<p>En confirmant l'action, ce contact sera réinvité dans la structure et pourra de nouveau avoir accès au projet.</p>
                        <p>Confirmez-vous l’action ?</p>`,
          textGoButton: 'Oui',
          textReturnButton: 'Non',
          icon: false,
        },
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.handleReinviterContact();
        }
      });
    }
  }

  checkContactExistAndActifStructure(): boolean {
    for (const structure of this.structures) {
      for (const contact of structure.contacts) {
        if (contact.email == this.contact.email && !contact.inactif && !contact.roles.includes(this.REPRESENTANT_LEGAL)) {
          return true;
        }
      }
    }
    return false;
  }

  /*
   * Permet de retourner à la page d'information consortium
   * */
  onGoToConsortiumInfo(): void {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  /*
   * Affiche le numero de telephone au bon format
   * */
  parsePhoneNumber(phone: string): string {
    phone = phone.replace(/\d{2}(?=.)/g, '$& ');
    return phone;
  }

  downloadRGPD(): void {
    window.open(URL_PUBLIC_DOC + this.aap.programme.fichierRgpd);
  }

  protected readonly EnumGenre = EnumGenre;
  protected readonly EnumMotifNonRattachement = EnumMotifNonRattachement;
  protected readonly EnumRoleStructure = EnumRoleStructure;

  private handleReinviterContact() {
    const observer = {
      next: (structure: Structure) => {
        this.showToastrService.success('Le contact a été réinvité.');
        this.structureService.setUniqueStructureListObservable(structure, this.structures);
        this.onGoToConsortiumInfo();
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    };
    this.structureService
      .reinvitationContactStructure(this.structureId, this.contactId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(observer);
  }

  isContactInStructure(role: EnumRoleStructure): boolean {
    const structure = this.structures.find(structure => structure.role === role);
    return structure?.contacts.some(contact => contact.email === this.utilisateur?.email) ?? false;
  }

  isEtapeProjetToDeleted(lastEtape: ProjetEtape): boolean {
    return (
      !lastEtape ||
      (lastEtape.nom === EnumProjetEtape.PRE_DEPOT &&
        (lastEtape.statut === EnumProjetStatut.EN_COURS || this.projet.tag === (EnumProjetTag as any)[EnumProjetTag.A_CORRIGER])) ||
      (lastEtape.nom === EnumProjetEtape.DEPOT &&
        (lastEtape.statut === EnumProjetStatut.EN_COURS || this.projet.tag === (EnumProjetTag as any)[EnumProjetTag.A_CORRIGER]))
    );
  }

  canDeleteContact() {
    const lastEtape = this.projet.etapes ? this.projet.etapes[this.projet?.etapes?.length - 1] : null;
    const isContactOfStrtucture = this.structure.contacts.some(contact => contact.email === this.utilisateur?.email);
    this.canDeletedContact =
      (isContactOfStrtucture ||
        this.isContactInStructure(EnumRoleStructure.CHEF_DE_FILE) ||
        this.isContactInStructure(EnumRoleStructure.MANDATAIRE) ||
        this.isEtapeProjetToDeleted(lastEtape)) &&
      this.contactId != null &&
      !this.isRepresentantLegal &&
      !this.contact.inactif &&
      this.contact.matricule !== this.utilisateur?.matricule;
  }

  private loadSynthesesContractualisation() {
    this.structureService
      .loadSyntheseContractualisationStructuresSubject()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(syntheses => {
        const synthese = syntheses?.find(synthese => synthese.id === this.structureId);
        const currentProcedure = this.structureSyntheseHelperService.getCurrentProcedure(synthese);
        if (
          currentProcedure.statut === EnumProcedureSignatureStatut.EN_COURS &&
          currentProcedure.signataires.some(signataire => signataire.email === this.contact.email)
        ) {
          this.isRepresentantLegalSignataireInOngoingProcedure = true;
          this.disableForm();
        }
      });
  }

  private disableForm() {
    this.contactForm.disable();
    this.contactForm.get('papaysNaissance')?.disable();
  }

  private patchStructure(): void {
    this.structure = {
      ...this.structure,
      role: this.suiviProjet.structures?.find(structure => structure.id === this.structureId)?.role,
    };
  }

  onCountryChange(country: ICountry) {
    this.contactForm.get('paysNaissance').setValue({
      code: country.code,
      name: country.name,
    });
  }
}
