import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DemandeComplement, Projet } from '../../../shared/models/_public_models';
import { EnumStatutDemandeDocumentComplementaireTech } from '../../../shared/enums/enum.statutDemandeDocumentComplementaire';

@Component({
  selector: 'lib-demande-document-complementaire-admin',
  templateUrl: './demande-document-complementaire-admin.component.html',
  styleUrls: ['./demande-document-complementaire-admin.component.scss'],
})
export class DemandeDocumentComplementaireAdminComponent {
  @Input() demande: DemandeComplement;
  @Input() canWrite: boolean;
  @Input() isArchived = false;
  @Input() projet: Projet;
  @Input() etapeCleTitle: string;
  @Output() updateStatutDemandeComplementEmitter = new EventEmitter();

  readonly VALIDEE = EnumStatutDemandeDocumentComplementaireTech.VALIDEE;
  readonly REJETEE = EnumStatutDemandeDocumentComplementaireTech.REJETEE;
  readonly RENVOYEE = EnumStatutDemandeDocumentComplementaireTech.RENVOYEE;
}
