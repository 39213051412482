import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NoteDepensesProjet, SubscriptionDestroyerComponent } from '@shared-ui';
import { ProjetInfoDepensesSharedService } from '../projet-info-depenses/projet-info-depenses-shared.service';

@Component({
  selector: 'pxl-sidenav-depenses',
  templateUrl: './sidenav-depenses.component.html',
  styleUrls: ['./sidenav-depenses.component.scss'],
})
export class SidenavDepensesComponent extends SubscriptionDestroyerComponent implements OnInit {
  public noteForm: FormGroup;
  public isValidForm = false;
  public readonly MAX_LENGTH = '500';
  public readonly TEXT_AREA_ROW_HEIGHT = '5';

  commentaireToDisplay: {
    index: number;
    depenseData: { note: NoteDepensesProjet | undefined; value: number };
    columnName: string;
  } = { index: 0, depenseData: { note: undefined, value: 0 }, columnName: '' };

  @Output() closeSideNav: EventEmitter<void> = new EventEmitter();

  constructor(private fb: FormBuilder, public sharedService: ProjetInfoDepensesSharedService) {
    super();
  }

  ngOnInit() {
    this.noteForm = this.fb.group({
      prioriteElevee: [false],
      commentaire: this.fb.group({
        data: [''],
      }),
    });
    this.getCommentaire();
    this.getIsSyntheseFormValid();
  }

  close(): void {
    if (
      this.isValidForm ||
      this.noteForm?.controls?.commentaire?.value?.data === this.commentaireToDisplay?.depenseData.note?.commentaire?.data
    ) {
      this.closeSideNav.emit();
    } else {
      if (confirm('Les modifications ne seront pas enregistrées')) {
        this.noteForm?.patchValue({ note: this.commentaireToDisplay?.depenseData?.note });
        this.closeSideNav.emit();
      }
    }
  }

  saveSideNav(): void {
    const commentaireToSave = {
      ...this.commentaireToDisplay,
      depenseData: {
        ...this.commentaireToDisplay?.depenseData,
        note: {
          prioriteElevee: this.noteForm.get('prioriteElevee')?.value,
          commentaire: {
            data: this.noteForm.get('commentaire.data')?.value,
          },
        },
      },
    };
    this.sharedService.setCommentaireToSave(commentaireToSave);
    this.closeSideNav.emit();
  }

  private getCommentaire(): void {
    this.sharedService
      .getCommentaireToDisplay()
      .pipe(this.takeUntilDestroyed())
      .subscribe(commentaire => {
        this.commentaireToDisplay = commentaire;
        this.noteForm.patchValue({ note: commentaire.depenseData.note });
        this.noteForm.get('prioriteElevee')?.setValue(commentaire.depenseData.note?.prioriteElevee);
        this.noteForm.get('commentaire.data')?.setValue(commentaire.depenseData?.note?.commentaire?.data);
      });
  }

  private getIsSyntheseFormValid(): void {
    this.sharedService
      .getIsSyntheseFormValid()
      .pipe(this.takeUntilDestroyed())
      .subscribe((valid: boolean) => {
        if (valid) {
          this.noteForm?.disable();
        } else {
          this.noteForm?.enable();
        }
        this.isValidForm = valid;
      });
  }
}
