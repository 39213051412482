import { NgModule } from '@angular/core';
import { QdtVizComponentsComponent } from './qdt-viz.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [QdtVizComponentsComponent],
  exports: [QdtVizComponentsComponent],
  imports: [MatProgressSpinnerModule],
})
export class QdtVizModule {}
