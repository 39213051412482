import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, DestroyRef, HostListener, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AapService } from '@services-candidat/aap.service';
import { FicheCommunicationService } from '@services-candidat/fiche-communication.service';
import { ProjetService } from '@services-candidat/projet.service';
import { UserService } from '@services-candidat/user.service';
import { SharedFunction } from '@shared-candidat/utils/sharedFunction';
import {
  Aap,
  DocumentHelper,
  DocumentProjet,
  EnumScanDocument,
  EnumTypeDocument,
  EnumTypePartenaire,
  EnumValidation,
  FicheCom,
  FicheComContactPresseComponent,
  FicheComDemandeDerogationComponent,
  FicheComInfoChefDeFileComponent,
  FicheComInfoProjetComponent,
  FicheComLogosStructuresComponent,
  Projet,
  ShowToastrService,
  SubscriptionDestroyerComponent,
  UploadDocumentHttpService,
  Utilisateur,
} from '@shared-ui';
import { EMPTY, Observable } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const FICHE_COMMUNICATION_ENREGISTREE_SUCCESS = 'Fiche de communication enregistrée avec succès';

@Component({
  selector: 'app-projet-info-fiche-com',
  templateUrl: './projet-info-fiche-com.component.html',
  styleUrls: ['./projet-info-fiche-com.component.scss'],
})
export class ProjetInfoFicheComComponent extends SubscriptionDestroyerComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  protected readonly EnumTypePartenaire = EnumTypePartenaire;
  readonly VALIDEE = (EnumValidation as any)[EnumValidation.VALIDE];
  projet: Projet = new Projet();
  aap: Aap = new Aap();
  ficheCommunicationDocuments: DocumentProjet[] = [];

  utilisateur: Utilisateur;
  isProjectUpdatableByUser = false;

  ficheCom: FicheCom = new FicheCom();
  ficheComForm: FormGroup;
  isLoaded = false;
  isDocLoaded = false;
  listLogoProjet: DocumentProjet[];
  isValideFicheCom: boolean;

  @ViewChild(FicheComInfoProjetComponent) ficheComInfoProjetComponent: FicheComInfoProjetComponent;
  @ViewChild(FicheComInfoChefDeFileComponent) ficheComInfoChefDeFileComponent: FicheComInfoChefDeFileComponent;
  @ViewChild(FicheComContactPresseComponent) ficheComContactPresseComponent: FicheComContactPresseComponent;
  @ViewChild(FicheComLogosStructuresComponent) ficheComLogosStructuresComponent: FicheComLogosStructuresComponent;
  @ViewChild(FicheComDemandeDerogationComponent) ficheComDemandeDerogationComponent: FicheComDemandeDerogationComponent;

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return (
      (this.ficheComInfoProjetComponent ? this.ficheComInfoProjetComponent.canDeactivate() : true) &&
      (this.ficheComInfoChefDeFileComponent ? this.ficheComInfoChefDeFileComponent.canDeactivate() : true) &&
      (this.ficheComContactPresseComponent ? this.ficheComContactPresseComponent.canDeactivate() : true) &&
      (this.ficheComLogosStructuresComponent ? this.ficheComLogosStructuresComponent.canDeactivate() : true) &&
      (this.ficheComDemandeDerogationComponent ? this.ficheComDemandeDerogationComponent.canDeactivate() : true)
    );
  }

  constructor(
    private projetService: ProjetService,
    private aapService: AapService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private showToastrService: ShowToastrService,
    private uploadDocumentService: UploadDocumentHttpService,
    private ficheCommunicationService: FicheCommunicationService,
    public sharedFunction: SharedFunction,
    private fb: FormBuilder
  ) {
    super();
    this.createForm();
  }

  ngOnInit(): void {
    this.getProjet();
    this.getLogoDoc();
    this.loadFicheCommunicationDocuments();
  }

  /*
   * Cette méthode récupère le projet
   * */
  getProjet(): void {
    const projetId = this.route.snapshot.parent.params.id;
    if (projetId) {
      this.projetService
        .getProjetObservable()
        .pipe(this.takeUntilDestroyed(), concatMap(this.processProjectResponse.bind(this)))
        .subscribe({
          next: this.processAapResponse.bind(this),
          error: this.handleHttpError.bind(this),
        });
    }
  }

  processProjectResponse(responseProjet: Projet): Observable<HttpResponse<Aap>> {
    if (responseProjet) {
      this.projet = responseProjet;
      this.ficheCom = this.projet.ficheCom || new FicheCom();
      this.isValideFicheCom = this.ficheCom?.statut === EnumValidation.VALIDE;
      this.ficheComForm.patchValue(this.ficheCom);
      return this.aapService.loadAapSubject();
    }
  }

  processAapResponse(responseAap: HttpResponse<Aap>): void {
    if (responseAap) {
      this.aap = responseAap.body;
      this.getUser();
    }
  }

  getUser(): void {
    this.userService.getUserObservable().subscribe(response => {
      if (response) {
        this.utilisateur = response;
        this.checkUserFormPermission();
        this.isLoaded = true;
      }
    });
  }

  checkUserFormPermission(): void {
    if (this.sharedFunction.isProjectUpdatableByUser(this.aap, this.projet, this.utilisateur)) {
      this.isProjectUpdatableByUser = true;
    }
    if (this.ficheCom.statut === this.VALIDEE) {
      this.isValideFicheCom = true;
    }
  }

  handleHttpError(err: HttpErrorResponse): void {
    this.showToastrService.checkCodeError(err?.error);
  }

  /*
   * Cette méthode permet de retourner à la page d'information projet
   * */

  onGoToProjetInfo(): void {
    this.router.navigate(['../projet-info'], { relativeTo: this.route });
  }

  onSave(event: FicheCom) {
    this.ficheCom = event;
    this.ficheCommunicationService.createFicheCommunication(this.ficheCom, this.projet.id).subscribe({
      next: (_response: HttpResponse<void>) => {
        this.showToastrService.success(FICHE_COMMUNICATION_ENREGISTREE_SUCCESS);
        this.ficheComForm.patchValue(this.ficheCom);
      },
      error: (err: HttpErrorResponse) => {
        this.showToastrService.checkCodeError(err?.error);
      },
    });
  }

  private createForm() {
    this.ficheComForm = new FormGroup({
      informationProjet: this.fb.group({
        description: ['', Validators.required],
        fonctionnementConsortium: ['', Validators.required],
        objectifs: ['', Validators.required],
        pathS3Image: ['', Validators.required],
        legendeCredit: ['', Validators.required],
      }),
      informationChefDeFile: this.fb.group({
        pathS3Image: ['', Validators.required],
        activite: ['', Validators.required],
      }),
      contactPresse: this.fb.group({
        nomPrenom: ['', Validators.required],
        telephone: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
      }),
      logoEntreprises: this.fb.group({
        logosStructures: this.fb.array([]),
      }),
      derogationForm: this.fb.group({
        derogation: [false],
        datePublication: [''],
      }),
    });

    if (this.projet.partenaireType === EnumTypePartenaire.INDIV) {
      this.ficheComForm.get('informationProjet').get('descriptionConsortium').setValidators(null);
    }
  }

  getLogoDoc(): void {
    const projetId = this.projet.id;

    this.uploadDocumentService
      .getLogoDocument(projetId)
      .pipe(this.takeUntilDestroyed())
      .subscribe({
        next: (resp: HttpResponse<DocumentProjet[]>) => {
          this.listLogoProjet =
            resp.body?.filter((doc: DocumentProjet) => {
              return doc.typeDoc === 'MEDIA_IMAGE';
            }) || [];
          this.isDocLoaded = true;
        },
        error: (err: HttpErrorResponse) => {
          this.showToastrService.checkCodeError(err?.error);
        },
      });
  }

  loadFicheCommunicationDocuments() {
    this.projetService
      .getDocumentsProjet(this.projet.id, [EnumTypeDocument.FICHE_COMMUNICATION_PUBLIQUE])
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        map(response => response.body ?? []),
        catchError(err => this.handleError(err))
      )
      .subscribe(documents => {
        this.ficheCommunicationDocuments = documents;
      });
  }

  handleError(err: HttpErrorResponse): Observable<never> {
    this.showToastrService.checkCodeError(err?.error);
    return EMPTY;
  }

  deleteDocument(document: DocumentProjet, silent = false): void {
    if (document.id) {
      this.projetService
        .deleteDocument(this.projet.id, document.id)
        .pipe(takeUntilDestroyed(this.destroyRef), catchError(!silent ? this.handleError : () => EMPTY))
        .subscribe(() => {
          this.ficheCommunicationDocuments = this.ficheCommunicationDocuments.filter(doc => doc !== document);
          this.showToastrService.success('Le document a bien été supprimé.');

          if (document.scan !== EnumScanDocument.UNSAFE && !document.stockerGed) {
            this.deleteDocumentFile(document);
          }
        });
    } else {
      this.ficheCommunicationDocuments = this.ficheCommunicationDocuments.filter(doc => doc !== document);
    }
  }

  private deleteDocumentFile(document: DocumentProjet): void {
    const path = DocumentHelper.getDocumentS3FilePath(document);
    this.uploadDocumentService
      .deleteDocFromS3(path, 'B401', document.id, this.projet.id)
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        catchError(err => this.handleError(err))
      )
      .subscribe();
  }
}
